// src/components/AdminDashboard/sections/PurchasesAfterInfluencerSection.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { Typography, MenuItem, CircularProgress } from '@mui/material';
import { SectionCard, FormControlStyled, StyledSelect, TableWrapper, StyledTable, StyledTableCell } from '../AdminDashboard.styles';
import { Table, TableHead, TableRow, TableBody, TableCell } from '@mui/material';

const PurchasesAfterInfluencerSection = ({
  users,
  selectedUserForPurchases,
  setSelectedUserForPurchases,
  filteredPurchases,
  loadingFilteredPurchases,
  purchaseQueryError
}) => (
  <SectionCard>
    <Typography variant="h6">Purchases After Influencer Code Usage</Typography>
    <FormControlStyled variant="outlined">
      <StyledSelect
        labelId="select-user-purchases-label"
        value={selectedUserForPurchases}
        label="Select User"
        onChange={(e) => setSelectedUserForPurchases(e.target.value)}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {users.map((user) => (
          <MenuItem key={user.id} value={user.id}>
            {user.username} ({user.email})
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControlStyled>
    {loadingFilteredPurchases ? (
      <CircularProgress />
    ) : purchaseQueryError ? (
      <Typography color="error">{purchaseQueryError}</Typography>
    ) : filteredPurchases.length > 0 ? (
      <TableWrapper>
        <StyledTable>
          <TableHead>
            <TableRow>
              <StyledTableCell>Purchase ID</StyledTableCell>
              <StyledTableCell>Purchase Date</StyledTableCell>
              <StyledTableCell>Price</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredPurchases.map((purchase) => (
              <TableRow key={purchase.id}>
                <TableCell>{purchase.id}</TableCell>
                <TableCell>{new Date(purchase.purchaseDate).toLocaleString()}</TableCell>
                <TableCell>{purchase.price}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </StyledTable>
      </TableWrapper>
    ) : (
      <Typography>No purchases found for selected user after influencer code usage.</Typography>
    )}
  </SectionCard>
);

PurchasesAfterInfluencerSection.propTypes = {
  users: PropTypes.array.isRequired,
  selectedUserForPurchases: PropTypes.string.isRequired,
  setSelectedUserForPurchases: PropTypes.func.isRequired,
  filteredPurchases: PropTypes.array.isRequired,
  loadingFilteredPurchases: PropTypes.bool.isRequired,
  purchaseQueryError: PropTypes.string
};

export default PurchasesAfterInfluencerSection;
