// src/utils/media.js
import { css } from 'styled-components';

export const media = {
  mobile: (...args) => css`
    @media (max-width: 480px) {
      ${css(...args)}
    }
  `,
  tablet: (...args) => css`
    @media (min-width: 481px) and (max-width: 768px) {
      ${css(...args)}
    }
  `,
  desktop: (...args) => css`
    @media (min-width: 769px) and (max-width: 1024px) {
      ${css(...args)}
    }
  `,
  largeDesktop: (...args) => css`
    @media (min-width: 1025px) {
      ${css(...args)}
    }
  `,
  hover: (...args) => css`
    @media (hover: hover) {
      ${css(...args)}
    }
  `,
};
