// src/components/AdminDashboard/AdminDashboard.styles.js
import styled from 'styled-components';
import { Button, Typography, Table, TableCell, FormControl, Select } from '@mui/material';
import { media } from '../../../utils/media';

// Container & Title for the main AdminDashboard
export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryBackground};
  padding: 40px;
  max-width: 1200px;
  margin: 40px auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  ${media.mobile`
    padding: 20px;
    margin: 20px auto;
  `}
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.accentBlueGray};
  margin-bottom: 20px !important;
  text-align: center;
`;

/* ──────────────────────────────────────────────────
   Shared "card" style for each section
─────────────────────────────────────────────────── */
export const SectionCard = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 30px;
  box-shadow: 0 1px 2px rgba(0,0,0,0.08);
`;

export const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  ${media.mobile`
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  `}
`;

/* ──────────────────────────────────────────────────
   Table styles
─────────────────────────────────────────────────── */
export const TableWrapper = styled.div`
  overflow-x: auto;
  width: 100%;
  margin-bottom: 20px;
`;

export const StyledTable = styled(Table)`
  min-width: 700px;
  & .MuiTableCell-root {
    border-bottom: 1px solid ${({ theme }) => theme.colors.accentBlueGray};
  }
`;

export const StyledTableCell = styled(TableCell)`
  color: ${({ theme }) => theme.colors.accentBlueGray};
  font-weight: bold;
`;

/* ──────────────────────────────────────────────────
   Form / FormControl / Buttons
─────────────────────────────────────────────────── */
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 600px;

  ${media.mobile`
    gap: 15px;
  `}
`;

export const FormControlStyled = styled(FormControl)`
  /* Some MUI overrides, e.g. color or border */
`;

export const StyledSelect = styled(Select)`
  /* MUI overrides for color, border, etc. */
`;

export const ResponsiveButton = styled(Button)`
  /* ... */
`;

export const StyledLinkButton = styled(Button)`
  /* ... */
`;

export const StyledDeleteButton = styled(Button)`
  /* ... */
`;

export const StyledSubmitButton = styled(Button)`
  /* ... */
`;

export const NoDataMessage = styled(Typography)`
  text-align: center;
  color: ${({ theme }) => theme.colors.accentBlueGray};
  margin-top: 20px;
`;

export const ErrorText = styled(Typography)`
  text-align: center;
  color: red;
  margin-top: 20px;
`;

export const ExportButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.secondaryButton};
  color: #fff;
  margin-top: 16px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.accentOrange};
  }

  svg {
    font-size: 1.2rem;
  }

  ${media.mobile`
    width: 100%;
    margin-top: 10px;
  `}
`;

