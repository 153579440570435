import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { media } from '../../utils/media';

const Logo = ({ size = 'medium', alt = 'Company Logo', logoSrc }) => {
  return (
    <StyledLogo 
      src={logoSrc} 
      alt={alt} 
      size={size}
    />
  );
};

Logo.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  alt: PropTypes.string,
  logoSrc: PropTypes.string.isRequired,
};

export default Logo;

const StyledLogo = styled.img`
  width: ${({ size }) => {
    switch (size) {
      case 'small':
        return '100px';
      case 'medium':
        return '120px';
      case 'large':
        return '150px';
      default:
        return '120px';
    }
  }};
  height: auto;
  object-fit: contain;
  transition: ${({ theme }) => theme.transitions.default};

  &:hover {
    transform: scale(1.05);
  }

  ${media.tablet(`
    width: ${({ size }) => {
      switch (size) {
        case 'small':
          return '80px';
        case 'medium':
          return '100px';
        case 'large':
          return '120px';
        default:
          return '100px';
      }
    }};
  `)}

  ${media.mobile(`
    width: ${({ size }) => {
      switch (size) {
        case 'small':
          return '60px';
        case 'medium':
          return '80px';
        case 'large':
          return '100px';
        default:
          return '80px';
      }
    }};
  `)}
`;
