// src/components/AdminDashboard/AdminDashboard.js
import React, { useEffect, useState } from 'react';
import api from '../../../utils/api';
import { Snackbar, Alert } from '@mui/material';
import { Container, Title } from './AdminDashboard.styles';
import qs from 'qs';

// Import sub-components
import DiscountCodesSection from './sections/DiscountCodesSection';
import UserRolesSection from './sections/UserRolesSection';
import AddCourtSection from './sections/AddCourtSection';
import CourtOwnershipSection from './sections/CourtOwnershipSection';
import PurchasesAfterInfluencerSection from './sections/PurchasesAfterInfluencerSection';
import UserPurchasesSection from './sections/UserPurchasesSection';
import RevenueRecordsExportSection from './sections/RevenueRecordsExportSection';
import FeedbackManagementSection from './sections/FeedbackManagementSection';

const AdminDashboard = () => {
  // ────────────── STATE VARIABLES ──────────────
  const [discountCodes, setDiscountCodes] = useState([]);
  const [loadingDiscountCodes, setLoadingDiscountCodes] = useState(true);
  const [deleteError, setDeleteError] = useState('');
  const [deleteSuccess, setDeleteSuccess] = useState('');

  const [users, setUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [loadingAssign, setLoadingAssign] = useState(false);
  const [assignSuccess, setAssignSuccess] = useState('');
  const [assignError, setAssignError] = useState('');

  const [courts, setCourts] = useState([]);
  const [selectedCourt, setSelectedCourt] = useState('');
  const [selectedCourtOwner, setSelectedCourtOwner] = useState('');
  const [loadingAssignCourt, setLoadingAssignCourt] = useState(false);
  const [assignCourtSuccess, setAssignCourtSuccess] = useState('');
  const [assignCourtError, setAssignCourtError] = useState('');

  const [purchases, setPurchases] = useState([]);
  const [loadingPurchases, setLoadingPurchases] = useState(true);
  const [purchaseError, setPurchaseError] = useState('');

  const [feedbackList, setFeedbackList] = useState([]);
  const [loadingFeedback, setLoadingFeedback] = useState(true);
  const [feedbackError, setFeedbackError] = useState('');
  const [deleteFeedbackSuccess, setDeleteFeedbackSuccess] = useState('');

  const [selectedUserForPurchases, setSelectedUserForPurchases] = useState('');
  const [filteredPurchases, setFilteredPurchases] = useState([]);
  const [loadingFilteredPurchases, setLoadingFilteredPurchases] = useState(false);
  const [purchaseQueryError, setPurchaseQueryError] = useState('');

  const [newCourtName, setNewCourtName] = useState('');
  const [newCourtHlsUrl, setNewCourtHlsUrl] = useState('');
  const [newCourtCameraName, setNewCourtCameraName] = useState('');
  const [newCourtCameraUrl, setNewCourtCameraUrl] = useState('');
  const [sessions, setSessions] = useState([]);
  const [tempSessionName, setTempSessionName] = useState('');
  const [tempDayOfWeek, setTempDayOfWeek] = useState('MONDAY');
  const [tempStartTime, setTempStartTime] = useState('06:00:00');
  const [tempEndTime, setTempEndTime] = useState('12:00:00');
  const [tempActive, setTempActive] = useState(true);

  const [exportError, setExportError] = useState('');
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());



  // ────────────── DATA FETCHING ──────────────
  useEffect(() => {
    const fetchDiscountCodes = async () => {
      try {
        const response = await api.get('/admin/discount-codes/all');
        setDiscountCodes(response.data);
      } catch (error) {
        console.error('Error fetching discount codes:', error);
        setDeleteError('Failed to fetch discount codes.');
      } finally {
        setLoadingDiscountCodes(false);
      }
    };
    fetchDiscountCodes();
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await api.get('/users/admin/users');
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
        setAssignError('Failed to fetch users.');
      } finally {
        setLoadingUsers(false);
      }
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchAllCourts = async () => {
      try {
        const response = await api.get('/users/admin/courts');
        setCourts(response.data);
      } catch (error) {
        console.error('Error fetching courts:', error);
      }
    };
    fetchAllCourts();
  }, []);

  useEffect(() => {
    const fetchUserPurchases = async () => {
      try {
        const res = await api.get('/users/admin/purchases');
        setPurchases(res.data);
      } catch (err) {
        console.error('Error fetching purchases:', err);
        setPurchaseError('Failed to fetch user purchases.');
      } finally {
        setLoadingPurchases(false);
      }
    };
    fetchUserPurchases();
  }, []);

  useEffect(() => {
    const fetchFeedback = async () => {
      try {
        const response = await api.get('/feedback/all');
        setFeedbackList(response.data);
      } catch (error) {
        console.error('Error fetching feedback:', error);
        setFeedbackError('Failed to fetch feedback.');
      } finally {
        setLoadingFeedback(false);
      }
    };
    fetchFeedback();
  }, []);

  useEffect(() => {
    const fetchFilteredPurchases = async () => {
      if (!selectedUserForPurchases) return;
      setLoadingFilteredPurchases(true);
      try {
        const response = await api.get(`/admin/after/influencer/${selectedUserForPurchases}`);
        setFilteredPurchases(response.data);
      } catch (error) {
        console.error('Error fetching filtered purchases:', error);
        setPurchaseQueryError('Failed to fetch purchases for selected user.');
      } finally {
        setLoadingFilteredPurchases(false);
      }
    };
    fetchFilteredPurchases();
  }, [selectedUserForPurchases]);

  // ────────────── EVENT HANDLERS ──────────────
  const handleAssignRole = async (e) => {
    e.preventDefault();
    if (!selectedUser || !selectedRole) {
      setAssignError('Please select both a user and a role.');
      return;
    }
    try {
      setLoadingAssign(true);
      await api.post('/users/admin/add-role', {
        userName: selectedUser,
        roleName: selectedRole.toUpperCase(),
      });
      setAssignSuccess(`Role "${selectedRole}" assigned to "${selectedUser}" successfully.`);
      const response = await api.get('/users/admin/users');
      setUsers(response.data);
      setSelectedUser('');
      setSelectedRole('');
    } catch (error) {
      console.error('Error assigning role:', error);
      setAssignError(error.response?.data?.message || 'Failed to assign role.');
    } finally {
      setLoadingAssign(false);
    }
  };

  const handleAssignCourtOwnership = async (e) => {
    e.preventDefault();
    if (!selectedCourt || !selectedCourtOwner) {
      setAssignCourtError('Please select both a court and a user.');
      return;
    }
    try {
      setLoadingAssignCourt(true);
      await api.post('/users/admin/assign-court', {
        courtId: selectedCourt,
        username: selectedCourtOwner,
      });
      setAssignCourtSuccess(`Court ownership assigned to ${selectedCourtOwner}.`);
    } catch (error) {
      console.error('Error assigning court ownership:', error);
      setAssignCourtError(error.response?.data?.message || 'Failed to assign court ownership.');
    } finally {
      setLoadingAssignCourt(false);
      setSelectedCourt('');
      setSelectedCourtOwner('');
    }
  };

  const handleDelete = async (id) => {
    try {
      await api.delete(`/admin/discount-codes/${id}`);
      setDiscountCodes((prev) => prev.filter((code) => code.id !== id));
      setDeleteSuccess('Discount code deleted successfully.');
    } catch (error) {
      console.error('Error deleting discount code:', error);
      setDeleteError('Failed to delete discount code.');
    }
  };

  const handleDeleteFeedback = async (feedbackId) => {
    try {
      await api.delete(`/feedback/delete/${feedbackId}`);
      setFeedbackList((prev) => prev.filter((fb) => fb.id !== feedbackId));
      setDeleteFeedbackSuccess('Feedback deleted successfully.');
    } catch (error) {
      console.error('Error deleting feedback:', error);
      setFeedbackError('Failed to delete feedback.');
    }
  };

  const handleAddSession = (e) => {
    e.preventDefault();
    const newSession = {
      sessionName: tempSessionName || `${tempDayOfWeek} Session`,
      dayOfWeek: tempDayOfWeek,
      startTime: tempStartTime,
      endTime: tempEndTime,
      active: tempActive
    };
    setSessions((prev) => [...prev, newSession]);
    setTempSessionName('');
    setTempDayOfWeek('MONDAY');
    setTempStartTime('06:00:00');
    setTempEndTime('12:00:00');
    setTempActive(true);
  };

  const handleRemoveSession = (index) => {
    setSessions((prev) => prev.filter((_, i) => i !== index));
  };

  const handleAddCourt = async (e) => {
    e.preventDefault();
    const newCourtDto = {
      courtName: newCourtName,
      hlsUrl: newCourtHlsUrl,
      cameraUrls: {
        [newCourtCameraName]: newCourtCameraUrl,
      },
      sessions: sessions,
    };
    try {
      await api.post('/admin/court', newCourtDto);
      setNewCourtName('');
      setNewCourtHlsUrl('');
      setNewCourtCameraName('');
      setNewCourtCameraUrl('');
      setSessions([]);
    } catch (err) {
      console.error('Error creating court:', err);
    }
  };

  const handleMonthChange = (event) => {
    setSelectedMonths(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleExportRevenue = async () => {
    try {
      setExportError('');
      const response = await api.get('/admin/export/purchases', {
        responseType: 'arraybuffer',
        params: { months: selectedMonths,
          year: selectedYear
         },
         paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' })
      });
      const blob = new Blob([response.data], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'purchases.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error exporting revenue records:', error);
      setExportError('Failed to export revenue records.');
    }
  };

  // ────────────── RENDER ──────────────
  return (
    <Container>
      <Title variant="h4" gutterBottom>
        Admin Dashboard
      </Title>

      <DiscountCodesSection
        discountCodes={discountCodes}
        loadingDiscountCodes={loadingDiscountCodes}
        deleteError={deleteError}
        handleDelete={handleDelete}
      />

      <UserRolesSection
        users={users}
        loadingUsers={loadingUsers}
        selectedUser={selectedUser}
        selectedRole={selectedRole}
        setSelectedUser={setSelectedUser}
        setSelectedRole={setSelectedRole}
        handleAssignRole={handleAssignRole}
        loadingAssign={loadingAssign}
        assignError={assignError}
        assignSuccess={assignSuccess}
      />

      <AddCourtSection
        newCourtName={newCourtName}
        setNewCourtName={setNewCourtName}
        newCourtHlsUrl={newCourtHlsUrl}
        setNewCourtHlsUrl={setNewCourtHlsUrl}
        newCourtCameraName={newCourtCameraName}
        setNewCourtCameraName={setNewCourtCameraName}
        newCourtCameraUrl={newCourtCameraUrl}
        setNewCourtCameraUrl={setNewCourtCameraUrl}
        sessions={sessions}
        tempSessionName={tempSessionName}
        setTempSessionName={setTempSessionName}
        tempDayOfWeek={tempDayOfWeek}
        setTempDayOfWeek={setTempDayOfWeek}
        tempStartTime={tempStartTime}
        setTempStartTime={setTempStartTime}
        tempEndTime={tempEndTime}
        setTempEndTime={setTempEndTime}
        tempActive={tempActive}
        setTempActive={setTempActive}
        handleAddSession={handleAddSession}
        handleRemoveSession={handleRemoveSession}
        handleAddCourt={handleAddCourt}
      />

      <CourtOwnershipSection
        courts={courts}
        users={users}
        loadingUsers={loadingUsers}
        selectedCourt={selectedCourt}
        setSelectedCourt={setSelectedCourt}
        selectedCourtOwner={selectedCourtOwner}
        setSelectedCourtOwner={setSelectedCourtOwner}
        handleAssignCourtOwnership={handleAssignCourtOwnership}
        loadingAssignCourt={loadingAssignCourt}
      />

      <PurchasesAfterInfluencerSection
        users={users}
        selectedUserForPurchases={selectedUserForPurchases}
        setSelectedUserForPurchases={setSelectedUserForPurchases}
        filteredPurchases={filteredPurchases}
        loadingFilteredPurchases={loadingFilteredPurchases}
        purchaseQueryError={purchaseQueryError}
      />

      <UserPurchasesSection
        purchases={purchases}
        loadingPurchases={loadingPurchases}
        purchaseError={purchaseError}
      />

      <RevenueRecordsExportSection
        selectedMonths={selectedMonths}
        selectedYear={selectedYear}
        handleYearChange={handleYearChange}
        handleMonthChange={handleMonthChange}
        handleExportRevenue={handleExportRevenue}
        exportError={exportError}
      />

      <FeedbackManagementSection
        feedbackList={feedbackList}
        loadingFeedback={loadingFeedback}
        feedbackError={feedbackError}
        handleDeleteFeedback={handleDeleteFeedback}
      />

      <Snackbar open={!!deleteSuccess} autoHideDuration={6000} onClose={() => setDeleteSuccess('')}>
        <Alert onClose={() => setDeleteSuccess('')} severity="success" sx={{ width: '100%' }}>
          {deleteSuccess}
        </Alert>
      </Snackbar>
      <Snackbar open={!!deleteError} autoHideDuration={6000} onClose={() => setDeleteError('')}>
        <Alert onClose={() => setDeleteError('')} severity="error" sx={{ width: '100%' }}>
          {deleteError}
        </Alert>
      </Snackbar>
      <Snackbar open={!!assignSuccess} autoHideDuration={6000} onClose={() => setAssignSuccess('')}>
        <Alert onClose={() => setAssignSuccess('')} severity="success" sx={{ width: '100%' }}>
          {assignSuccess}
        </Alert>
      </Snackbar>
      <Snackbar open={!!assignError} autoHideDuration={6000} onClose={() => setAssignError('')}>
        <Alert onClose={() => setAssignError('')} severity="error" sx={{ width: '100%' }}>
          {assignError}
        </Alert>
      </Snackbar>
      <Snackbar open={!!deleteFeedbackSuccess} autoHideDuration={6000} onClose={() => setDeleteFeedbackSuccess('')}>
        <Alert onClose={() => setDeleteFeedbackSuccess('')} severity="success" sx={{ width: '100%' }}>
          {deleteFeedbackSuccess}
        </Alert>
      </Snackbar>
      <Snackbar open={!!feedbackError} autoHideDuration={6000} onClose={() => setFeedbackError('')}>
        <Alert onClose={() => setFeedbackError('')} severity="error" sx={{ width: '100%' }}>
          {feedbackError}
        </Alert>
      </Snackbar>
      <Snackbar open={!!assignCourtSuccess} autoHideDuration={6000} onClose={() => setAssignCourtSuccess('')}>
        <Alert onClose={() => setAssignCourtSuccess('')} severity="success" sx={{ width: '100%' }}>
          {assignCourtSuccess}
        </Alert>
      </Snackbar>
      <Snackbar open={!!assignCourtError} autoHideDuration={6000} onClose={() => setAssignCourtError('')}>
        <Alert onClose={() => setAssignCourtError('')} severity="error" sx={{ width: '100%' }}>
          {assignCourtError}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AdminDashboard;
