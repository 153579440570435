// src/components/Video/VideoTrimCrop.js
import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { Form, ProgressBar as BootstrapProgressBar, Modal, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Rnd } from 'react-rnd';
import { Range, getTrackBackground } from 'react-range';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../utils/api';
import { PlatformConfigs } from '../../utils/PlatformConfigs';
import { setLoading } from '../../store/userSlice';
import { FaDownload, FaTimes } from 'react-icons/fa';
import { SiTiktok } from 'react-icons/si';
import { ShareButton, ActionButton, DownloadButton, CloseButton } from './styles/SharedStyles';
import { formatTime } from '../../utils/helper';
import { handleTikTokShare, downloadVideo } from '../../store/videoSlice';
import { useTikTokShare } from '../tiktok/useTikTokShare';

/* --------------------------------------------
 * HELPER FUNCTIONS & HOOKS
 * -------------------------------------------- */

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

// Calculate the initial crop dimensions based on the selected platform
function calculateInitialCrop(selectedPlatform, displayedWidth, displayedHeight) {
  if (!displayedWidth || !displayedHeight) return { x: 0, y: 0, width: 0, height: 0 };

  const config = PlatformConfigs.find((c) => c.value === selectedPlatform);
  if (!config || selectedPlatform === 'FREE_CROP') {
    const cropWidth = displayedWidth * 0.6;
    const cropHeight = displayedHeight * 0.6;
    return {
      x: (displayedWidth - cropWidth) / 2,
      y: (displayedHeight - cropHeight) / 2,
      width: cropWidth,
      height: cropHeight,
    };
  }

  const { aspectRatio, maxWidth, maxHeight } = config;
  let cropWidth = displayedWidth * 0.8;
  let cropHeight = cropWidth / aspectRatio;

  if (cropHeight > displayedHeight * 0.8) {
    cropHeight = displayedHeight * 0.8;
    cropWidth = cropHeight * aspectRatio;
  }

  cropWidth = Math.min(cropWidth, maxWidth, displayedWidth);
  cropHeight = Math.min(cropHeight, maxHeight, displayedHeight);

  return {
    x: (displayedWidth - cropWidth) / 2,
    y: (displayedHeight - cropHeight) / 2,
    width: cropWidth,
    height: cropHeight,
  };
}

// Polling hook to check the status of video processing (trim & crop)
function useVideoProcessingPolling({ trackingId, onSuccess, onFailure, onProgress, setLoadingState }) {
  useEffect(() => {
    if (!trackingId) return;

    const intervalId = setInterval(async () => {
      try {
        const statusResponse = await api.get('/videos/trim-crop/status', { params: { trackingId } });
        const processingInfo = statusResponse.data;

        if (processingInfo.videoProcessingStatus === 'PROCESSED') {
          clearInterval(intervalId);
          setLoadingState(false);
          if (processingInfo.preSignedUrl) {
            onSuccess(processingInfo.preSignedUrl, processingInfo.videoId);
          } else {
            onFailure('Result video URL not available.');
          }
        } else if (processingInfo.videoProcessingStatus === 'FAILED') {
          clearInterval(intervalId);
          setLoadingState(false);
          onFailure(processingInfo.videoProcessingErrorMessage || 'Video trimming and cropping failed.');
        } else {
          onProgress(processingInfo.videoProcessingProgress || 0);
        }
      } catch (err) {
        console.error('Error fetching trim/crop status:', err);
        clearInterval(intervalId);
        setLoadingState(false);
        onFailure('Error fetching operation status.');
      }
    }, 5000);

    return () => clearInterval(intervalId);
  }, [trackingId, onSuccess, onFailure, onProgress, setLoadingState]);
}

/* --------------------------------------------
 * TikTok Login Modal Component
 * -------------------------------------------- */
const TikTokLoginModal = ({ show, handleClose, resultVideoUrl }) => {
  const returnUrl = "/tiktok/share";

  const handleConnect = () => {
    const url =
      `/tiktok/login` +
      `?videoUrl=${encodeURIComponent(resultVideoUrl || "")}` +
      `&returnUrl=${encodeURIComponent(returnUrl)}`;
    window.location.href = url;
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Connect your TikTok account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>To share your video on TikTok, you need to connect your TikTok account first.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleConnect}>
          Connect with TikTok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

TikTokLoginModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

/* --------------------------------------------
 * VideoTrimCrop Component
 * -------------------------------------------- */
const VideoTrimCrop = ({ video, onClose }) => {
  const dispatch = useDispatch();
  const videoRef = useRef(null);

  // Declare state variables first
  const [resultVideoUrl, setResultVideoUrl] = useState(null);
  const [trimRange, setTrimRange] = useState([0, 0]); // [startSeconds, endSeconds]
  const [playerDisplayDimensions, setPlayerDisplayDimensions] = useState({ width: 0, height: 0 });
  const [crop, setCrop] = useState({ x: 0, y: 0, width: 0, height: 0 });
  const [selectedPlatform, setSelectedPlatform] = useState('FREE_CROP');
  const [trackingId, setTrackingId] = useState(null);
  const [progress, setProgress] = useState(0);
  const [trimCropLoading, setTrimCropLoading] = useState(false);
  const [trimCropError, setTrimCropError] = useState(null);
  const [trimmedCroppedVideoId, setTrimmedCroppedVideoId] = useState(null);

  // Use the TikTok share hook. The hook now receives the current resultVideoUrl.
  const { shareVideo, isSharing, needTikTokLogin, setNeedTikTokLogin } = useTikTokShare(resultVideoUrl);

  // Download selectors
  const downloadProgress = useSelector((state) => state.video.downloadProgress[trimmedCroppedVideoId]);
  const downloadStatus = useSelector((state) => state.video.downloadStatus[trimmedCroppedVideoId]);
  const isDownloadInProgress = downloadStatus === 'loading';
  const isDownloadSuccessful = downloadStatus === 'success';
  const isDownloadFailed = downloadStatus === 'failed';

  // Update display dimensions on window resize
  useEffect(() => {
    const handleResize = () => {
      if (videoRef.current) {
        const rect = videoRef.current.getBoundingClientRect();
        setPlayerDisplayDimensions({ width: rect.width, height: rect.height });
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Set initial crop values when display dimensions or platform change
  useEffect(() => {
    const { width, height } = playerDisplayDimensions;
    if (width && height) {
      const initialCrop = calculateInitialCrop(selectedPlatform, width, height);
      setCrop(initialCrop);
    }
  }, [playerDisplayDimensions, selectedPlatform]);

  // Callbacks for processing status updates
  const onSuccess = useCallback(
    (url, videoId) => {
      console.log('Video trimmed and cropped successfully:', url);
      setResultVideoUrl(url);
      setTrimmedCroppedVideoId(videoId);
      setTrimCropLoading(false);
      dispatch(setLoading(false));
      toast.success('Video trimmed and cropped successfully!');
    },
    [dispatch]
  );

  const onFailure = useCallback(
    (errorMsg) => {
      setTrimCropError(errorMsg);
      setTrimCropLoading(false);
      dispatch(setLoading(false));
      toast.error(errorMsg);
    },
    [dispatch]
  );

  const onProgress = useCallback((p) => {
    setProgress(p);
  }, []);

  const setLoadingState = useCallback(
    (state) => {
      dispatch(setLoading(state));
    },
    [dispatch]
  );

  useVideoProcessingPolling({ trackingId, onSuccess, onFailure, onProgress, setLoadingState });

  const handleTrimCrop = useCallback(async () => {
    setTrimCropError(null);
    setTrimCropLoading(true);
    setResultVideoUrl(null);
    setProgress(0);

    const [startSeconds, endSeconds] = trimRange;
    if (startSeconds >= endSeconds) {
      toast.error('Start time must be less than end time.');
      setTrimCropLoading(false);
      return;
    }

    const videoElement = videoRef.current;
    if (!videoElement) {
      setTrimCropError('Video element not found.');
      setTrimCropLoading(false);
      return;
    }

    const duration = videoElement.duration || 0;
    if (endSeconds > duration) {
      toast.error('End time exceeds video duration.');
      setTrimCropLoading(false);
      return;
    }

    const actualWidth = videoElement.videoWidth;
    const actualHeight = videoElement.videoHeight;
    if (!actualWidth || !actualHeight) {
      setTrimCropError('Video dimensions not available.');
      setTrimCropLoading(false);
      return;
    }

    const { width: displayedWidth, height: displayedHeight } = playerDisplayDimensions;
    if (!displayedWidth || !displayedHeight) {
      setTrimCropError('Video display dimensions not ready.');
      setTrimCropLoading(false);
      return;
    }

    const scaleX = actualWidth / displayedWidth;
    const scaleY = actualHeight / displayedHeight;
    const mappedX = Math.round(crop.x * scaleX);
    const mappedY = Math.round(crop.y * scaleY);
    let mappedWidth = Math.round(crop.width * scaleX);
    let mappedHeight = Math.round(crop.height * scaleY);

    const platformConfig = PlatformConfigs.find((c) => c.value === selectedPlatform);
    if (platformConfig && selectedPlatform !== 'FREE_CROP') {
      mappedWidth = Math.min(mappedWidth, platformConfig.maxWidth);
      mappedHeight = Math.min(mappedHeight, platformConfig.maxHeight);
    }

    const trimAndCropDto = {
      startSeconds,
      endSeconds,
      cropDto: {
        x: mappedX,
        y: mappedY,
        width: mappedWidth,
        height: mappedHeight,
        socialMediaPlatform: selectedPlatform !== 'FREE_CROP' ? selectedPlatform : 'CUSTOM',
      },
    };

    try {
      dispatch(setLoading(true));
      const response = await api.post(`/videos/${video.id}/trim-crop`, trimAndCropDto);
      setTrackingId(response.data);
    } catch (err) {
      console.error('Error initiating trim and crop:', err);
      setTrimCropError('Error initiating trim and crop. Please try again.');
      setTrimCropLoading(false);
      dispatch(setLoading(false));
    }
  }, [
    trimRange,
    crop,
    selectedPlatform,
    playerDisplayDimensions,
    video.id,
    dispatch,
  ]);

  /* ----------------------------------------------------------------
   *  DOWNLOAD LOGIC
   * ---------------------------------------------------------------- */
  const handleDownload = useCallback(async () => {
    if (!trimmedCroppedVideoId) {
      toast.error('No trimmed cropped video ID available.');
      return;
    }

    console.log('Download started for video ID:', trimmedCroppedVideoId);

    if (!isDownloadInProgress) {
      try {
        const actionResult = await dispatch(downloadVideo(trimmedCroppedVideoId));
        if (downloadVideo.rejected.match(actionResult)) {
          toast.error(actionResult.payload || 'Download failed.');
        } else {
          toast.success('Download started...');
        }
      } catch (err) {
        console.error(err);
        toast.error('Error initiating download. Please try again.');
      }
    }
  }, [dispatch, trimmedCroppedVideoId, isDownloadInProgress]);

  return (
    <Container>
      {trimCropError && <ErrorMessage>{trimCropError}</ErrorMessage>}

      {!resultVideoUrl ? (
        <>
          <ContentArea>
            <VideoContainer>
              <StyledVideo
                ref={videoRef}
                src={video.preSignedS3Url}
                controls
                onLoadedMetadata={() => {
                  if (videoRef.current) {
                    const totalDuration = videoRef.current.duration;
                    setTrimRange([0, Math.floor(totalDuration)]);
                  }
                }}
                aria-label="Video player for trimming and cropping"
              />
              {crop.width > 0 && crop.height > 0 && (
                <StyledRnd
                  size={{ width: crop.width, height: crop.height }}
                  position={{ x: crop.x, y: crop.y }}
                  bounds="parent"
                  minWidth={100}
                  minHeight={100}
                  lockAspectRatio={selectedPlatform !== 'FREE_CROP'}
                  aspectRatio={
                    selectedPlatform !== 'FREE_CROP'
                      ? PlatformConfigs.find((c) => c.value === selectedPlatform)?.aspectRatio
                      : undefined
                  }
                  onDragStop={(e, data) => {
                    setCrop((prev) => ({ ...prev, x: data.x, y: data.y }));
                  }}
                  onResizeStop={(e, direction, ref, delta, position) => {
                    setCrop({
                      x: position.x,
                      y: position.y,
                      width: parseFloat(ref.style.width),
                      height: parseFloat(ref.style.height),
                    });
                  }}
                  enableResizing={{
                    top: true,
                    right: true,
                    bottom: true,
                    left: true,
                    topRight: true,
                    bottomRight: true,
                    bottomLeft: true,
                    topLeft: true,
                  }}
                  style={{ zIndex: 10 }}
                  aria-label="Crop selection area"
                />
              )}
            </VideoContainer>

            <FormGroupStyled>
              <Form.Label>Select Crop Dimension</Form.Label>
              <Form.Control
                as="select"
                value={selectedPlatform}
                onChange={(e) => setSelectedPlatform(e.target.value)}
                aria-label="Select crop dimension"
              >
                <option value="FREE_CROP">Free Crop</option>
                {PlatformConfigs.map((config) => (
                  <option key={config.value} value={config.value}>
                    {config.label}
                  </option>
                ))}
              </Form.Control>
            </FormGroupStyled>

            <TrimSliderContainer>
              <Label>Trim Video</Label>
              <TimeDisplay>
                <span>Start: {formatTime(trimRange[0])}</span>
                <span>End: {formatTime(trimRange[1])}</span>
              </TimeDisplay>
              <Range
                step={1}
                min={0}
                max={video.duration}
                values={trimRange}
                onChange={(values) => setTrimRange(values)}
                renderTrack={({ props, children }, state) => (
                  <div
                    {...props}
                    style={{
                      ...props.style,
                      height: '6px',
                      width: '100%',
                      background: getTrackBackground({
                        values: trimRange,
                        colors: ['#ccc', '#548BF4', '#ccc'],
                        min: 0,
                        max: video.duration,
                      }),
                    }}
                    aria-label="Trim video range"
                  >
                    {children}
                  </div>
                )}
                renderThumb={({ props, isDragged }) => (
                  <div
                    {...props}
                    style={{
                      ...props.style,
                      height: '24px',
                      width: '24px',
                      borderRadius: '12px',
                      backgroundColor: '#fff',
                      border: '2px solid #548BF4',
                      boxShadow: '0 0 0 2px rgba(0,0,0,0.5)',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    aria-label={isDragged ? 'Dragging thumb' : 'Trim slider thumb'}
                  />
                )}
              />
            </TrimSliderContainer>
          </ContentArea>

          <Footer>
            <ActionButtons>
              <ActionButton
                onClick={onClose}
                variant="secondary"
                aria-label="Cancel trimming and cropping"
              >
                Cancel
              </ActionButton>
              <ActionButton
                onClick={handleTrimCrop}
                variant="primary"
                disabled={trimCropLoading}
                aria-label="Trim & Crop Video"
              >
                {trimCropLoading ? (
                  <>
                    Processing... <Spinner />
                  </>
                ) : (
                  'Trim & Crop'
                )}
              </ActionButton>
            </ActionButtons>

            {trimCropLoading && (
              <ProgressBarContainer>
                <BootstrapProgressBar
                  now={progress}
                  label={progress >= 0 ? `${progress}%` : 'Processing...'}
                  animated
                  striped
                  variant="danger"
                  aria-label={`Processing progress: ${progress}%`}
                />
              </ProgressBarContainer>
            )}
          </Footer>
        </>
      ) : (
        <>
          <ContentArea>
            <ResultContainer>
              <p>Video trimming and cropping is complete.</p>
              <ResultStyledVideo src={resultVideoUrl} controls aria-label="Processed video preview" />
            </ResultContainer>
          </ContentArea>

          <Footer>
            <ButtonsContainer>
              <ShareDownloadSection>
                <p style={{ margin: 0, fontWeight: 500 }}>Your Video is Ready!</p>
                <p style={{ fontSize: '0.85rem', color: '#777', marginBottom: '0.75rem' }}>
                  Download now or share later.
                </p>

                <SocialButtons>
                  {/* DOWNLOAD BUTTON */}
                  <SocialButton onClick={handleDownload} disabled={isDownloadInProgress}>
                    {isDownloadInProgress ? (
                      <>
                        <FaDownload /> Downloading...
                      </>
                    ) : (
                      <>
                        <FaDownload /> Download
                      </>
                    )}
                  </SocialButton>
                </SocialButtons>

                {isDownloadInProgress && (
                  <ProgressBarContainer>
                    <BootstrapProgressBar
                      now={downloadProgress || 0}
                      label={`${downloadProgress || 0}%`}
                      animated
                      striped
                      variant="danger"
                    />
                  </ProgressBarContainer>
                )}
                {isDownloadSuccessful && <SuccessMessage>Download Complete</SuccessMessage>}
                {isDownloadFailed && <ErrorMessage>Download failed. Please try again.</ErrorMessage>}

                {/* TIKTOK & YOUTUBE SHORTS - DISABLED & "Coming Soon" */}
                <DisabledButtonSection>
                  <DisabledButton disabled>
                    <SiTiktok />
                    TikTok
                  </DisabledButton>
                  <DisabledButton disabled>
                    {/* You can replace the icon below with any YouTube Shorts icon you use */}
                    <span style={{ marginRight: '5px' }}>▶</span>
                    YouTube Shorts
                  </DisabledButton>
                  <DisabledNote>Coming Soon</DisabledNote>
                </DisabledButtonSection>
              </ShareDownloadSection>

              <CloseSection>
                <CloseButton onClick={onClose} variant="secondary" aria-label="Close Trim and Crop">
                  <FaTimes /> Close
                </CloseButton>
              </CloseSection>
            </ButtonsContainer>
          </Footer>
        </>
      )}

      {/* Use the hook's needTikTokLogin state to control the modal */}
      {needTikTokLogin && (
        <TikTokLoginModal
          show={needTikTokLogin}
          handleClose={() => setNeedTikTokLogin(false)}
          resultVideoUrl={resultVideoUrl}
        />
      )}
    </Container>
  );
};

VideoTrimCrop.propTypes = {
  video: PropTypes.shape({
    id: PropTypes.number.isRequired,
    preSignedS3Url: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

/* --------------------------------------------
 * STYLED COMPONENTS
 * -------------------------------------------- */

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: ${({ theme }) => theme.spacing.md};
  color: ${({ theme }) => theme.colors.textPrimary};
  background-color: ${({ theme }) => theme.colors.primaryBackground};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  box-shadow: ${({ theme }) => theme.shadows.large};
  overflow: hidden;

  @media (max-width: 768px) {
    border-radius: ${({ theme }) => theme.borderRadius.sm};
  }
`;

const ContentArea = styled.div`
  flex: 1 1 auto;
  padding: ${({ theme }) => theme.spacing.lg};
  overflow-y: auto;

  @media (max-width: 768px) {
    padding: ${({ theme }) => theme.spacing.md};
  }
`;

const Footer = styled.div`
  flex-shrink: 0;
  padding: ${({ theme }) => theme.spacing.lg};
  background-color: ${({ theme }) => theme.colors.lightBackground};
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    padding: ${({ theme }) => theme.spacing.md};
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.sm};
  }
`;

const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.error};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing.sm};
`;

const ActionButtons = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm};
  justify-content: flex-end;

  @media (max-width: 576px) {
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.xs};
    width: 100%;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.md};
  width: 100%;

  @media (max-width: 576px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const ShareDownloadSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.md};

  @media (max-width: 576px) {
    order: 1;
  }
`;

const CloseSection = styled.div`
  @media (max-width: 576px) {
    order: 2;
  }
`;

const ProgressBarContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing.md};

  .progress {
    height: 1.25rem;
    border-radius: ${({ theme }) => theme.borderRadius.xs};
  }

  .progress-bar {
    background-color: ${({ theme }) => theme.colors.error} !important;
    font-size: ${({ theme }) => theme.fontSizes.xs};
  }
`;

const Spinner = styled.div`
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border: 2px solid ${({ theme }) => theme.colors.spinnerBackground};
  border-top-color: ${({ theme }) => theme.colors.spinnerForeground};
  border-radius: 50%;
  animation: ${spin} 0.6s linear infinite;
  margin-left: ${({ theme }) => theme.spacing.xs};
`;

const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.md};
  margin-top: ${({ theme }) => theme.spacing.lg};
`;

const ResultStyledVideo = styled.video`
  width: 100%;
  height: auto;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  object-fit: cover;
`;

const TrimSliderContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing.lg};

  .react-range__track {
    height: 6px;
    background: ${({ theme }) => theme.colors.primary};
  }

  .react-range__thumb {
    height: 24px;
    width: 24px;
    background-color: #fff;
    border: 2px solid ${({ theme }) => theme.colors.primary};
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.primary};
    cursor: pointer;
    border-radius: 50%;
    margin-top: -9px;
  }

  .react-range__track.react-range__track--active {
    background: ${({ theme }) => theme.colors.primary};
  }

  .react-range__label {
    display: none;
  }
`;

const Label = styled.label`
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.textPrimary};
`;

const TimeDisplay = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.textSecondary};
`;

const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  background-color: #000;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  overflow: hidden;
  box-shadow: ${({ theme }) => theme.shadows.medium};
`;

const StyledVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  object-fit: cover;

  &:focus {
    outline: 2px solid ${({ theme }) => theme.colors.primary};
    outline-offset: 2px;
  }
`;

const StyledRnd = styled(Rnd)`
  border: 2px solid ${({ theme }) => theme.colors.accent};
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: ${({ theme }) => theme.borderRadius.xs};
  box-shadow: ${({ theme }) => theme.shadows.small};

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.primary};
  }

  .react-rnd-resize-handle {
    background: ${({ theme }) => theme.colors.primary};
  }
`;

const FormGroupStyled = styled(Form.Group)`
  margin-top: ${({ theme }) => theme.spacing.md};

  label {
    font-size: ${({ theme }) => theme.fontSizes.sm};
    color: ${({ theme }) => theme.colors.textPrimary};
    margin-bottom: ${({ theme }) => theme.spacing.xs};
    font-weight: 500;
  }

  select,
  input {
    font-size: ${({ theme }) => theme.fontSizes.sm};
    padding: ${({ theme }) => theme.spacing.xs};
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: ${({ theme }) => theme.borderRadius.md};
    transition: ${({ theme }) => theme.transitions.input};
    width: 100%;

    &:focus {
      outline: none;
      border-color: ${({ theme }) => theme.colors.primary};
      background-color: ${({ theme }) => theme.colors.inputFocusBackground};
    }
  }
`;

const SuccessMessage = styled.p`
  color: green;
  font-size: 1rem;
  margin-top: 0.5rem;
`;

// Buttons for share/download
const SocialButtons = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm};
  flex-wrap: wrap;
`;

const SocialButton = styled.button`
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs};
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.md};
  background-color: ${({ theme }) => theme.colors.accents[1]};
  color: #fff;
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  text-decoration: none;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  text-align: center;
  cursor: pointer;
  transition: ${({ theme }) => theme.transitions.button};
  flex: 1 1 auto;

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
  }

  &:focus {
    outline: 2px solid ${({ theme }) => theme.colors.focusOutline || theme.colors.primary};
    outline-offset: 2px;
  }

  svg {
    margin-right: 5px;
    font-size: 1.2em;
  }
`;

/* Disabled TikTok & YouTube Shorts placeholders */
const DisabledButtonSection = styled.div`
  margin-top: 1rem;
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
`;

const DisabledButton = styled.button`
  display: inline-flex;
  align-items: center;
  gap: 0.4rem;
  padding: 0.5rem 1rem;
  background-color: #ccc;
  color: #444;
  border: none;
  border-radius: 6px;
  font-size: 0.875rem;
  text-align: center;
  cursor: not-allowed;
  opacity: 0.65;
`;

const DisabledNote = styled.p`
  margin: 0.3rem 0 0;
  font-size: 0.8rem;
  color: #999;
`;

export default VideoTrimCrop;
