import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { handleTikTokShare } from '../../store/videoSlice';
import { toast } from 'react-toastify';

export const useTikTokShare = (resultVideoUrl) => {
  const dispatch = useDispatch();
  const [needTikTokLogin, setNeedTikTokLogin] = useState(false);
  const [isSharing, setIsSharing] = useState(false);

  const shareVideo = useCallback(async () => {
    if (!resultVideoUrl) {
      toast.error('No video available to share.');
      return;
    }
    setIsSharing(true);
    try {
      const actionResult = await dispatch(handleTikTokShare(resultVideoUrl));
      if (handleTikTokShare.rejected.match(actionResult) && actionResult.payload?.needTikTokLogin) {
        setNeedTikTokLogin(true);
      } else {
        toast.success('Video shared successfully on TikTok!');
      }
    } catch (error) {
      toast.error('Error sharing to TikTok.');
    } finally {
      setIsSharing(false);
    }
  }, [dispatch, resultVideoUrl]);

  return { shareVideo, isSharing, needTikTokLogin, setNeedTikTokLogin };
};
