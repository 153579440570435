import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../utils/api';
import { toast } from 'react-toastify';




// Async thunk for downloading video with immediate download progress
export const downloadVideo = createAsyncThunk(
  'video/download',
  async (videoId, thunkAPI) => {
    try {
      // Reset download progress for this video when download starts
      thunkAPI.dispatch(setDownloadProgress({ videoId, progress: 0 }));

      const response = await api.get(`/videos/download/${videoId}`, {
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;

          if (total) {
            const progress = Math.round((loaded / total) * 100);
            thunkAPI.dispatch(setDownloadProgress({ videoId, progress }));
          } else {
            // Handle the case where Content-Length is missing or zero
            thunkAPI.dispatch(setDownloadProgress({ videoId, progress: -1 }));
          }
        }
      });

      // Create a blob URL and initiate download
      const blob = new Blob([response.data], { type: response.data.type || 'video/mp4' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `video_${videoId}.mp4`);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      return videoId; // Return videoId on success
    } catch (error) {
      console.error('Error downloading video:', error);
      return thunkAPI.rejectWithValue(`Failed to download video ${videoId}. Please try again.`);
    }
  }
);

// Updated TikTok share thunk
export const handleTikTokShare = createAsyncThunk(
  'video/handleTikTokShare',
  async (resultVideoUrl, thunkAPI) => {
    try {
      // Attempt to post the video URL to your TikTok share endpoint
      await api.post('/tiktok/share-video', { videoUrl: resultVideoUrl });
      toast.success(
        'Successfully initiated TikTok share. Please check your TikTok inbox to finish posting!'
      );
      return resultVideoUrl;
    } catch (error) {
      // If the API returns a 400 error, assume it means the user is not authenticated
      if (error.response && error.response.status === 400) {
        return thunkAPI.rejectWithValue({ needTikTokLogin: true });
      }
      // For all other errors, return a generic error message
      return thunkAPI.rejectWithValue({
        message: 'Error sharing to TikTok. Please try again',
      });
    }
  }
);



// Async thunk for fetching trimmed video
export const fetchTrimmedVideo = createAsyncThunk(
  'video/fetchTrimmedVideo',
  async (videoId, thunkAPI) => {
    try {
      const response = await api.get(`/videos/${videoId}/trimmed`);
      return response.data;
    } catch (error) {
      console.error('Error fetching trimmed video:', error);
      return thunkAPI.rejectWithValue(`Failed to fetch trimmed video ${videoId}.`);
    }
  }
);

// videoSlice.js
export const fetchVideos = createAsyncThunk(
  'video/fetchVideos',
  async ({ page, size, court, date, startTime, endTime }, { rejectWithValue }) => {
    try {
      // Build the query params
      const params = new URLSearchParams({
        page,
        size,
      });
      if (court)     params.append('court', court);
      if (date)      params.append('date', date);
      if (startTime) params.append('startTime', startTime);
      if (endTime)   params.append('endTime', endTime);

      const response = await api.get(
        `/videos/meta?${params.toString()}`
      );

      console.log('Fetched videos:', response.data);
      // if (!response.ok) {
      //   throw new Error('Failed to fetch videos');

      // }
      const data = response.data;
      // data should be a Page<VideoDto> with { content, totalElements, totalPages }
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


// // Async thunk for fetching paginated videos from "/videos/meta"
// export const fetchVideos = createAsyncThunk(
//   'video/fetchVideos',
//   async ({ page = 0, size = 10 }, { rejectWithValue }) => {
//     try {
//       // e.g. GET /videos/meta?page=0&size=10
//       const response = await api.get('/videos/meta', {
//         params: { page, size },
//       });

//       // The server returns a Page<VideoDto> that looks like:
//       // {
//       //   content: [...array of VideoDto...],
//       //   totalPages: 5,
//       //   number: 0, // the current page
//       //   size: 10,
//       //   totalElements: 47,
//       //   etc...
//       // }
//       return response.data;
//     } catch (error) {
//       console.error('Error fetching videos:', error);
//       return rejectWithValue(
//         error.response?.data?.message || 'Failed to fetch videos. Please try again.'
//       );
//     }
//   }
// );


export const fetchUserPurchasedVideos = createAsyncThunk('video/fetchUserPurchasedVideos',
   async (userId) => {
  try {
    const response = await api.get(`/users/${userId}/purchased-videos`);
    console.log('Fetched user purchased videos:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching user purchased videos:', error);
    throw new Error('Failed to fetch purchased videos. Please try again.');
  }
});

// Async thunk for fetching video details
export const fetchVideoDetails = createAsyncThunk(
  'video/fetchVideoDetails',
  async (id, thunkAPI) => {
    try {
      const response = await api.get(`/videos/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching video details:', error);
      return thunkAPI.rejectWithValue('Failed to fetch video details.');
    }
  }
);

// Async thunk to initiate trimming
export const initiateTrim = createAsyncThunk(
  'video/initiateTrim',
  async ({ videoId, startSeconds, endSeconds }, thunkAPI) => {
    try {
      const response = await api.post(`/videos/${videoId}/trim`, null, {
        params: { startSeconds, endSeconds },
      });
      return { trackingId: response.data, videoId };
    } catch (error) {
      console.error('Error initiating trim:', error);
      return thunkAPI.rejectWithValue('Failed to initiate trimming.');
    }
  }
);


// Async thunk to poll trimming status
export const pollTrimStatus = createAsyncThunk(
  'video/pollTrimStatus',
  async ({ trackingId, videoId }, thunkAPI) => {
    try {
      const response = await api.get('/videos/trim/status', {
        params: { trackingId },
      });
      console.log('Trim status:', response.data);
      return { statusInfo: response.data, videoId };
    } catch (error) {
      console.error('Error polling trim status:', error);
      return thunkAPI.rejectWithValue('Failed to fetch trimming status.');
    }
  }
);

export const downloadTrimmedVideo = createAsyncThunk(
  'video/downloadTrimmedVideo',
  async ({ videoId, trimmedVideoUrl }, thunkAPI) => {
    try {
      const response = await fetch(trimmedVideoUrl);
      if (!response.ok) throw new Error('Download failed.');

      const contentType = response.headers.get('content-type');
      console.log('Content-Type:', contentType);
      console.log('Content-Disposition:', response.headers.get('content-disposition'));
      console.log('Content-Length:', response.headers.get('content-length'));

      

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `trimmed_${videoId}.mp4`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);

      toast.success('Trimmed video downloaded successfully.');
      return videoId;
    } catch (error) {
      console.error('Error downloading trimmed video:', error);
      return thunkAPI.rejectWithValue('Failed to download trimmed video.');
    }
  }
);




const videoSlice = createSlice({
  name: 'video',
  initialState: {
    videos: [],
    totalPages: 0,           // from the Page object
    currentPage: 0, 
    videoDetails: null,
    trimmedVideos: {},
    userPurchasedVideos: [],
    selectedCourt: null,
    selectedCourtId: null,
    downloadProgress: {}, // Use an object to track progress for each video
    downloadStatus: {}, // Track status for each video
    trimming: {
      isTrimming: false,
      progress: 0,
      trimmedVideoUrl: '',
      error: null,
    },
    loading: {
      fetchVideos: false,
      fetchVideoDetails: false,
      downloadVideo: false,
      trimmedVideos: false,
      fetchUserPurchasedVideos: false,
    },
    error: null,
  },
  reducers: {
    setDownloadProgress(state, action) {
      const { videoId, progress } = action.payload;
      state.downloadProgress[videoId] = progress;
    },

    setSelectedCourt(state, action) {
      state.selectedCourt = action.payload;
    },

    removeVideoFromList(state, action) {
      const videoIdToRemove = action.payload;
      state.videos = state.videos.filter(video => video.id !== videoIdToRemove);

      state.userPurchasedVideos = state.userPurchasedVideos.filter(video => video.id !== videoIdToRemove);
    },

    resetDownloadProgress(state, action) {
      const videoId = action.payload;
      delete state.downloadProgress[videoId];
      delete state.downloadStatus[videoId];
    },

    setVideos(state, action) {
      state.videos = action.payload.reduce((acc, video) => {
        acc[video.id] = video;
        return acc;
      }, {});
      state.videoIds = action.payload.map((video) => video.id);
    },



    resetVideoState(state) {
      state.videos = [];
      state.videoDetails = null;
      state.trimmedVideos = [];
      state.userPurchasedVideos = [];
      state.loading = {
        fetchVideos: false,
        fetchVideoDetails: false,
        downloadVideo: false,
        trimmedVideos: false,
        fetchUserPurchasedVideos: false,
      };
      state.downloadProgress = {};
      state.downloadStatus = {};
      state.error = null;
      state.trimming = {
        isTrimming: false,
        progress: 0,
        trimmedVideoUrl: '',
        error: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVideos.pending, (state) => {
        state.loading.fetchVideos = true;
        state.error = null;
      })
      .addCase(fetchVideos.fulfilled, (state, action) => {
        // The server returns the entire Page object:
        // e.g.: {
        //   content: [...],
        //   totalPages: 5,
        //   number: 0,
        //   size: 10,
        //   totalElements: 47,
        //   ...
        // }
        state.loading.fetchVideos = false;
        const pageData = action.payload; // the entire Page object

        // Save the content array in videos
        state.videos = pageData.content;

        // Save totalPages or other fields if you want them
        state.totalPages = pageData.totalPages;
        state.currentPage = pageData.number; // The current page (0-based)
      })
      .addCase(fetchVideos.rejected, (state, action) => {
        state.loading.fetchVideos = false;
        state.error = action.payload || 'Failed to fetch videos. Please try again.';
      })

      .addCase(fetchVideoDetails.pending, (state) => {
        state.loading.fetchVideoDetails = true;
        state.error = null;
      })
      .addCase(fetchVideoDetails.fulfilled, (state, action) => {
        state.loading.fetchVideoDetails = false;
        state.videoDetails = action.payload;
      })
      .addCase(fetchVideoDetails.rejected, (state, action) => {
        state.loading.fetchVideoDetails = false;
        state.error = action.payload;
      })

      // Immediate status updates for downloads
      .addCase(downloadVideo.pending, (state, action) => {
        const videoId = action.meta.arg;
        state.loading.downloadVideo = true;
        state.downloadStatus[videoId] = 'loading';
        state.downloadProgress[videoId] = 0;
        state.error = null;
      })
      .addCase(downloadVideo.fulfilled, (state, action) => {
        const videoId = action.payload;
        state.loading.downloadVideo = false;
        state.downloadStatus[videoId] = 'success';
        state.downloadProgress[videoId] = 100; // Mark as fully downloaded
      })
      .addCase(downloadVideo.rejected, (state, action) => {
        const videoId = action.meta.arg;
        state.loading.downloadVideo = false;
        state.downloadStatus[videoId] = 'failed';
        state.downloadProgress[videoId] = 0;
        state.error = action.payload;
      })
      .addCase(fetchTrimmedVideo.pending, (state) => {
        state.loading.trimmedVideos = true;
        state.error = null;
      })
      .addCase(fetchTrimmedVideo.fulfilled, (state, action) => {
        state.loading.trimmedVideos = false;
        state.trimmedVideos = action.payload;
      })
      .addCase(fetchTrimmedVideo.rejected, (state, action) => {
        state.loading.trimmedVideos = false;
        state.error = action.payload;
      })
      .addCase(fetchUserPurchasedVideos.pending, (state) => {
        state.loading.fetchUserPurchasedVideos = true;
        state.error = null;
      })
      .addCase(fetchUserPurchasedVideos.fulfilled, (state, action) => {
        state.loading.fetchUserPurchasedVideos = false;
        state.userPurchasedVideos = action.payload;
      })
      .addCase(fetchUserPurchasedVideos.rejected, (state, action) => {
        state.loading.fetchUserPurchasedVideos = false;
        state.error = action.payload;
      })
      .addCase(initiateTrim.pending, (state) => {
        state.trimming.isTrimming = true;
        state.trimming.progress = 0;
        state.trimming.trimmedVideoUrl = '';
        state.trimming.error = null;
      })
      .addCase(initiateTrim.fulfilled, (state, action) => {
        const { trackingId, videoId } = action.payload;
        state.trimming.trackingId = trackingId;
      })
      .addCase(initiateTrim.rejected, (state, action) => {
        state.trimming.isTrimming = false;
        state.trimming.error = action.payload;
        toast.error(action.payload);
      })
    
      // Handle pollTrimStatus
      .addCase(pollTrimStatus.fulfilled, (state, action) => {
        const { statusInfo, videoId } = action.payload;
        const { videoProcessingStatus, 
          videoProcessingProgress, preSignedUrl, 
          videoProcessingErrorMessage } = statusInfo;

          console.log('Trim status:', statusInfo);
    
        if (videoProcessingStatus === 'PROCESSED') {
          state.trimming.isTrimming = false;
          state.trimming.progress = 100;
          state.trimmedVideos[videoId] = preSignedUrl;
          toast.success('Video trimming completed successfully!');
        } else if (videoProcessingStatus === 'FAILED') {
          state.trimming.isTrimming = false;
          state.trimming.error = videoProcessingErrorMessage 
          || 'Trimming failed.';
          toast.error('Video trimming failed.');
        } else {
          state.trimming.progress = videoProcessingProgress;
          console.log(state.trimming.progress);
        }
      })
      .addCase(pollTrimStatus.rejected, (state, action) => {
        state.trimming.isTrimming = false;
        state.trimming.error = action.payload;
        toast.error(action.payload);
      })
    
      // Handle downloadTrimmedVideo
      .addCase(downloadTrimmedVideo.pending, (state) => {
        state.loading.downloadVideo = true;
        state.error = null;
      })
      .addCase(downloadTrimmedVideo.fulfilled, (state, action) => {
        state.loading.downloadVideo = false;
        toast.success('Trimmed video downloaded successfully.');
      })
      .addCase(downloadTrimmedVideo.rejected, (state, action) => {
        state.loading.downloadVideo = false;
        state.error = action.payload;
        toast.error(action.payload);
      });
  },
});


export const { resetVideoState, setDownloadProgress,
   resetDownloadProgress, setSelectedCourt, removeVideoFromList,
  setResultVideoUrl } = videoSlice.actions;

export default videoSlice.reducer;
