import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import TikTok from '../assets/images/tiktok.png';
import { useSelector } from 'react-redux';
import api from '../utils/api';

// A styled button for TikTok login
const Button = styled.button`
  display: inline-flex;
  align-items: center;
  background-color: #010101; /* TikTok black */
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  
  &:hover {
    background-color: #333;
  }
  
  img {
    margin-right: 10px;
    width: 24px;
    height: 24px;
  }
`;


const TikTokLoginButton = () => {
    let videoUrl = useRef(null);
    let returnUrl = useRef(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    videoUrl.current = params.get("videoUrl");
    returnUrl.current = params.get("returnUrl");


    console.log("Video URL: ", videoUrl);


  }, []);

const handleLogin = () => {
    window.location.href =
      "http://localhost:8080/api/v1/tiktok/login?videoUrl=" +
      encodeURIComponent(videoUrl.current || "") +
      "&returnUrl=" +
      encodeURIComponent(returnUrl.current || "");
  };

  return (
    <Button onClick={handleLogin}>
      <img src={TikTok} alt='Tik Tok logo'/>
      Connect with TikTok
    </Button>
  );
};

export default TikTokLoginButton;
