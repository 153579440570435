// LivestreamDetailsModal.jsx
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import styled from 'styled-components';

const InfoRow = styled.div`
  margin-bottom: 0.5rem;
  strong {
    display: inline-block;
    width: 100px;
  }
`;

function LivestreamDetailsModal({ show, onHide, event }) {
  if (!event) {
    return null;
  }

  const {
    id,
    description,
    startTime,
    endTime,
    status,
    price,
    watchUrl,
    sponsorsList,
  } = event;

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Livestream Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InfoRow>
          <strong>ID:</strong> <span>{id}</span>
        </InfoRow>
        <InfoRow>
          <strong>Description:</strong> <span>{description}</span>
        </InfoRow>
        <InfoRow>
          <strong>Start:</strong> <span>{new Date(startTime).toLocaleString()}</span>
        </InfoRow>
        <InfoRow>
          <strong>End:</strong> <span>{new Date(endTime).toLocaleString()}</span>
        </InfoRow>
        <InfoRow>
          <strong>Status:</strong> <span>{status}</span>
        </InfoRow>
        <InfoRow>
          <strong>Price:</strong> <span>${price}</span>
        </InfoRow>

        {watchUrl && (
          <InfoRow>
            <strong>Watch URL:</strong> <span>{watchUrl}</span>
          </InfoRow>
        )}

        {sponsorsList && sponsorsList.length > 0 && (
          <>
            <strong>Sponsors:</strong>
            <ul style={{ marginTop: '0.25rem', marginLeft: '1rem' }}>
              {sponsorsList.map((sponsor, idx) => (
                <li key={idx}>
                  {sponsor.sponsorName}
                  {sponsor.logoUrl && ` - ${sponsor.logoUrl}`}
                </li>
              ))}
            </ul>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default LivestreamDetailsModal;
