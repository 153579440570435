// src/components/common/Layout.js

import React from 'react';
import NavBar from './NavBar';
import Footer from './Footer';
import styled from 'styled-components';
import { media } from '../../utils/media';

const Layout = ({ children }) => {
  return (
    <PageContainer>
      <NavBar />
      <MainContent>{children}</MainContent>
      <Footer />
    </PageContainer>
  );
};

export default Layout;

// Styled Components

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  background-color: ${({ theme }) => theme.colors.primaryBackground};
`;

const MainContent = styled.main`
  flex: 1;
  padding-top: 80px; /* Adjusted to match navbar height */
  width: 100%;
  position: relative;
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.primaryBackground};

  ${media.tablet(`
    padding-top: 70px; /* Adjusted for tablet navbar height */
  `)}

  ${media.mobile(`
    padding-top: 60px; /* Adjusted for mobile navbar height */
  `)}
`;
