// src/components/common/Sections.js

import styled from 'styled-components';
import { media } from '../../utils/media';

// Base Section Component
export const Section = styled.section`
  padding: ${({ theme }) => theme.spacing.xl} 0;
  position: relative;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.primaryBackground};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(135deg, 
      ${({ theme }) => theme.colors.primary} 0%,
      ${({ theme }) => theme.colors.secondary} 100%
    );
    opacity: 0.05;
    z-index: 0;
  }

  ${media.tablet(`
    padding: ${({ theme }) => theme.spacing.lg} 0;
  `)}

  ${media.mobile(`
    padding: ${({ theme }) => theme.spacing.md} 0;
  `)}
`;

// Horizontal Line Component
export const HorizontalLine = styled.div`
  width: 80px;
  height: 4px;
  background: ${({ theme }) => theme.colors.secondary};
  margin: ${({ theme }) => theme.spacing.md} auto;
  position: relative;
  border-radius: 2px;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 100%;
    background: ${({ theme }) => theme.colors.primary};
    border-radius: 2px;
  }
`;

// Icon Wrapper for Mission and Vision
export const IconWrapper = styled.div`
  font-size: 3.5rem;
  color: ${({ theme }) => theme.colors.secondary};
  margin-bottom: ${({ theme }) => theme.spacing.md};
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;

  &::after {
    content: '';
    position: absolute;
    width: 60px;
    height: 60px;
    background: ${({ theme }) => theme.colors.secondary}15;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    transition: all 0.3s ease;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    transform: translateY(-5px);

    &::after {
      transform: translate(-50%, -50%) scale(1.2);
      background: ${({ theme }) => theme.colors.primary}20;
    }
  }

  ${media.mobile(`
    font-size: 2.5rem;

    &::after {
      width: 40px;
      height: 40px;
    }
  `)}
`;

// Section Title Component
export const SectionTitle = styled.h2`
  font-size: ${({ theme }) => theme.fontSizes['2xl']};
  margin-bottom: ${({ theme }) => theme.spacing.md};
  color: ${({ theme }) => theme.colors.secondary};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: 700;
  position: relative;
  display: inline-block;

  &::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 2px;
    background: ${({ theme }) => theme.colors.primary};
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.3s ease;
  }

  &:hover::after {
    transform: scaleX(1);
    transform-origin: left;
  }

  ${media.tablet(`
    font-size: ${({ theme }) => theme.fontSizes.xl};
  `)}

  ${media.mobile(`
    font-size: ${({ theme }) => theme.fontSizes.lg};
  `)}
`;

// Mission and Vision Icons
export const MissionIcon = styled.div`
  font-size: 3rem;
  color: ${(props) => props.theme.colors.primary};
  margin-bottom: 20px;
  transition: color 0.3s ease;

  &:hover {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const VisionIcon = styled(MissionIcon)``;

// Mission and Vision Titles
export const MissionTitle = styled.h2`
  font-size: ${(props) => props.theme.fontSizes['2xl']};
  margin-bottom: 20px;
  color: ${(props) => props.theme.colors.textPrimary};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 1.75rem;
  }
`;

export const VisionTitle = styled(MissionTitle)``;

export const ResponsiveIframe = styled.div`
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  position: relative;
  border-radius: 0.75rem; /* 12px */
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.2); /* 4px 12px 12px */
  border: 2px solid ${(props) => props.theme.colors.border};

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0.75rem; /* 12px */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  &:hover iframe,
  &:focus iframe {
    transform: scale(1.02);
    box-shadow: 0 0.5rem 1.25rem rgba(0, 0, 0, 0.3); /* 8px 20px 20px */
  }
`;

export const ContentWrapper = styled.div`
  padding: 2rem;
  background-color: ${({ theme }) => theme.colors.secondaryBackground};
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  box-shadow: ${({ theme }) => theme.shadows.medium};
  text-align: left;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    text-align: center;
    padding: 1.5rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: 1rem;
  }
`;