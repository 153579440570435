import React, { useEffect } from 'react';
import styled from 'styled-components';
import LivestreamCard from './LivestreamCard';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLivestreams } from '../../store/LivestreamSlice';

const Container = styled.div`
  max-width: 1200px;
  margin: ${({ theme }) => theme.spacing.xl} auto;
  padding: ${({ theme }) => theme.spacing.md};
  font-family: ${({ theme }) => theme.fonts.primary};
  background: ${({ theme }) => theme.colors.primaryBackground};
`;

const Header = styled.h2`
  margin-bottom: ${({ theme }) => theme.spacing.lg};
  margin-top: 50px;
  font-size: ${({ theme }) => theme.fontSizes['2xl']};
  color: ${({ theme }) => theme.colors.title};
  text-align: center;
  font-weight: 600;
`;

const Grid = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacing.lg};
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
`;

const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.error};
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.md};
`;

const LoadingMessage = styled.p`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.md};
  color: ${({ theme }) => theme.colors.textSecondary};
`;

function AllLivestreamEventsAnyStatus() {
  const dispatch = useDispatch();
  const { livestreams, loading, error } = useSelector((state) => state.livestream);

  console.log('Fetching all livestreams...', { livestreams, loading, error });

  useEffect(() => {
    dispatch(fetchLivestreams());
  }, [dispatch]);

  if (loading) {
    return (
      <Container>
        <Header>All Livestream Events (Any Status)</Header>
        <LoadingMessage>Loading livestreams...</LoadingMessage>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Header>All Livestream Events (Any Status)</Header>
        <ErrorMessage>{error}</ErrorMessage>
      </Container>
    );
  }

  return (
    <Container>
      <Header>All Livestream Events (Any Status)</Header>
      <Grid>
        {livestreams.map((ls) => (
          <LivestreamCard key={ls.id} event={ls} />
        ))}
      </Grid>
    </Container>
  );
}

export default AllLivestreamEventsAnyStatus;
