import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FaArrowLeft } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { media } from '../../utils/media';

const RegisterNavbar = ({ logoSrc }) => {
  const navigate = useNavigate();

  return (
    <NavbarContainer>
      <NavbarContent>
        <BackButton 
          onClick={() => navigate(-1)}
          aria-label="Go back"
        >
          <FaArrowLeft />
        </BackButton>
        <LogoWrapper>
          <Logo 
            src={logoSrc} 
            alt="Akiture Logo"
            width={100}
            height={32}
            priority
          />
        </LogoWrapper>
      </NavbarContent>
    </NavbarContainer>
  );
};

RegisterNavbar.propTypes = {
  logoSrc: PropTypes.string.isRequired,
};

export default RegisterNavbar;

const NavbarContainer = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 64px;
  background: ${({ theme }) => theme.colors.secondaryBackground};
  backdrop-filter: blur(10px);
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary}20;
  z-index: 1000;
  transition: ${({ theme }) => theme.transitions.default};

  ${media.tablet(`
    height: 56px;
  `)}

  ${media.mobile(`
    height: 48px;
  `)}
`;

const NavbarContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 ${({ theme }) => theme.spacing.lg};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  ${media.tablet(`
    padding: 0 ${({ theme }) => theme.spacing.md};
  `)}

  ${media.mobile(`
    padding: 0 ${({ theme }) => theme.spacing.sm};
  `)}
`;

const BackButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  background: transparent;
  border: 1px solid ${({ theme }) => theme.colors.border};
  color: ${({ theme }) => theme.colors.text};
  cursor: pointer;
  transition: ${({ theme }) => theme.transitions.button};
  margin-right: ${({ theme }) => theme.spacing.md};
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.primary};
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  svg {
    width: 18px;
    height: 18px;
    position: relative;
    z-index: 1;
  }

  &:hover {
    border-color: ${({ theme }) => theme.colors.primary};
    transform: translateX(-2px);

    &::before {
      opacity: 0.1;
    }
  }

  &:active {
    transform: translateX(0);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px ${({ theme }) => theme.colors.primaryLight};
  }

  ${media.mobile(`
    width: 36px;
    height: 36px;
    margin-right: ${({ theme }) => theme.spacing.sm};

    svg {
      width: 16px;
      height: 16px;
    }
  `)}
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, 
      ${({ theme }) => theme.colors.primary} 0%,
      ${({ theme }) => theme.colors.secondary} 100%
    );
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover::after {
    opacity: 1;
  }
`;

const Logo = styled.img`
  height: 32px;
  width: auto;
  object-fit: contain;
  transition: ${({ theme }) => theme.transitions.default};

  &:hover {
    transform: scale(1.05);
  }

  ${media.tablet(`
    height: 28px;
  `)}

  ${media.mobile(`
    height: 24px;
  `)}
`; 