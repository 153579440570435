import React from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';
import styled from 'styled-components';
import DashboardNavBar from './DashboardNavBar';
import PurchasedVideos from './PurchasedVideos';
import DashboardFeedback from './DashboardFeedback';
import { media } from '../../utils/media';

const Dashboard = () => {
  return (
    <DashboardContainer>
      <DashboardNavBar />
      <ContentWrapper>
        <Routes>
          <Route index element={<Navigate to="purchased-videos" replace />} />
          <Route path="purchased-videos" element={<PurchasedVideos />} />
          <Route path="feedback" element={<DashboardFeedback />} />
        </Routes>
      </ContentWrapper>
    </DashboardContainer>
  );
};

export default Dashboard;

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.primaryBackground};
`;

const ContentWrapper = styled.div`
  flex: 1;
  padding: 30px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  transition: padding 0.3s ease;

  ${media.mobile`
    padding: 20px;
  `}
`;
