// src/components/VisionSection.js

import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FaUsers, FaRocket, FaHandshake } from 'react-icons/fa';
import { Section, HorizontalLine, IconWrapper } from './common/Sections';
import { media } from '../utils/media';

const VisionSection = () => {
  return (
    <Section id="vision">
      <Container>
        <ContentWrapper>
          <TextContent>
            <TitleWrapper>
              <IconWrapper>
                <FaUsers />
              </IconWrapper>
              <Title>Our Vision</Title>
              <HorizontalLine />
            </TitleWrapper>
            <Description>
            We envision a world where every athlete has access to their sports footage,
              allowing them to relive their best moments, share their highlights, and
              improve their game.
            </Description>
            <FeatureList>
              <FeatureItem>
                <FeatureIcon>
                  <FaUsers />
                </FeatureIcon>
                <FeatureText>Community First</FeatureText>
              </FeatureItem>
              <FeatureItem>
                <FeatureIcon>
                  <FaRocket />
                </FeatureIcon>
                <FeatureText>Innovation</FeatureText>
              </FeatureItem>
              <FeatureItem>
                <FeatureIcon>
                  <FaHandshake />
                </FeatureIcon>
                <FeatureText>Accessibility</FeatureText>
              </FeatureItem>
            </FeatureList>
          </TextContent>
          <VideoWrapper>
          <iframe
              src="https://www.youtube.com/embed/RXuJuz5fkow"
              title="Akture Sports Vision Video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </VideoWrapper>
        </ContentWrapper>
      </Container>
    </Section>
  );
};

export default VisionSection;

/* ────────────── STYLED COMPONENTS ────────────── */

const Container = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: ${({ theme }) => theme.spacing.xl} ${({ theme }) => theme.spacing.lg};
  position: relative;
  z-index: 2;

  ${media.tablet(`
    padding: ${({ theme }) => theme.spacing.lg} ${({ theme }) => theme.spacing.md};
  `)}

  ${media.mobile(`
    padding: ${({ theme }) => theme.spacing.md} ${({ theme }) => theme.spacing.sm};
  `)}
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spacing.xl};
  align-items: center;

  ${media.tablet(`
    grid-template-columns: 1fr;
    gap: ${({ theme }) => theme.spacing.xl};
    text-align: center;
  `)}

  ${media.mobile(`
    gap: ${({ theme }) => theme.spacing.lg};
  `)}
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.lg};
  animation: fadeInLeft 1s ease-out forwards;
  max-width: 600px;

  @keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translateX(-30px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  ${media.tablet(`
    align-items: center;
    max-width: 100%;
  `)}
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing.md};
  margin-bottom: ${({ theme }) => theme.spacing.md};

  ${media.tablet(`
    align-items: center;
  `)}
`;

const Title = styled.h2`
  font-size: clamp(2rem, 4vw, 3rem);
  font-weight: 800;
  line-height: 1.2;
  margin: 0;
  color: ${({ theme }) => theme.colors.title};
  letter-spacing: -0.02em;
  background: linear-gradient(
    135deg,
    ${({ theme }) => theme.colors.primary} 0%,
    ${({ theme }) => theme.colors.secondary} 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  ${media.tablet(`
    font-size: clamp(1.8rem, 4vw, 2.5rem);
  `)}

  ${media.mobile(`
    font-size: clamp(1.6rem, 5vw, 2rem);
  `)}
`;

const Description = styled.p`
  font-size: clamp(1.1rem, 2vw, 1.3rem);
  color: ${({ theme }) => theme.colors.textSecondary};
  line-height: 1.6;
  margin: 0;
  padding-left: 24px;
  font-weight: 400;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 4px;
    height: 80%;
    background: linear-gradient(
      to bottom,
      ${({ theme }) => theme.colors.primary},
      ${({ theme }) => theme.colors.secondary}
    );
    border-radius: 2px;
  }

  ${media.tablet(`
    font-size: 1.1rem;
    padding-left: 0;
    &::before {
      display: none;
    }
  `)}

  ${media.mobile(`
    font-size: 1rem;
  `)}
`;

const FeatureList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${({ theme }) => theme.spacing.md};
  margin-top: ${({ theme }) => theme.spacing.lg};

  ${media.tablet(`
    grid-template-columns: repeat(2, 1fr);
  `)}

  ${media.mobile(`
    grid-template-columns: 1fr;
    gap: ${({ theme }) => theme.spacing.sm};
  `)}
`;

const FeatureItem = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
  padding: ${({ theme }) => theme.spacing.md};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  background: rgba(255, 255, 255, 0.05);
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);

  &:hover {
    background: rgba(255, 255, 255, 0.1);
    transform: translateY(-2px);
    border-color: ${({ theme }) => theme.colors.primary};
  }

  ${media.mobile(`
    padding: ${({ theme }) => theme.spacing.sm};
    justify-content: center;
  `)}
`;

const FeatureIcon = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 111, 60, 0.1);
  padding: ${({ theme }) => theme.spacing.sm};
  border-radius: 50%;
`;

const FeatureText = styled.span`
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: 0.95rem;
  font-weight: 500;

  ${media.mobile(`
    font-size: 0.9rem;
  `)}
`;

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  animation: fadeInUp 1s ease-out 0.5s both;
  margin: ${({ theme }) => theme.spacing.md} 0;
  background: rgba(0, 0, 0, 0.05);

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    z-index: 1;
  }

  ${media.tablet(`
    padding-bottom: 56.25%;
    margin: ${({ theme }) => theme.spacing.sm} 0;
  `)}

  ${media.mobile(`
    padding-bottom: 56.25%;
    margin: ${({ theme }) => theme.spacing.sm} 0;
    border-radius: ${({ theme }) => theme.borderRadius.md};
    min-height: 200px;
  `)}
`;
