// src/components/Video/VideoCard.js
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { FaDownload, FaCrop } from 'react-icons/fa'; // <== Removed FaExpand
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  downloadVideo,
  fetchUserPurchasedVideos,
  removeVideoFromList,
} from '../../store/videoSlice';
import api from '../../utils/api';
import { getCourtName } from '../../utils/helper';
import VideoTrimCrop from './VideoTrimCrop';
import { Modal, Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import watermark from '../../assets/images/aktureWatermark.png';
import {
  Card,
  CheckboxContainer,
  TooltipWrapper,
  TooltipText,
  ThumbnailContainer,
  StyledVideo,
  PlayButton,
  PurchaseOverlay,
  DownloadIconContainer,
  SpinnerIcon,
  Content,
  TitleContainer,
  Title,
  RenameButton,
  TrimCropButton,
  DeleteButton,
  Description,
  ProgressWrapper,
  ProgressBar,
  ProgressLabel,
  DownloadCompleteLabel,
  ErrorMessage,
  Message,
  StyledModalHeader,
  StyledModalBody,
  StyledModalFooter,
  StyledTrimCropModalHeader,
  StyledTrimCropModalBody,
  ErrorText,
  ModalOverlay,
  ModalContainer,
  ModalTitle,
  ModalBody,
  ModalActions,
  CancelButton,
  ConfirmButton,
  ButtonGroup,
  PlayIcon,
  OverlayLogoImage,
  DownloadButton,
} from './styles/VideoCardStyles'; 
// ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ Note: Removed FullScreenButton import

/* --------------------------------------------
 * UTILITY FUNCTIONS FOR FORMATTING
 * -------------------------------------------- */

export const formatDate = (uploadDate) => {
  const date = new Date(uploadDate);
  if (isNaN(date.getTime())) return 'Invalid Date';
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric',
  }).format(date);
};

const formatDate2 = (uploadDate) => {
  const date = new Date(uploadDate);
  if (isNaN(date.getTime())) return 'Invalid Date';
  return new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
    .format(date);
};

const formatDateExtractTime = (dateString) => {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) return 'Invalid Date';
  return date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
};

export const formatDuration = (durationInSeconds) => {
  const minutes = Math.floor(durationInSeconds / 60);
  return `${minutes} min${minutes > 1 ? 's' : ''}`;
};

const isDefaultTitle = (title) => {
  const defaultTitlePattern = /^.+_video_\d{8}_\d{6}\.mp4$/;
  return defaultTitlePattern.test(title);
};

const getVideoDisplayTitle = (video) => {
  if (video.title && !isDefaultTitle(video.title.trim())) {
    return video.title;
  } else {
    const courtName = getCourtName(video.title);
    if (video.duration > 900) {
      // Longer duration videos
      return `${courtName} - ${formatDate2(video.startTime)} Duration: (${formatDuration(
        video.duration
      )})`;
    } else {
      // Shorter or exact segment
      return `${courtName} - ${formatDate(video.startTime)} - ${formatDateExtractTime(
        video.endTime
      )} (${formatDuration(video.duration)})`;
    }
  }
};

/* --------------------------------------------
 * MAIN COMPONENT
 * -------------------------------------------- */

const VideoCard = ({ video, isSelected, onSelect }) => {
  const dispatch = useDispatch();
  const videoRef = useRef(null);

  // Local state for modals and form inputs
  const [isPlaying, setIsPlaying] = useState(false);
  const [newTitle, setNewTitle] = useState(video.title || '');
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [renameError, setRenameError] = useState('');
  const [renameLoading, setRenameLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showTrimCropModal, setShowTrimCropModal] = useState(false);

  const user = useSelector((state) => state.auth.user);
  const downloadProgress = useSelector((state) => state.video.downloadProgress[video.id]);
  const downloadStatus = useSelector((state) => state.video.downloadStatus[video.id]);

  const isDownloadInProgress = downloadStatus === 'loading';
  const isDownloadSuccessful = downloadStatus === 'success';
  const isDownloadFailed = downloadStatus === 'failed';

  const displayTitle = getVideoDisplayTitle(video);

  /* --------------------------------------------
   * HANDLERS
   * -------------------------------------------- */

  const handleVideoPlayback = useCallback((e) => {
    e.stopPropagation();
    const videoElement = videoRef.current;
    if (!videoElement) return;
    if (videoElement.paused) {
      videoElement.play().catch((error) => {
        console.error('Error trying to play the video:', error);
      });
    } else {
      videoElement.pause();
    }
  }, []);

  const handleCardClick = useCallback(() => {
    if (onSelect) onSelect();
  }, [onSelect]);

  const handleRenameClick = useCallback((e) => {
    e.stopPropagation();
    setShowRenameModal(true);
  }, []);

  const handleRenameClose = useCallback(() => {
    setShowRenameModal(false);
    setRenameError('');
  }, []);

  const handleDeleteClick = useCallback((e) => {
    e.stopPropagation();
    setShowDeleteModal(true);
  }, []);

  const handleCloseDeleteModal = useCallback(() => {
    setShowDeleteModal(false);
  }, []);

  const handleTrimCropClick = useCallback((e) => {
    e.stopPropagation();
    setShowTrimCropModal(true);
  }, []);

  const handleCloseTrimCrop = useCallback(() => {
    setShowTrimCropModal(false);
  }, []);

  const handleTitleChange = useCallback((e) => {
    setNewTitle(e.target.value);
  }, []);

  const handleRenameSave = useCallback(async () => {
    if (newTitle.trim() === '') {
      setRenameError('Title cannot be empty.');
      return;
    }
    setRenameLoading(true);
    try {
      await api.put(`/videos/${video.id}/edit-name?newTitle=${newTitle}`);
      video.title = newTitle; // Update title locally
      await dispatch(fetchUserPurchasedVideos(user.id));
      handleRenameClose();
      toast.success('Video title updated successfully!');
    } catch (error) {
      console.error('Error updating video title:', error);
      setRenameError('Failed to update video title. Please try again.');
    } finally {
      setRenameLoading(false);
    }
  }, [newTitle, video, dispatch, user?.id, handleRenameClose]);

  const handleDownloadClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!video.isPurchased) {
      toast.warn('Please purchase this video to download.');
      return;
    }
    if (!isDownloadInProgress) {
      dispatch(downloadVideo(video.id));
    }
  }, [dispatch, video.id, video.isPurchased, isDownloadInProgress]);

  const handleDelete = useCallback(async () => {
    setIsDeleting(true);
    try {
      dispatch(removeVideoFromList(video.id));
      toast.success('Video deleted successfully.');
      handleCloseDeleteModal();
      await api.delete(`/videos/delete/${video.id}`);
    } catch (error) {
      console.error('Error deleting video:', error);
      toast.error('Failed to delete video. Please try again.');
      dispatch(fetchUserPurchasedVideos(user?.id));
    } finally {
      setIsDeleting(false);
    }
  }, [video.id, dispatch, handleCloseDeleteModal, user?.id]);

  /* --------------------------------------------
   * EFFECTS
   * -------------------------------------------- */

  useEffect(() => {
    const videoElement = videoRef.current;
    if (!videoElement) return;

    const handlePlay = () => setIsPlaying(true);
    const handlePause = () => setIsPlaying(false);
    const handleEnded = () => setIsPlaying(false);

    videoElement.addEventListener('play', handlePlay);
    videoElement.addEventListener('pause', handlePause);
    videoElement.addEventListener('ended', handleEnded);

    return () => {
      videoElement.removeEventListener('play', handlePlay);
      videoElement.removeEventListener('pause', handlePause);
      videoElement.removeEventListener('ended', handleEnded);
    };
  }, []);

  /* --------------------------------------------
   * RENDER
   * -------------------------------------------- */

  return (
    <Card onClick={handleCardClick} aria-label={`Video card for ${displayTitle}`}>
      <CheckboxContainer>
        <TooltipWrapper>
          <input
            type="checkbox"
            checked={isSelected}
            onChange={onSelect}
            aria-label={`Select video ${video.id}`}
          />
          <TooltipText>Select this video</TooltipText>
        </TooltipWrapper>
      </CheckboxContainer>

      <ThumbnailContainer>
        <StyledVideo
          ref={videoRef}
          controls
          fullscreen="allow"
          muted
          preload="metadata"
          onClick={video.isPurchased && isPlaying ? handleVideoPlayback : undefined}
          poster={video.thumbnails?.[Object.keys(video.thumbnails)[0]] || '/fallback-thumbnail.png'}
          aria-label={`Video preview for ${displayTitle}`}
        >
          <source src={video.preSignedS3Url} type="video/mp4" />
          Your browser does not support the video tag.
        </StyledVideo>

        {!video.isPurchased && (
          <PurchaseOverlay role="alert"><OverlayLogoImage src={watermark} alt='Brand Logo'></OverlayLogoImage>
          </PurchaseOverlay>
        )}

        {video.isPurchased && !isPlaying && (
          <PlayButton onClick={handleVideoPlayback} aria-label="Play video">
            <PlayIcon />
          </PlayButton>
        )}
      </ThumbnailContainer>

      <Content>

        {/* Title and Action Buttons */}
        <TitleContainer>
          <Title isPurchased={video.isPurchased}>{displayTitle}</Title>
          {video.isPurchased && (
            <ButtonGroup>
              <RenameButton onClick={handleRenameClick} aria-label="Rename video">
                Rename
              </RenameButton>
              <TrimCropButton onClick={handleTrimCropClick} aria-label="Trim and Crop video">
                <FaCrop style={{ marginRight: '5px' }} />
                Trim/Crop
              </TrimCropButton>
              <DownloadButton onClick={handleDownloadClick} aria-label="Download video">
                Download
              </DownloadButton>
              <DeleteButton onClick={handleDeleteClick} aria-label="Delete video">
                Delete
              </DeleteButton>
            </ButtonGroup>
          )}
        </TitleContainer>

        <Modal
          show={showTrimCropModal}
          onHide={handleCloseTrimCrop}
          centered
          backdrop="static"
          keyboard={false}
          size="lg"
          aria-label="Trim and Crop Modal"
        >
          <StyledTrimCropModalHeader closeButton>
            <Modal.Title>Trim & Crop Video</Modal.Title>
          </StyledTrimCropModalHeader>
          <StyledTrimCropModalBody>
            <VideoTrimCrop video={video} onClose={handleCloseTrimCrop} />
          </StyledTrimCropModalBody>
        </Modal>

        {/* Rename Modal */}
        <Modal
          show={showRenameModal}
          onHide={handleRenameClose}
          centered
          backdrop="static"
          keyboard={false}
          aria-label="Rename Video Modal"
        >
          <StyledModalHeader closeButton>
            <Modal.Title>Rename Video Title</Modal.Title>
          </StyledModalHeader>
          <StyledModalBody>
            <Form>
              <Form.Group controlId="formVideoTitle">
                <Form.Label>Video Title</Form.Label>
                <Form.Control
                  type="text"
                  value={newTitle}
                  onChange={handleTitleChange}
                  placeholder="Enter new video title"
                  autoFocus
                  aria-label="Video title input"
                />
              </Form.Group>
              {renameError && <ErrorText role="alert">{renameError}</ErrorText>}
            </Form>
          </StyledModalBody>
          <StyledModalFooter>
            <Button
              variant="secondary"
              onClick={handleRenameClose}
              aria-label="Cancel renaming title"
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleRenameSave}
              disabled={renameLoading}
              aria-label="Save new title"
            >
              {renameLoading ? 'Saving...' : 'Save'}
            </Button>
          </StyledModalFooter>
        </Modal>

        {/* Delete Confirmation */}
        {showDeleteModal && (
          <ModalOverlay>
            <ModalContainer
              role="dialog"
              aria-modal="true"
              aria-labelledby="delete-modal-title"
            >
              <ModalTitle id="delete-modal-title">Confirm Deletion</ModalTitle>
              <ModalBody>
                Are you sure you want to delete this video? This action cannot be undone.
              </ModalBody>
              <ModalActions>
                <CancelButton
                  onClick={handleCloseDeleteModal}
                  aria-label="Cancel deletion"
                >
                  Cancel
                </CancelButton>
                <ConfirmButton
                  onClick={handleDelete}
                  aria-label="Confirm deletion"
                  disabled={isDeleting}
                >
                  {isDeleting ? 'Deleting...' : 'Delete'}
                </ConfirmButton>
              </ModalActions>
            </ModalContainer>
          </ModalOverlay>
        )}

        <Description>{video.description}</Description>

        {/* Download Progress */}
        {isDownloadInProgress && (
          <ProgressWrapper aria-label={`Download progress: ${downloadProgress}%`}>
            <ProgressBar progress={downloadProgress} />
            <ProgressLabel>{`${downloadProgress}%`}</ProgressLabel>
          </ProgressWrapper>
        )}

        {isDownloadSuccessful && (
          <DownloadCompleteLabel role="status">Download Complete</DownloadCompleteLabel>
        )}
        {isDownloadFailed && (
          <ErrorMessage role="alert">Download failed. Please try again.</ErrorMessage>
        )}
        {/* {!video.isPurchased && (
          // <Message role="alert">Please purchase this video to access more features.</Message>
        )} */}
      </Content>
    </Card>
  );
};

VideoCard.propTypes = {
  video: PropTypes.shape({
    id: PropTypes.number.isRequired,
    preSignedS3Url: PropTypes.string.isRequired,
    uploadDate: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
    description: PropTypes.string,
    thumbnails: PropTypes.objectOf(PropTypes.string),
    isPurchased: PropTypes.bool,
    title: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
  }).isRequired,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
};

export default React.memo(VideoCard);

