import axios from 'axios';
import auth from './auth';
import { reduxStore } from '../store/reduxStore';
import { logout } from '../store/authSlice';
import { toast } from 'react-toastify';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api/v1',
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
});

api.interceptors.request.use(
  (config) => {
    const token = auth.getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  // On success, just return the response.
  (response) => response,

  // On error:
  (error) => {
    // If there's no response at all, it's a network or CORS issue, etc.
    if (!error.response) {
      toast.error('Network Error. Please try again later.');
      return Promise.reject(error);
    }

    // 1) Real HTTP status from Axios
    const { status } = error.response;

    // 2) ApiError fields (if your server sends them)
    const { statusCode, type, message, fieldErrors } = error.response.data || {};

    switch (status) {
      case 400:
        // Could check for fieldErrors if present (validation issues).
        // Or just display the message
        toast.error(message || 'Bad Request');
        break;

      case 401:
        // Possibly prompt for login
        toast.error(message || 'Unauthorized, please log in');
        
          auth.removeToken();
          reduxStore.dispatch(logout());
        setTimeout(() => {
          window.location.href = '/login';
        }
        , 1000);
        break;

      case 403:
        toast.error("You don't have permission to access this resource");
        auth.removeToken();
        reduxStore.dispatch(logout());
        setTimeout(() => {
          window.location.href = '/login';
        }
        , 1000);

        break;

      case 404:
        toast.error(message || 'Resource not found');
        break;

      case 422:
        toast.error(message || 'Validation Error');
        
        break;
      case 409:
        toast.error(message || 'Duplicate request, please check your email');
        
        break;

      default:
        toast.error(message || 'An error occurred. Please try again.');
        break;
    }
    return Promise.reject(error);
  }
);

export default api;
