import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FaUser, FaSignOutAlt, FaCog, FaBars } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { media } from '../../utils/media';
import NavBar from './NavBar';
import Logo from './Logo';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout as logoutAction } from '../../store/authSlice';

const MainNavbar = ({ user }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogout = async (e) => {
    e.preventDefault();
    await dispatch(logoutAction());
    navigate('/login');
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const navItems = [
    { label: 'Home', path: '/' },
    { label: 'About', path: '/about' },
    { label: 'Services', path: '/services' },
    { label: 'Contact', path: '/contact' },
  ];

  return (
    <NavBar user={user} />
  );
};

MainNavbar.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string,
  }),
};

export default MainNavbar;

/* ─────────────────────────────────────────────
   STYLED COMPONENTS
────────────────────────────────────────────── */
const StyledNavbar = styled.div`
  background: ${({ theme }) => theme.colors.secondaryBackground};
  backdrop-filter: blur(8px);
  box-shadow: ${({ theme }) => theme.shadows.medium};
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.md};
  font-family: ${({ theme }) => theme.fonts.primary};
  z-index: 1050;
  width: 90%;
  margin: 0 auto;
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  position: fixed;
  top: ${({ theme }) => theme.spacing.md};
  left: 50%;
  transform: translateX(-50%);

  ${media.tablet(`
    width: 95%;
    padding: ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.sm};
  `)}

  ${media.mobile(`
    width: 100%;
    border-radius: 0;
    top: 0;
  `)}
`;

const ContainerStyled = styled.div`
  width: 100%;
  max-width: 100%;
  padding: 0;
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;

  ${media.mobile(`
    img {
      width: 100px;
      height: 33px;
    }
  `)}
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.md};

  ${media.tablet(`
    gap: ${({ theme }) => theme.spacing.sm};
  `)}
`;

const UserSection = styled.div`
  position: relative;
`;

const UserButton = styled.button`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
  background: transparent;
  border: none;
  padding: ${({ theme }) => theme.spacing.sm};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  cursor: pointer;
  transition: ${({ theme }) => theme.transitions.button};
  color: ${({ theme }) => theme.colors.text};

  &:hover {
    background: ${({ theme }) => theme.colors.lightBackground};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px ${({ theme }) => theme.colors.primary}22;
  }

  ${media.tablet(`
    padding: ${({ theme }) => theme.spacing.xs};
  `)}
`;

const UserAvatar = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  transition: ${({ theme }) => theme.transitions.default};

  ${media.hover(`
    &:hover {
      transform: scale(1.05);
    }
  `)}

  ${media.mobile(`
    width: 28px;
    height: 28px;
    font-size: ${({ theme }) => theme.fontSizes.xs};
  `)}
`;

const UserName = styled.span`
  font-weight: 500;
  display: none;

  ${media.tablet(`
    display: inline;
  `)}

  ${media.mobile(`
    display: none;
  `)}
`;

const DropdownArrow = styled.span`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid ${({ theme }) => theme.colors.text};
  transition: transform 0.3s ease;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0)')};

  ${media.mobile(`
    display: none;
  `)}
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: ${({ theme }) => theme.spacing.xs};
  background: ${({ theme }) => theme.colors.secondaryBackground};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  box-shadow: ${({ theme }) => theme.shadows.medium};
  min-width: 200px;
  z-index: 1000;
  animation: slideDown 0.2s ease-out;

  @keyframes slideDown {
    from {
      opacity: 0;
      transform: translateY(-6px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  ${media.tablet(`
    position: static;
    width: 100%;
    border: none;
    border-top: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 0;
    box-shadow: none;
  `)}
`;

const DropdownItem = styled.div`
  padding: ${({ theme }) => theme.spacing.sm};
  cursor: pointer;
  transition: ${({ theme }) => theme.transitions.default};

  &:hover {
    background: ${({ theme }) => theme.colors.lightBackground};
  }
`;

const DropdownDivider = styled.div`
  height: 1px;
  background: ${({ theme }) => theme.colors.border};
`;

const AuthButtons = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.md};
`;

const LoginButton = styled(Link)`
  padding: ${({ theme }) => theme.spacing.sm};
  background: transparent;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text};
  transition: ${({ theme }) => theme.transitions.default};

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const RegisterButton = styled(Link)`
  padding: ${({ theme }) => theme.spacing.sm};
  background: ${({ theme }) => theme.colors.primary};
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  transition: ${({ theme }) => theme.transitions.default};

  &:hover {
    background: ${({ theme }) => theme.colors.primaryHover};
  }
`;

const CenterSection = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.md};
  padding: ${({ theme }) => theme.spacing.md};

  ${media.mobile(`
    padding: ${({ theme }) => theme.spacing.sm};
  `)}
`;

const NavLink = styled(Link)`
  color: ${({ theme }) => theme.colors.text};
  text-decoration: none;
  transition: ${({ theme }) => theme.transitions.default};

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const MobileMenuButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing.sm};
  color: ${({ theme }) => theme.colors.text};
  transition: ${({ theme }) => theme.transitions.default};

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  ${media.mobile(`
    display: block;
  `)}
`;