import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import Hls from 'hls.js';
import api from '../../utils/api';
import { toast } from 'react-toastify';

// Reusable manifest polling function
const fetchWithRetry = async (url, retries = 10, delay = 1000) => {
  for (let i = 0; i < retries; i++) {
    try {
      const res = await fetch(url, { method: 'HEAD' });
      if (res.ok) return true;
    } catch (error) {
      console.error('Manifest fetch error:', error);
    }
    await new Promise((resolve) => setTimeout(resolve, delay));
  }
  return false;
};

const CourtInfo = ({ courts }) => {
  const [selectedCourt, setSelectedCourt] = useState(null);
  const [selectedCamera, setSelectedCamera] = useState(null);
  const [hlsUrl, setHlsUrl] = useState('');
  const [streamKey, setStreamKey] = useState('');
  
  const videoRef = useRef(null);
  const hlsInstanceRef = useRef(null);

  const handleCourtClick = (court) => {
    setSelectedCourt(court);
    setSelectedCamera(null);
    resetStream();
  };

  const resetStream = () => {
    setHlsUrl('');
    setStreamKey('');
    cleanupHls();
  };

  const handleCameraChange = async (e) => {
    const camera = e.target.value;
    setSelectedCamera(camera);

    const rtspUrl = selectedCourt?.cameraUrls?.[camera];
    const selectedCourtId = selectedCourt?.courtId;
    if (rtspUrl && selectedCourtId) {
      await startStream(rtspUrl, selectedCourtId);
    } else {
      resetStream();
    }
  };

  const startStream = async (rtspUrl, selectedCourtId) => {
    try {
      const response = await api.post('/streams/start', { rtspUrl, courtId: selectedCourtId });
      const { hlsUrl, courtId } = response.data;

      setStreamKey(courtId);

      const isReady = await fetchWithRetry(hlsUrl);
      if (isReady) {
        setHlsUrl(hlsUrl);
      } else {
        console.error('Failed to start stream:', response.data);
      }
    } catch (error) {
      console.error('Failed to start stream:', error);
    
      resetStream();
    }
  };

  const stopStream = async () => {
    if (!streamKey) return alert('No active stream to stop.');
    try {
      await api.post('/streams/stop', { streamKey });
      resetStream();
      toast.success('Stream stopped successfully');
    } catch (error) {
      console.error('Failed to stop stream:', error);
    }
  };

  const initializeHls = (url) => {
    if (hlsInstanceRef.current) {
      hlsInstanceRef.current.destroy();
    }

    if (Hls.isSupported()) {
      const hls = new Hls();
      hlsInstanceRef.current = hls;
      hls.loadSource(url);
      hls.attachMedia(videoRef.current);
    } else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
      videoRef.current.src = url;
    }
  };

  const cleanupHls = () => {
    if (hlsInstanceRef.current) {
      hlsInstanceRef.current.destroy();
      hlsInstanceRef.current = null;
    }
  };

  useEffect(() => {
    if (hlsUrl) {
      initializeHls(hlsUrl);
    } else {
      cleanupHls();
    }

    return cleanupHls;
  }, [hlsUrl]);

  return (
    <Container>
      <Sidebar>
        <SidebarHeader>All Courts</SidebarHeader>
        <CourtList>
          {courts.map((court) => {
            const isSelected = selectedCourt?.courtId === court.courtId;
            return (
              <CourtItem
                key={court.courtId}
                onClick={() => handleCourtClick(court)}
                $isSelected={isSelected}
              >
                <strong>{court.courtName}</strong>
                <CameraCount>
                  {Object.keys(court.cameraUrls || {}).length} camera(s)
                </CameraCount>
              </CourtItem>
            );
          })}
        </CourtList>
      </Sidebar>

      <MainContent>
        {selectedCourt ? (
          <>
            <Title>{selectedCourt.courtName}</Title>

            <CameraSelectContainer>
              <Label htmlFor="cameraSelect">Select Camera:</Label>
              <CameraSelect
                id="cameraSelect"
                value={selectedCamera || ''}
                onChange={handleCameraChange}
              >
                <option value="">-- Choose Camera --</option>
                {Object.keys(selectedCourt.cameraUrls || {}).map((camera) => (
                  <option key={camera} value={camera}>
                    {camera}
                  </option>
                ))}
              </CameraSelect>
            </CameraSelectContainer>

            {hlsUrl && (
              <VideoContainer>
                <video ref={videoRef} controls muted />
                <StopButton onClick={stopStream}>Stop Stream</StopButton>
              </VideoContainer>
            )}
          </>
        ) : (
          <PlaceholderText>Select a court to view details.</PlaceholderText>
        )}
      </MainContent>
    </Container>
  );
};

export default CourtInfo;

/* 
  -------------------------------------------------------
  STYLED COMPONENTS 
  -------------------------------------------------------
*/

/**
 * Overall container 
 */
const Container = styled.div`
  display: flex;
  gap: 1.5rem;
  padding: 1rem;
  background-color: #fafafa;
  min-height: 500px; /* optional: ensures there's enough vertical space */
`;

/**
 * Sidebar that holds the courts list
 */
const Sidebar = styled.aside`
  width: 280px;
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
`;

/**
 * Header for the sidebar list
 */
const SidebarHeader = styled.h2`
  font-size: 1.25rem;
  color: #333;
  margin-bottom: 1rem;
  text-align: center;
  border-bottom: 1px solid #ececec;
  padding-bottom: 0.5rem;
`;

/**
 * Container for the list of courts
 */
const CourtList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-top: 0.5rem;
  flex: 1;
  overflow-y: auto; /* If the list grows large, it will scroll */
`;

/**
 * Single court item in the list
 */
const CourtItem = styled.div`
  background: ${({ $isSelected }) => ($isSelected ? '#e0f7fa' : '#fafafa')};
  border: 1px solid ${({ $isSelected }) => ($isSelected ? '#00bcd4' : '#ddd')};
  border-radius: 4px;
  padding: 0.75rem;
  cursor: pointer;
  transition: background 0.2s ease;
  
  &:hover {
    background: ${({ $isSelected }) => ($isSelected ? '#b2ebf2' : '#f4f4f4')};
  }

  strong {
    display: block;
    font-size: 1rem;
    color: #333;
  }
`;

/**
 * Sub-text for how many cameras are in a court
 */
const CameraCount = styled.span`
  font-size: 0.875rem;
  color: #777;
  margin-top: 0.25rem;
  display: block;
`;

/**
 * Main area to the right that shows camera selection + video
 */
const MainContent = styled.section`
  flex: 1;
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
`;

/**
 * Title for the selected court
 */
const Title = styled.h2`
  margin-top: 0;
  font-size: 1.5rem;
  color: #333;
  border-bottom: 1px solid #ececec;
  padding-bottom: 0.5rem;
`;

/**
 * Container + label for the camera select
 */
const CameraSelectContainer = styled.div`
  margin: 1rem 0;
`;

/**
 * Label for the camera dropdown
 */
const Label = styled.label`
  display: block;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #444;
`;

/**
 * The camera dropdown
 */
const CameraSelect = styled.select`
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  background: #fff;

  &:focus {
    outline: 2px solid #66afe9;
  }
`;

/**
 * Container that holds the video and the stop button
 */
const VideoContainer = styled.div`
  margin-top: 1rem;

  video {
    width: 100%;
    max-width: 720px;
    border: 1px solid #ddd;
    border-radius: 6px;
    display: block;
  }
`;

/**
 * Button to stop the stream
 */
const StopButton = styled.button`
  margin-top: 0.75rem;
  padding: 0.5rem 1.25rem;
  background-color: #d32f2f;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.95rem;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #b71c1c;
  }

  &:focus {
    outline: 2px solid #ff6f60;
  }
`;

/**
 * Default text to show when no court selected
 */
const PlaceholderText = styled.p`
  margin: auto;
  font-size: 1rem;
  color: #666;
  text-align: center;
`;
