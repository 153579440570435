import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import api from '../../utils/api';
import { toast } from 'react-toastify';
import { theme } from '../common/theme';

const StartLivestreamBtn = styled.button`
  background-color: ${theme.colors.orange};
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.3125rem;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: ${theme.colors.darkBackground};
  }

  &:focus {
    outline: 2px solid #5b52c8;
    outline-offset: 2px;
  }

  &:active {
    transform: scale(0.98);
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

function StartLivestreamButton({ livestreamId, purchaseDto, onStarted, externalDisabled = false }) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleStart = async () => {
    try {
      setIsLoading(true);
      const response = await api.post(
        `/livestreams/${livestreamId}/start`,
        purchaseDto || {}
      );
      const result = response.data;
      console.log('Livestream started:', result);

      onStarted?.(result);

      const { livestream, sessionId, sessionUrl } = result;
      if (livestream) {
        const watchUrl = livestream.watchUrl;
        navigate(`/livestreams/watch?url=${encodeURIComponent(watchUrl)}`);
      } else if (sessionId && sessionUrl) {
        console.log('Redirecting to payment page:', sessionUrl);
        window.location.href = sessionUrl;

      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage || 'An error occurred while starting the livestream.');
    } finally {
      setIsLoading(false);
    }
  };

  const disabled = isLoading || externalDisabled;

  return (
    <StartLivestreamBtn
      type="button"
      aria-label="Join Livestream"
      onClick={handleStart}
      disabled={disabled}
    >
      {isLoading ? 'Starting...' : 'Join stream'}
    </StartLivestreamBtn>
  );
}

StartLivestreamButton.propTypes = {
  livestreamId: PropTypes.oneOfType([PropTypes.string,
     PropTypes.number]).isRequired,
  purchaseDto: PropTypes.object,
  onStarted: PropTypes.func,
  externalDisabled: PropTypes.bool,
};

export default StartLivestreamButton;
