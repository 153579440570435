import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { media } from '../../utils/media';
import { FaBars, FaTimes } from 'react-icons/fa';

const DashboardNavBar = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const location = useLocation();
  // This includes any query string like ?processId=xxxx
  const { search } = location;

  const handleToggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  return (
    <NavContainer>
      <Logo>My Videos</Logo>
      <HamburgerIcon onClick={handleToggleSidebar}>
        {showSidebar ? <FaTimes /> : <FaBars />}
      </HamburgerIcon>
      <NavLinks showSidebar={showSidebar}>
        {/* Notice `search` appended to each sub-route */}
        <StyledNavLink to={`/dashboard/purchased-videos${search}`}>
          Purchased Videos
        </StyledNavLink>
        <StyledNavLink to={`/dashboard/feedback${search}`}>
          Leave Feedback
        </StyledNavLink>
      </NavLinks>
    </NavContainer>
  );
};

export default DashboardNavBar;

/* Styles omitted for brevity; identical to your existing code */


const NavContainer = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) =>
    theme.colors.primaryBackground || '#ffffff'};
  padding: 0 2rem;
  margin-top: 2rem;
  border-bottom: 1px solid #e0e0e0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: all 0.3s ease;

  ${media.mobile`
    padding: 0 1rem;
    margin-top: 1rem;
  `}
`;

const Logo = styled.div`
  font-size: 1.8rem;
  font-weight: bold;
  font-family: 'Raleway', sans-serif;
  color: ${({ theme }) => theme.colors.accents[1] || '#010101'};
  letter-spacing: 1px;
`;

const HamburgerIcon = styled.div`
  font-size: 1.8rem;
  cursor: pointer;
  display: none;
  color: ${({ theme }) => theme.colors.primary || '#010101'};

  ${media.mobile`
    display: block;
  `}
`;

const NavLinks = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;

  ${media.mobile`
    position: absolute;
    top: 3.5rem;
    right: 0;
    width: 200px;
    flex-direction: column;
    background-color: ${({ theme }) =>
      theme.colors.primaryBackground || '#ffffff'};
    padding: 1rem;
    gap: 1rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    transform: ${({ showSidebar }) =>
      showSidebar ? 'translateX(0)' : 'translateX(110%)'};
    transition: transform 0.3s ease-in-out;
    z-index: 999;
  `}
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.accents[2] || '#010101'};
  font-size: 1rem;
  font-family: ${({ theme }) =>
    theme.fonts.primary || "'Raleway', sans-serif"};
  position: relative;
  padding: 0.5rem 0;
  transition: color 0.3s ease;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -4px;
    width: 0;
    height: 2px;
    background-color: ${({ theme }) =>
      theme.colors.secondary || '#FF5722'};
    transition: width 0.3s ease;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.secondary || '#FF5722'};
  }

  &:hover::after {
    width: 100%;
  }

  &.active {
    color: ${({ theme }) => theme.colors.secondary || '#FF5722'};
  }
`;
