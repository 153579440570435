import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useAuth } from '../Auth/AuthProvider';
import UpdateLivestreamModal from './UpdateLivestreamModal';
import LivestreamDetailsModal from './LivestreamDetailsModal';
import StartLivestreamButton from './StartLivestreamButton';
import api from '../../utils/api';
import { toast } from 'react-toastify';
import { fetchLivestreams } from '../../store/LivestreamSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const CardWrapper = styled.article`
  background: ${({ theme }) => theme.colors.secondaryBackground};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  box-shadow: ${({ theme }) => theme.shadows.medium};
  overflow: hidden;
  margin-top: ${({ theme }) => theme.spacing.xl};
  margin-bottom: ${({ theme }) => theme.spacing.lg};
  transition: ${({ theme }) => theme.transitions.card};
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;

  &:hover {
    transform: translateY(-2px);
    box-shadow: ${({ theme }) => theme.shadows.large};
  }

  @media (max-width: 576px) {
    margin-top: ${({ theme }) => theme.spacing.md};
    margin-bottom: ${({ theme }) => theme.spacing.md};
    border-radius: ${({ theme }) => theme.borderRadius.md};
  }
`;

const CardContent = styled.div`
  padding: ${({ theme }) => theme.spacing.lg};

  @media (max-width: 576px) {
    padding: ${({ theme }) => theme.spacing.md};
  }
`;

const CardHeader = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

const Title = styled.h3`
  margin: 0 0 ${({ theme }) => theme.spacing.sm} 0;
  font-size: ${({ theme }) => theme.fontSizes['2xl']};
  font-weight: 600;
  color: ${({ theme }) => theme.colors.title};
  font-family: ${({ theme }) => theme.fonts.primary};

  @media (max-width: 576px) {
    font-size: ${({ theme }) => theme.fontSizes.xl};
  }
`;

const DateInfo = styled.p`
  margin: ${({ theme }) => theme.spacing.xxs} 0;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.accents[2]};

  strong {
    color: ${({ theme }) => theme.colors.title};
  }

  @media (max-width: 576px) {
    font-size: ${({ theme }) => theme.fontSizes.xs};
  }
`;

const Price = styled.div`
  margin-top: ${({ theme }) => theme.spacing.md};
  font-weight: 700;
  font-size: ${({ theme }) => theme.fontSizes.lg};
  color: ${({ theme }) => theme.colors.secondary};

  @media (max-width: 576px) {
    font-size: ${({ theme }) => theme.fontSizes.md};
  }
`;

const SponsorList = styled.ul`
  margin-top: ${({ theme }) => theme.spacing.md};
  padding-left: ${({ theme }) => theme.spacing.lg};
  list-style-type: disc;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.textSecondary};

  li {
    margin-bottom: ${({ theme }) => theme.spacing.xxs};
  }

  @media (max-width: 576px) {
    font-size: ${({ theme }) => theme.fontSizes.xs};
    padding-left: ${({ theme }) => theme.spacing.md};
  }
`;

const CourtOwnerError = styled.div`
  background-color: ${({ theme }) => theme.colors.inputFocusBackground};
  border-left: 4px solid ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.secondary};
  padding: ${({ theme }) => theme.spacing.sm};
  margin-top: ${({ theme }) => theme.spacing.md};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  border-radius: ${({ theme }) => theme.borderRadius.sm};

  @media (max-width: 576px) {
    font-size: ${({ theme }) => theme.fontSizes.xs};
  }
`;

const ButtonRow = styled.div`
  margin-top: ${({ theme }) => theme.spacing.lg};
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.md};
  align-items: center;

  @media (max-width: 576px) {
    gap: ${({ theme }) => theme.spacing.sm};
    margin-top: ${({ theme }) => theme.spacing.md};
  }
`;

const DiscountCodeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${({ theme }) => theme.spacing.xs};

  @media (max-width: 576px) {
    margin-left: 0;
    margin-top: ${({ theme }) => theme.spacing.sm};
    width: 100%;
  }

  label {
    font-size: ${({ theme }) => theme.fontSizes.xs};
    margin-bottom: ${({ theme }) => theme.spacing.xxs};
    color: ${({ theme }) => theme.colors.textSecondary};
  }

  input {
    border: 1px solid ${({ theme }) => theme.colors.disabledBorder};
    border-radius: ${({ theme }) => theme.borderRadius.sm};
    padding: ${({ theme }) => theme.spacing.xs};
    font-size: ${({ theme }) => theme.fontSizes.sm};
    min-width: 140px;
    background: ${({ theme }) => theme.colors.inputBackground};

    &:focus {
      border-color: ${({ theme }) => theme.colors.teal};
      outline: none;
      box-shadow: 0 0 4px rgba(13, 110, 253, 0.5);
    }

    @media (max-width: 576px) {
      width: 100%;
      min-width: 0;
    }
  }
`;

const ButtonBase = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.md};
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.white};
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  cursor: pointer;
  transition: ${({ theme }) => theme.transitions.button};

  &:focus {
    outline: 2px solid ${({ theme }) => theme.colors.teal};
    outline-offset: 2px;
  }

  &:active {
    transform: scale(0.98);
  }

  @media (max-width: 576px) {
    font-size: ${({ theme }) => theme.fontSizes.sm};
    padding: ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.sm};
  }
`;

const EditButton = styled(ButtonBase)`
  background-color: ${({ theme }) => theme.colors.accents[0]};

  &:hover {
    background-color: ${({ theme }) => theme.colors.darkBackground};
    opacity: 0.9;
  }
`;

const DetailsButton = styled(ButtonBase)`
  background-color: ${({ theme }) => theme.colors.accents[2]};

  &:hover {
    background-color: ${({ theme }) => theme.colors.darkBackground};
    opacity: 0.9;
  }
`;

const StopButton = styled(ButtonBase)`
  background-color: ${({ theme }) => theme.colors.secondary};

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
    opacity: 0.9;
  }
`;

const DeleteButton = styled(ButtonBase)`
  background-color: red;

  &:hover {
    background-color: darkred;
  }
`;

const LivestreamCard = ({ event, onDelete }) => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const processId = useMemo(() => Date.now(), []);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [discountCode, setDiscountCode] = useState('');
  const [isStopping, setIsStopping] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  // No longer tracking timeRemaining to disable the join button.

  const userRoles = user?.roles || [];
  const isSiteOwner = userRoles.includes('SITE_OWNER');
  const isAdmin = userRoles.includes('ADMIN');
  const isRegularUser = userRoles.includes('USER');
  const isCourtOwner = event.court?.owner?.id === user?.id;
  const isSiteOwnerAndUser = isSiteOwner && isRegularUser;
  const noCourtOwner = !event.court || !event.court.owner;

  // We keep canStopStream logic if only certain roles can stop the stream
  const canStopStream =
    event.status === 'IN_PROGRESS' &&
    (isSiteOwner || isAdmin || isCourtOwner || isSiteOwnerAndUser);

  // The purchase object (discount codes, etc.) remains
  const successUrl = "";
  const cancelUrl = "";
  const purchase = {
    discountCode,
    processId,
    successUrl,
    cancelUrl,
  };

  const handleStopStream = async () => {
    try {
      setIsStopping(true);
      const response = await api.post(`/livestreams/${event.id}/stop`);
      console.log('Livestream stopped:', response.data);
      toast.success('Livestream stopped successfully!');
    } catch (error) {
      console.error('Error stopping livestream:', error);
      toast.error('Failed to stop livestream.');
    } finally {
      setIsStopping(false);
    }
  };

  const handleDeleteLivestream = async () => {
    if (!toast.isActive('confirm-delete')) {
      toast.info('Are you sure you want to delete this livestream?', {
        toastId: 'confirm-delete',
        autoClose: 10000,
        position: 'top-center',
        closeOnClick: false,
        closeButton: false,
        draggable: false,
        pauseOnHover: false,
        pauseOnFocusLoss: false,
        onClick: () => toast.dismiss('confirm-delete'),
        onClose: () => toast.dismiss('confirm-delete'),
      });
      return;
    }

    try {
      setIsDeleting(true);
      await api.post(`/livestreams/${event.id}/delete`);
      toast.success('Livestream deleted successfully!');
      dispatch(fetchLivestreams());
      if (onDelete) {
        onDelete(event.id);
      }
    } catch (error) {
      console.error('Error deleting livestream:', error);
      toast.error('Failed to delete livestream.');
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <CardWrapper>
      <CardContent>
        <CardHeader>
          <Title>{event.description}</Title>
          <DateInfo>
            <strong>Start:</strong> {new Date(event.startTime).toLocaleString()}
          </DateInfo>
          <DateInfo>
            <strong>End:</strong> {new Date(event.endTime).toLocaleString()}
          </DateInfo>
          <Price>${event.price}</Price>
        </CardHeader>

        {event.sponsorsList?.length > 0 && (
          <SponsorList>
            {event.sponsorsList.map((sponsor, idx) => (
              <li key={idx}>
                {sponsor.sponsorName}
                {sponsor.logoUrl && ` - ${sponsor.logoUrl}`}
              </li>
            ))}
          </SponsorList>
        )}

        {noCourtOwner && (
          <CourtOwnerError>
            Warning: This livestream does not have a valid court owner. Starting or joining this livestream may fail until a court owner is assigned.
          </CourtOwnerError>
        )}

        <ButtonRow>
          <DetailsButton
            type="button"
            aria-label="View Livestream Details"
            onClick={() => setShowDetailsModal(true)}
          >
            View Details
          </DetailsButton>

          {isRegularUser && !user?.isPaid && (
            <DiscountCodeWrapper>
              <label htmlFor="discountCode">Discount Code:</label>
              <input
                id="discountCode"
                type="text"
                value={discountCode}
                placeholder="Enter code"
                onChange={(e) => setDiscountCode(e.target.value)}
              />
            </DiscountCodeWrapper>
          )}

          {/*
            Removed role checks for starting/joining the stream.
            Any user can attempt to join now; the backend will respond
            if the stream isn't ready or the user isn't authorized.
          */}
          <StartLivestreamButton
            livestreamId={event.id}
            purchaseDto={isRegularUser ? purchase : undefined}
            onStarted={(updatedLivestream) => {
              console.log('Livestream started/joined:', updatedLivestream);
              if (updatedLivestream.watchUrl) {
                toast.success('Livestream joined successfully!');
              }
            }}
          />

          {canStopStream && (
            <StopButton
              type="button"
              aria-label="Stop Livestream"
              onClick={handleStopStream}
              disabled={isStopping}
            >
              {isStopping ? 'Stopping...' : 'Stop Stream'}
            </StopButton>
          )}

          <EditButton
            type="button"
            aria-label="Edit Livestream"
            onClick={() => setShowEditModal(true)}
          >
            Edit
          </EditButton>

          {isAdmin && (
            <DeleteButton
              type="button"
              aria-label="Delete Livestream"
              onClick={handleDeleteLivestream}
              disabled={isDeleting}
            >
              {isDeleting ? 'Deleting...' : 'Delete'}
            </DeleteButton>
          )}
        </ButtonRow>
      </CardContent>

      {showDetailsModal && (
        <LivestreamDetailsModal
          show={showDetailsModal}
          onHide={() => setShowDetailsModal(false)}
          event={event}
        />
      )}

      {showEditModal && (
        <UpdateLivestreamModal
          show={showEditModal}
          onHide={() => setShowEditModal(false)}
          initialData={event}
          onUpdated={() => setShowEditModal(false)}
        />
      )}
    </CardWrapper>
  );
};

LivestreamCard.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    description: PropTypes.string.isRequired,
    startTime: PropTypes.string.isRequired,
    endTime: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    sponsorsList: PropTypes.arrayOf(
      PropTypes.shape({
        sponsorName: PropTypes.string.isRequired,
        logoUrl: PropTypes.string,
      })
    ),
    court: PropTypes.shape({
      owner: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
      }),
    }),
  }).isRequired,
  onDelete: PropTypes.func,
};

export default LivestreamCard;
