import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import api from '../../utils/api';
import { media } from '../../utils/media';

const DashboardFeedback = () => {
  const user = useSelector((state) => state.auth.user);
  const [feedbackContent, setFeedbackContent] = useState('');
  const [feedbackError, setFeedbackError] = useState('');
  const [feedbackSuccess, setFeedbackSuccess] = useState('');

  const handleFeedbackSubmit = async (e) => {
    e.preventDefault();
    if (!feedbackContent.trim()) {
      setFeedbackError('Please provide some feedback before submitting.');
      return;
    }
    try {
      const feedbackData = {
        user: { id: user.id },
        feedbackContent,
        timestamp: new Date().toISOString(),
      };
      await api.post('/feedback/submit', feedbackData);
      setFeedbackSuccess('Thank you for your feedback!');
      setFeedbackContent('');
      setFeedbackError('');
    } catch (err) {
      setFeedbackError('Failed to submit feedback. Please try again.');
    }
  };

  return (
    <FeedbackContainer>
      <FeedbackTitle>We’d Love to Hear from You</FeedbackTitle>
      <Subtitle>Leave us feedback about your experience.</Subtitle>

      {feedbackSuccess && <SuccessMessage>{feedbackSuccess}</SuccessMessage>}
      {feedbackError && <ErrorMessage>{feedbackError}</ErrorMessage>}

      <form onSubmit={handleFeedbackSubmit}>
        <TextArea
          placeholder="Write your feedback here..."
          value={feedbackContent}
          onChange={(e) => setFeedbackContent(e.target.value)}
        />
        <SubmitButton type="submit">Submit Feedback</SubmitButton>
      </form>
    </FeedbackContainer>
  );
};

export default DashboardFeedback;

const FeedbackContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryBackground};
  padding: 20px;
  margin-top: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  font-family: 'Raleway', sans-serif;
`;

const FeedbackTitle = styled.h3`
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 10px;
`;

const Subtitle = styled.p`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.textSecondary};
  margin-bottom: 20px;
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 140px;
  padding: 12px;
  border-radius: 6px;
  resize: none;
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  font-size: 1rem;
  margin-bottom: 15px;
  font-family: 'Raleway', sans-serif;
  transition: border-color 0.3s ease, background-color 0.3s ease;

  &:focus {
    border-color: ${({ theme }) => theme.colors.accentOrange};
    background-color: ${({ theme }) => theme.colors.inputFocusBackground || '#fff7f0'};
    outline: none;
  }
`;

const SubmitButton = styled.button`
  padding: 12px 24px;
  background-color: ${({ theme }) => theme.colors.accents[1] || '#1976d2'};
  color: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-family: 'Raleway', sans-serif;
  transition: background-color 0.3s ease;
  font-size: 1rem;

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary || '#1565c0'};
  }

  ${media.mobile`
    font-size: 0.9rem;
  `}
`;

const SuccessMessage = styled.p`
  color: ${({ theme }) => theme.colors.success || '#388e3c'};
  font-size: 1.1rem;
  margin-bottom: 15px;
  font-family: 'Raleway', sans-serif;
`;

const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.error || '#d32f2f'};
  font-size: 1.1rem;
  margin-bottom: 15px;
  font-family: 'Raleway', sans-serif;
`;
