import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { register } from '../../store/authSlice';
import debounce from 'lodash/debounce';
import { FaUser, FaEnvelope, FaLock, FaCalendar } from 'react-icons/fa';

import RegisterNavbar from './RegisterNavbar';
import {
  Container,
  StyledForm,
  LogoWrapper,
  Logo,
  FormTitle,
  FormSubtitle,
  FormGroup,
  Label,
  Input,
  FormError,
  ButtonGroup,
  Button,
  SecondaryButton,
  Error,
  DateInput,
} from './RegisterStyles';

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const registrationState = useSelector((state) => state.auth);
  const [form, setForm] = useState({ username: '', email: '', password: '', dob: '' });
  const [formErrors, setFormErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(true);

  const from = location.state?.from || '/explore';
  const videoIds = location.state?.videoIds;
  const duration = location.state?.duration;
  const thumbnails = location.state?.thumbnails;

  // Redirect on successful registration
  useEffect(() => {
    if (registrationState.user) {
      if (videoIds && duration && thumbnails) {
        navigate('/confirmation', { state: { videoIds, duration, thumbnails } });
      } else {
        navigate(from);
      }
      setForm({ username: '', email: '', password: '', dob: '' });
    }
  }, [registrationState.user, navigate, from, videoIds, duration, thumbnails]);

  // Debounced form validation
  useEffect(() => {
    const debouncedValidate = debounce(() => {
      const errors = {};

      // Username Validation
      if (!form.username.trim()) errors.username = 'Username is required.';

      // Email Validation
      if (!form.email.trim() || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(form.email))
        errors.email = 'Please enter a valid email address.';

      // Password Validation
      if (!form.password.trim()) errors.password = 'Password is required.';

      // DOB Validation
      if (!form.dob) {
        errors.dob = 'Date of Birth is required.';
      } else {
        const dobDate = new Date(form.dob);
        const today = new Date();
        let age = today.getFullYear() - dobDate.getFullYear();
        const m = today.getMonth() - dobDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < dobDate.getDate())) {
          age--;
        }
        if (age < 13) {
          errors.dob = 'You must be at least 13 years old to register.';
        }
      }

      setFormErrors(errors);
      setIsFormValid(Object.keys(errors).length === 0);
    }, 300);

    debouncedValidate();
    return () => debouncedValidate.cancel();
  }, [form]);

  const handleChange = useCallback((e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  }, [form]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isFormValid) return;
    dispatch(register(form));
  };

  return (
    <>
      <RegisterNavbar logoSrc="/1.svg" />
      <Container as="main">
        <StyledForm onSubmit={handleSubmit}>
          <LogoWrapper>
            <Logo 
              src="/1.svg" 
              alt="Akiture Logo"
              width={120}
              height={40}
              priority
            />
          </LogoWrapper>
          <FormTitle>Create Your Account</FormTitle>
          <FormSubtitle>Join us to access exclusive video content</FormSubtitle>

          <FormGroup>
            <Label htmlFor="username">
              <FaUser />
              Username
            </Label>
            <Input
              type="text"
              name="username"
              id="username"
              value={form.username}
              onChange={handleChange}
              placeholder="Choose a username"
              disabled={registrationState.loading}
              aria-invalid={formErrors.username ? 'true' : 'false'}
              aria-describedby="username-error"
            />
            {formErrors.username && <FormError id="username-error">{formErrors.username}</FormError>}
          </FormGroup>

          <FormGroup>
            <Label htmlFor="email">
              <FaEnvelope />
              Email
            </Label>
            <Input
              type="email"
              name="email"
              id="email"
              value={form.email}
              onChange={handleChange}
              placeholder="Enter your email"
              disabled={registrationState.loading}
              aria-invalid={formErrors.email ? 'true' : 'false'}
              aria-describedby="email-error"
            />
            {formErrors.email && <FormError id="email-error">{formErrors.email}</FormError>}
          </FormGroup>

          <FormGroup>
            <Label htmlFor="password">
              <FaLock />
              Password
            </Label>
            <Input
              type="password"
              name="password"
              id="password"
              value={form.password}
              onChange={handleChange}
              placeholder="Create a password"
              disabled={registrationState.loading}
              aria-invalid={formErrors.password ? 'true' : 'false'}
              aria-describedby="password-error"
            />
            {formErrors.password && <FormError id="password-error">{formErrors.password}</FormError>}
          </FormGroup>

          <FormGroup>
            <Label htmlFor="dob">
              <FaCalendar />
              Date of Birth
            </Label>
            <DateInput
              type="date"
              name="dob"
              id="dob"
              value={form.dob}
              onChange={handleChange}
              disabled={registrationState.loading}
              max={new Date().toISOString().split("T")[0]}
              aria-invalid={formErrors.dob ? 'true' : 'false'}
              aria-describedby="dob-error"
            />
            {formErrors.dob && <FormError id="dob-error">{formErrors.dob}</FormError>}
          </FormGroup>

          <ButtonGroup>
            <Button 
              type="submit" 
              disabled={!isFormValid || registrationState.loading}
              aria-label="Create account"
            >
              {registrationState.loading ? 'Creating Account...' : 'Create Account'}
            </Button>
            <SecondaryButton 
              type="button" 
              onClick={() => navigate('/login')}
              disabled={registrationState.loading}
            >
              Already have an account? Sign in
            </SecondaryButton>
          </ButtonGroup>

          {registrationState.error && <Error role="alert">{registrationState.error}</Error>}
        </StyledForm>
      </Container>
    </>
  );
};

export default Register;
