// UpdateLivestreamModal.jsx
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import api from 'js-cookie';

function UpdateLivestreamModal({ show, onHide, initialData, onUpdated }) {
  const [description, setDescription] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [price, setPrice] = useState('');
  const [logoType, setLogoType] = useState('');
  const [error, setError] = useState(null);
  const [sponsorsList, setSponsorsList] = useState([]);

  // Update state when initialData changes
  useEffect(() => {
    if (initialData) {
      setDescription(initialData.description || '');
      setStartTime(initialData.startTime || '');
      setEndTime(initialData.endTime || '');
      setPrice(initialData.price || '');
      setLogoType(initialData.logoType || '');
      setSponsorsList(initialData.sponsorsList || []);
    }
  }, [initialData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    const updatedLivestream = {
      id: initialData.id, // The controller sets the ID based on the URL.
      description,
      startTime,
      endTime,
      price,
      logoType,
      sponsorsList: initialData.sponsorsList,
    };

    try {
      const response = await api.put(`/livestreams/${initialData.id}`,
         updatedLivestream);
      onUpdated(response.data); // Callback to update parent state.
      onHide(); // Close the modal.
    } catch (err) {
      console.error('Error updating livestream:', err);
      setError('Update failed. Please try again.');
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Update Livestream</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="formStartTime" className="mt-3">
            <Form.Label>Start Time</Form.Label>
            <Form.Control
              type="datetime-local"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="formEndTime" className="mt-3">
            <Form.Label>End Time</Form.Label>
            <Form.Control
              type="datetime-local"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="formPrice" className="mt-3">
            <Form.Label>Price</Form.Label>
            <Form.Control
              type="number"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="formLogoType" className="mt-3">
            <Form.Label>Logo Type</Form.Label>
            <Form.Control
              type="text"
              value={logoType}
              onChange={(e) => setLogoType(e.target.value)}
              required
            />
          </Form.Group>

          <Button variant="primary" type="submit" className="mt-4">
            Update Livestream
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default UpdateLivestreamModal;
