// src/components/Video/videoList/LocationDropdown.js
import React, { useState, useRef, useEffect } from 'react';
import { ChevronDown, MapPin, Camera } from 'lucide-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

function LocationDropdown({ locations, onSelect }) {
  const [isOpen, setIsOpen] = useState(false);
  const [expandedLocation, setExpandedLocation] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedCamera, setSelectedCamera] = useState(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
        setExpandedLocation(null);
      }
    };
  
    document.addEventListener('click', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
  
    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);
  

  const handleLocationClick = (location) => {
    if (location.cameras.length === 1) {
      handleCameraSelect(location, location.cameras[0]);
    }
  };

  const handleCameraSelect = (location, camera) => {
    setSelectedLocation(location);
    setSelectedCamera(camera);
    onSelect(location.id, camera.id);
    setIsOpen(false);
    setExpandedLocation(null);
  };

  return (
    <DropdownWrapper ref={dropdownRef}>
      <DropdownButton onClick={() => setIsOpen(!isOpen)}>
        <IconWrapper>
          <MapPin size={16} />
        </IconWrapper>
        <ButtonText>
          {selectedLocation && selectedCamera
            ? `${selectedLocation.name} - ${selectedCamera.name}`
            : 'Select Location'}
        </ButtonText>
        <ChevronDown size={16} />
      </DropdownButton>
      {isOpen && (
        <DropdownMenu>
          <List>
            {locations.map((location) => (
              <LocationContainer
                key={location.id}
                onMouseEnter={() => location.cameras.length > 1 && setExpandedLocation(location.id)}
                onMouseLeave={() => location.cameras.length > 1 && expandedLocation === location.id && setExpandedLocation(null)}
              >
                <ListItem onClick={() => handleLocationClick(location)}>
                  {location.name}
                  {location.cameras.length > 1 && <ChevronDown size={12} />}
                </ListItem>
                {expandedLocation === location.id && location.cameras.length > 1 && (
                  <SubMenu>
                    {location.cameras.map((camera) => (
                      <SubListItem key={camera.id} onClick={() => handleCameraSelect(location, camera)}>
                        <IconWrapper>
                          <Camera size={16} />
                        </IconWrapper>
                        {camera.name}
                      </SubListItem>
                    ))}
                  </SubMenu>
                )}
              </LocationContainer>
            ))}
          </List>
        </DropdownMenu>
      )}
    </DropdownWrapper>
  );
}

LocationDropdown.propTypes = {
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      cameras: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired
        })
      ).isRequired
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired
};

export default LocationDropdown;

const DropdownWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const DropdownButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.lg}`};
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.textPrimary};
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: ${({ theme }) => theme.borderRadius.xs};
  box-shadow: ${({ theme }) => theme.shadows.small};
  transition: ${({ theme }) => theme.transitions.button};
  &:hover {
    background-color: ${({ theme }) => theme.colors.lightBackground};
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 5px ${({ theme }) => theme.colors.secondary}50;
  }
`;

const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing.xs};
`;

const ButtonText = styled.span`
  flex-grow: 1;
`;

const DropdownMenu = styled.div`
  position: absolute;
  z-index: 10;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.xs};
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: ${({ theme }) => theme.borderRadius.xs};
  box-shadow: ${({ theme }) => theme.shadows.medium};
  overflow: hidden;
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
`;

const LocationContainer = styled.div`
  position: relative;
  width: 100%;
`;

const ListItem = styled.li`
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.md}`};
  cursor: pointer;
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  &:hover {
    background-color: ${({ theme }) => theme.colors.lightBackground};
  }
`;

const SubMenu = styled.ul`
  list-style: none;
  margin: 0;
  padding-left: ${({ theme }) => theme.spacing.md};
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
`;

const SubListItem = styled.li`
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.md}`};
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  &:hover {
    background-color: ${({ theme }) => theme.colors.lightBackground};
  }
`;
