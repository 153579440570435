// src/components/HeroSection.js

import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FaArrowDown, FaUser } from 'react-icons/fa';
import HeroImage from '../assets/images/hero.jpg';
import { media } from '../utils/media';
import { Link } from 'react-router-dom';

const HeroSection = ({ user, handleLogin }) => {
  return (
    <HeroContainer>
      <HeroContent>
        <TextSection>
          <Title>All your moments, perfectly preserved</Title>
          <Description>
            Your best sport memories, at your fingertips.
          </Description>
          <CTAButtons>
            {!user ? (
              <>
                <PrimaryButton onClick={handleLogin}>
                  Get Started
                </PrimaryButton>
                <SecondaryButton as={Link} to="/explore">
                  Explore Courts
                </SecondaryButton>
              </>
            ) : (
              <PrimaryButton as={Link} to="/explore">
                Explore Courts
              </PrimaryButton>
            )}
          </CTAButtons>
        </TextSection>
        <BannerSection>
          <BannerImage src={HeroImage} alt="Pickleball Court" />
          <BannerOverlay />
        </BannerSection>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;

/* ─────────────────────────────────────────────────────────
   STYLED COMPONENTS
───────────────────────────────────────────────────────── */

/* Main container covering the full viewport height */
const HeroContainer = styled.section`
  min-height: 100vh;
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.xl} 0;
  background-color: ${({ theme }) => theme.colors.primaryBackground};
  position: relative;
  overflow: hidden;

  /* A subtle radial gradient behind everything */
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: radial-gradient(circle at top right, rgba(255, 111, 60, 0.1), transparent 70%);
    z-index: 0;
  }

  ${media.tablet(`
    padding: ${({ theme }) => theme.spacing.lg} 0;
    min-height: auto; /* Let content dictate height on smaller screens */
  `)}

  ${media.mobile(`
    padding: ${({ theme }) => theme.spacing.md} 0;
  `)}
`;

/* Content wrapper */
const HeroContent = styled.div`
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 ${({ theme }) => theme.spacing.lg};
  position: relative;
  z-index: 2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spacing.xl};
  align-items: center;

  ${media.tablet(`
    grid-template-columns: 1fr;
    gap: ${({ theme }) => theme.spacing.lg};
  `)}

  ${media.mobile(`
    padding: 0 ${({ theme }) => theme.spacing.md};
    gap: ${({ theme }) => theme.spacing.lg};
  `)}
`;

/* Text section */
const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.lg};
  max-width: 600px;
  margin: 0;
  text-align: left;
  animation: fadeInUp 1s ease-out forwards;

  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  ${media.tablet(`
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
  `)}

  ${media.mobile(`
    max-width: 100%;
    text-align: center;
  `)}
`;

/* Heading with gradient text and a subtle underline */
const Title = styled.h1`
  font-size: clamp(2.5rem, 5vw, 4rem);
  font-weight: 800;
  line-height: 1.2;
  margin: 0;
  position: relative;
  display: inline-block;
  color: ${({ theme }) => theme.colors.title};
  letter-spacing: -0.02em;

  /* Gradient text effect */
  background: linear-gradient(
    135deg,
    ${({ theme }) => theme.colors.primary} 0%,
    ${({ theme }) => theme.colors.secondary} 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  /* Underline effect */
  &::after {
    content: '';
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 4px;
    background: linear-gradient(
      90deg,
      ${({ theme }) => theme.colors.primary},
      ${({ theme }) => theme.colors.secondary}
    );
    border-radius: 2px;
  }

  ${media.tablet(`
    font-size: clamp(2rem, 5vw, 3rem);
  `)}

  ${media.mobile(`
    font-size: clamp(1.8rem, 6vw, 2.5rem);
  `)}
`;

/* Description with a vertical accent line on the left */
const Description = styled.p`
  font-size: clamp(1.1rem, 2.5vw, 1.3rem);
  color: ${({ theme }) => theme.colors.textSecondary};
  line-height: 1.6;
  margin: 0;
  position: relative;
  padding-left: 24px;
  font-weight: 400;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 4px;
    height: 80%;
    background: linear-gradient(
      to bottom,
      ${({ theme }) => theme.colors.primary},
      ${({ theme }) => theme.colors.secondary}
    );
    border-radius: 2px;
  }

  ${media.tablet(`
    font-size: 1.1rem;
    margin: 0 auto;
    padding-left: 0;
    &::before {
      display: none;
    }
  `)}

  ${media.mobile(`
    font-size: 1rem;
  `)}
`;

/* CTA button container */
const CTAButtons = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.md};
  margin-top: ${({ theme }) => theme.spacing.md};
  animation: fadeInUp 0.8s ease-out 0.3s both;

  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  ${media.tablet(`
    justify-content: center;
  `)}

  ${media.mobile(`
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.sm};
  `)}
`;

/* Primary CTA Button */
const PrimaryButton = styled.button`
  background: linear-gradient(
    135deg,
    ${({ theme }) => theme.colors.primary} 0%,
    ${({ theme }) => theme.colors.secondary} 100%
  );
  color: ${({ theme }) => theme.colors.white};
  border: none;
  padding: ${({ theme }) => theme.spacing.md} ${({ theme }) => theme.spacing.xl};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  font-size: clamp(1rem, 1.5vw, 1.1rem);
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  min-width: 160px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
    transition: 0.5s;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);

    &::before {
      left: 100%;
    }
  }

  ${media.tablet(`
    font-size: 1rem;
    padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.lg};
  `)}

  ${media.mobile(`
    width: 100%;
    font-size: 0.95rem;
    padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.md};
  `)}
`;

/* Secondary CTA Button */
const SecondaryButton = styled(PrimaryButton)`
  background: transparent;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.primary};
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: linear-gradient(
      135deg,
      ${({ theme }) => theme.colors.primary} 0%,
      ${({ theme }) => theme.colors.secondary} 100%
    );
    transition: width 0.3s ease;
    z-index: -1;
  }

  &:hover {
    background: transparent;
    color: ${({ theme }) => theme.colors.white};

    &::before {
      width: 100%;
    }
  }
`;

/* Banner section */
const BannerSection = styled.div`
  position: relative;
  width: 100%;
  height: 600px;
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  animation: fadeInUp 1s ease-out 0.5s both;

  ${media.tablet(`
    height: 400px;
  `)}

  ${media.mobile(`
    height: 300px;
  `)}
`;

/* Banner Image styling */
const BannerImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

/* Dark overlay to darken the banner image */
const BannerOverlay = styled.div`
  position: absolute;
  inset: 0;
  background: linear-gradient(
    135deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0.75) 50%,
    rgba(0, 0, 0, 0.8) 100%
  );
  z-index: 1;

  /* Optional decorative radial gradient in the corner */
  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background: radial-gradient(
      circle at top right,
      rgba(255, 111, 60, 0.2),
      transparent 70%
    );
  }
`;
