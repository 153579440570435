import React, { useState, useEffect } from "react";
import api from "../../utils/api";
import CourtInfo from "./CourtInfo";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { fetchSiteOwnerCourts } from "../../store/CourtSlice";

const Courts = () => {
  const dispatch = useDispatch();

  const { courts, loading, error } = useSelector((state) => state.court);

  console.log("Courts:", courts);

  useEffect(() => {
    dispatch(fetchSiteOwnerCourts()); // dispatch the thunk
  }, [dispatch]);

  return (
    <Container>
      <Header>Courts Information</Header>
      {loading && <p>Loading courts...</p>}
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {!loading && !error && courts.length === 0 && <p>No courts available.</p>}
      {!loading && !error && courts.length > 0 && <CourtInfo courts={courts} />}
    </Container>
  );
};

export default Courts;

/* Styled Components */
const Container = styled.div`
  padding: 24px;
`;

const Header = styled.h1`
  font-size: 2rem;
  margin-bottom: 16px;
`;

const ErrorMessage = styled.p`
  color: red;
  margin: 16px 0;
`;
