import React from 'react';
import styled from 'styled-components';
import { FaLinkedin, FaInstagram } from 'react-icons/fa';
import FooterLogo from '../../assets/images/2.svg';
import 'bootstrap/dist/css/bootstrap.min.css';

const FooterContainer = styled.footer`
  background-color: ${({ theme }) => theme.colors.darkBackground || '#111'};
  color: ${({ theme }) => theme.colors.textSecondary || '#ccc'};
  padding: 2rem ${({ theme }) => theme.spacing.md};
  box-shadow: ${({ theme }) => theme.shadows.medium};
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.lg};
  justify-content: space-between;
`;

const LogoSection = styled.div`
  flex: 1 1 250px;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
`;

const FooterLogoStyled = styled.img`
  width: ${({ theme }) => theme.sizes.logo.medium || '60px'};
`;

const LogoTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const LogoText = styled.span`
  font-size: ${({ theme }) => theme.fontSizes['2xl'] || '1.5rem'};
  font-weight: bold;
  color: ${({ theme }) => theme.colors.secondary || '#BD2026'};
`;

const LogoSubtitle = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.sm || '0.875rem'};
  color: ${({ theme }) => theme.colors.textSecondary || '#ccc'};
`;

const LinksSection = styled.div`
  flex: 1 1 150px;
`;

const PolicySection = styled.div`
  flex: 1 1 150px;
`;

const SectionTitle = styled.h4`
  font-size: ${({ theme }) => theme.fontSizes.lg || '1.25rem'};
  margin-bottom: ${({ theme }) => theme.spacing.sm};
  color: ${({ theme }) => theme.colors.title || '#fff'};
  position: relative;
  padding-bottom: ${({ theme }) => theme.spacing.xs};

  &:after {
    content: '';
    position: absolute;
    width: 40px;
    height: 2px;
    background: ${({ theme }) => theme.colors.accents[1] || '#BD2026'};
    bottom: 0;
    left: 0;
  }
`;

const FooterLinkList = styled.ul`
  list-style: none;
  padding: 0;
`;

const FooterLinkItem = styled.li`
  margin-bottom: ${({ theme }) => theme.spacing.sm};
`;

const FooterLink = styled.a`
  color: ${({ theme }) => theme.colors.secondaryBackground || '#ccc'};
  text-decoration: none;
  font-size: ${({ theme }) => theme.fontSizes.md || '1rem'};
  transition: color 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary || '#BD2026'};
  }
`;

const SocialSection = styled.div`
  flex: 1 1 150px;
`;

const SocialIcons = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.md};
`;

const SocialIcon = styled.a`
  color: ${({ theme }) => theme.colors.secondaryBackground || '#ccc'};
  font-size: ${({ theme }) => theme.fontSizes['2xl'] || '1.5rem'};
  transition: color 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary || '#BD2026'};
  }
`;

const FooterBottom = styled.div`
  text-align: center;
  border-top: 1px solid ${({ theme }) => theme.colors.border || '#333'};
  padding-top: ${({ theme }) => theme.spacing.md};
  margin-top: ${({ theme }) => theme.spacing.lg};
  font-size: ${({ theme }) => theme.fontSizes.sm || '0.875rem'};
  color: ${({ theme }) => theme.colors.textSecondary || '#ccc'};

  p {
    margin: 0;
  }

  a {
    color: ${({ theme }) => theme.colors.secondary || '#BD2026'};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <LogoSection>
          <FooterLogoStyled src={FooterLogo} alt="Akture Footer Logo" />
          <LogoTextContainer>
            <LogoText>Akture</LogoText>
            <LogoSubtitle>Action Capture System</LogoSubtitle>
          </LogoTextContainer>
        </LogoSection>

        <LinksSection>
          <SectionTitle>Explore</SectionTitle>
          <FooterLinkList>
            <FooterLinkItem>
              <FooterLink href="/">Home</FooterLink>
            </FooterLinkItem>
            <FooterLinkItem>
              <FooterLink href="/explore">Purchase</FooterLink>
            </FooterLinkItem>
            <FooterLinkItem>
              <FooterLink href="/contact">Contact</FooterLink>
            </FooterLinkItem>
          </FooterLinkList>
        </LinksSection>

        <PolicySection>
          <SectionTitle>Policies</SectionTitle>
          <FooterLinkList>
            <FooterLinkItem>
              <FooterLink href="/privacy-policy">Privacy Policy</FooterLink>
            </FooterLinkItem>
            <FooterLinkItem>
              <FooterLink href="/cookie-policy">Cookie Policy</FooterLink>
            </FooterLinkItem>
            <FooterLinkItem>
              <FooterLink href="/terms-of-service">Terms of Service</FooterLink>
            </FooterLinkItem>
            <FooterLinkItem>
              <FooterLink href="/return-policy">Return Policy</FooterLink>
            </FooterLinkItem>
            <FooterLinkItem>
              <FooterLink href="/disclaimer">Disclaimer</FooterLink>
            </FooterLinkItem>
          </FooterLinkList>
          <FooterLinkItem>
            <FooterLink href="/terms-of-servicee" target="_blank">Terms of service</FooterLink>
          </FooterLinkItem>
          <FooterLinkItem>
            <FooterLink href="/privacy-policyy" target="_blank">Privacy policy</FooterLink>
          </FooterLinkItem>
        </PolicySection>

        <SocialSection>
          <SectionTitle>Follow Us</SectionTitle>
          <SocialIcons>
            <SocialIcon
              href="https://linkedin.com/company/crazed-entertainment/"
              aria-label="LinkedIn"
            >
              <FaLinkedin />
            </SocialIcon>
            <SocialIcon
              href="https://instagram.com/akture.video"
              aria-label="Instagram"
            >
              <FaInstagram />
            </SocialIcon>
          </SocialIcons>
        </SocialSection>
      </FooterContent>

      <FooterBottom>
        <p>
          Contact us at{' '}
          <a href="mailto:contact@akture.video">contact@akture.video</a>
        </p>
        &copy; {new Date().getFullYear()} Akture. All rights reserved.
      </FooterBottom>
    </FooterContainer>
  );
};

export default Footer;


