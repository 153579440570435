

import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVideos, setSelectedCourt } from '../../../store/videoSlice';
import { fetchCourts } from '../../../store/CourtSlice';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { CircularProgress } from '@mui/material';
import { hasValidFilters } from '../../../utils/helper'; // your custom function
import useFilteredVideos from '../UseFilteredVideos';

import Pagination from './Pagination';
import LocationDropdown from './LocationDropdown';
import VideoCard from '../VideoCard';

const ITEMS_PER_PAGE = 10;
const MAX_SELECTED_VIDEOS = 10;

/** Example time conversion if needed. */
function convertTimeToSeconds(timeStr) {
  if (!timeStr) return NaN;
  const [time, modifier] = timeStr.split(' ');
  let [hours, minutes] = time.split(':').map(Number);

  if (modifier === 'PM' && hours !== 12) hours += 12;
  if (modifier === 'AM' && hours === 12) hours = 0;

  if (
    isNaN(hours) ||
    isNaN(minutes) ||
    hours < 0 ||
    hours > 23 ||
    minutes < 0 ||
    minutes > 59
  ) {
    return NaN;
  }
  return hours * 3600 + minutes * 60;
}

const VideoListing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // --- Redux states ---
  const {
    videos,
    totalPages,
    loading,
    error,
    selectedCourt,
  } = useSelector((state) => state.video);

  const { courts } = useSelector((state) => state.court);

  // --- Local filter states ---
  // Change this to '' if you want to force the user to pick a date
  const [selectedDate, setSelectedDate] = useState('today');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [validationError, setValidationError] = useState('');
  const [maxVideosError, setMaxVideosError] = useState('');

  // Track current page (1-based)
  const [currentPage, setCurrentPage] = useState(1);

  const filteredVideos = useFilteredVideos(videos, selectedDate,
     startTime, endTime, selectedCourt);

  // On mount, fetch courts only (not videos)
  useEffect(() => {
    dispatch(fetchCourts());
  }, [dispatch]);

  // Re-run the video fetch whenever filters or pagination change
  useEffect(() => {
    // Only fetch if the user has selected the required filters
    if (!hasValidFilters(selectedDate, startTime, endTime, selectedCourt)) {
      return;
    }

    console.log('Fetching videos with filters:', {
      selectedDate,
      startTime,
      endTime,
      selectedCourt,
    });

    // Example: pass filters if your backend needs them
    dispatch(fetchVideos({
      page: currentPage - 1,
      size: ITEMS_PER_PAGE,
      court: selectedCourt,
      date: selectedDate,
      startTime,
      endTime,
    }));
    
  }, [
    dispatch,
    currentPage,
    selectedDate,
    startTime,
    endTime,
    selectedCourt
  ]);

  // Build locations from courts for the camera dropdown
  const locations = useMemo(() => {
    return courts.map((court) => ({
      id: court.courtId,
      name: court.courtName,
      cameras: Object.keys(court.cameraUrls ?? {}).map((cameraName) => ({
        id: cameraName,
        name: cameraName,
      })),
    }));
  }, [courts]);

  // Example: skip 1am - 5:59am from the time dropdown
  const timeOptions = useMemo(() => {
    const allTimes = Array.from({ length: 48 }, (_, i) => {
      const hour = Math.floor(i / 2) % 12 || 12;
      const minutes = i % 2 === 0 ? '00' : '30';
      const period = i < 24 ? 'AM' : 'PM';
      return `${hour}:${minutes} ${period}`;
    });
    // skip 1:00 AM to 5:59 AM
    const oneAM = convertTimeToSeconds('1:00 AM');
    const sixAM = convertTimeToSeconds('6:00 AM');
    let filtered = allTimes.filter((t) => {
      const sec = convertTimeToSeconds(t);
      return !(sec >= oneAM && sec < sixAM);
    });
    // re-sort so times after midnight come last
    filtered.sort((a, b) => {
      const aSec = convertTimeToSeconds(a);
      const bSec = convertTimeToSeconds(b);
      const oneAMSec = convertTimeToSeconds('1:00 AM');
      const aAdj = aSec < oneAMSec ? aSec + 86400 : aSec;
      const bAdj = bSec < oneAMSec ? bSec + 86400 : bSec;
      return aAdj - bAdj;
    });
    return filtered;
  }, []);

  // Handle time picking with optional validation
  const handleTimeChange = useCallback(
    (setter, fieldType) => (e) => {
      const value = e.target.value;
      if (!value) {
        setter('');
        setValidationError('');
        return;
      }
      // Basic parse
      const [time, ampm] = value.split(' ');
      const [hours, minutes] = time.split(':').map(Number);
      if (
        isNaN(hours) ||
        isNaN(minutes) ||
        hours < 1 ||
        hours > 12 ||
        minutes < 0 ||
        minutes > 59 ||
        (ampm !== 'AM' && ampm !== 'PM')
      ) {
        setValidationError('Invalid time format. Please use HH:MM AM/PM format.');
        setter('');
        return;
      }
      setter(value);
      setValidationError('');

      // Enforce start < end if you wish
      if (fieldType === 'start' && endTime) {
        const startSec = convertTimeToSeconds(value);
        let endSec = convertTimeToSeconds(endTime);
        // if end < start, assume user wants next-day end
        if (endSec < startSec) endSec += 86400;
        if (startSec >= endSec) {
          setValidationError('Start time must be before end time.');
          setter('');
        }
      } else if (fieldType === 'end' && startTime) {
        let startSec = convertTimeToSeconds(startTime);
        const endSec = convertTimeToSeconds(value);
        if (endSec < startSec) startSec -= 86400;
        if (startSec >= endSec) {
          setValidationError('End time must be after start time.');
          setter('');
        }
      }
    },
    [startTime, endTime]
  );

  // Handle location/camera selection
  const handleLocationSelect = useCallback(
    (locationId, selectedCourt) => {
      dispatch(setSelectedCourt(selectedCourt));
    },
    [dispatch]
  );

  // Handle video selection
  const handleVideoSelection = useCallback((videoId) => {
    setMaxVideosError('');
    setSelectedVideos((prev) => {
      if (prev.includes(videoId)) {
        return prev.filter((id) => id !== videoId);
      }
      if (prev.length >= MAX_SELECTED_VIDEOS) {
        setMaxVideosError(`You can only select up to ${MAX_SELECTED_VIDEOS} videos.`);
        return prev;
      }
      return [...prev, videoId];
    });
  }, []);

  // Proceed to confirmation
  const [isNavigating, setIsNavigating] = useState(false);
  const handleProceedToConfirmation = useCallback(() => {
    setIsNavigating(true);
    const videoIds = selectedVideos;

    const selectedThumbs = videos
      .filter((v) => videoIds.includes(v.id))
      .map((v) => v.thumbnails?.[Object.keys(v.thumbnails)[0]] || '/fallback-thumbnail.png');

    navigate('/confirmation', {
      state: {
        videoIds,
        thumbnails: selectedThumbs,
        processing: true,
      },
    });
  }, [navigate, selectedVideos, videos]);

  // Clear all filters
  const handleClearFilters = () => {
    setSelectedDate('');
    setStartTime('');
    setEndTime('');
    setSelectedVideos([]);
    setValidationError('');
    setMaxVideosError('');
    setCurrentPage(1);
    dispatch(setSelectedCourt(null));
  };

  // Show a spinner if loading
  if (loading.fetchVideos) {
    return (
      <Container>
        <CircularProgress />
        <Message>Loading videos...</Message>
      </Container>
    );
  }

  // If an error occurred
  if (error) {
    return (
      <Container>
        <ErrorText>{error}</ErrorText>
        <RetryButton onClick={() => {
          dispatch(fetchVideos({
            page: 0,
            size: ITEMS_PER_PAGE,
            date: selectedDate,
            startTime,
            endTime,
            court: selectedCourt
          }));
        }}>
          Retry
        </RetryButton>
      </Container>
    );
  }

  // Check if the filters are valid enough to fetch & display results
  const validFilters = hasValidFilters(selectedDate, startTime, endTime, selectedCourt);

  return (
    <Container>
      <StepsIndicator>
        Select your video(s) &gt; Confirm &gt; Checkout &gt; Play-trim-crop-share
      </StepsIndicator>

      {/* Filter UI */}
    {/* Filter UI */}
<FilterContainer>
  <LocationSection>
    <Label>Select Court & Camera:</Label>
    <FullWidthWrapper>
      <LocationDropdown
        locations={locations}
        onSelect={handleLocationSelect}
      />
    </FullWidthWrapper>
    {selectedCourt && (
      <SelectedCourtIndicator>
        Selected Camera: <strong>{selectedCourt}</strong>
      </SelectedCourtIndicator>
    )}
  </LocationSection>

  <TimeRow>
    <SelectorWrapper>
      <Label>Date:</Label>
      <Select
        value={selectedDate}
        onChange={(e) => setSelectedDate(e.target.value)}
      >
        <option value="">--Select a Date--</option>
        <option value="today">Today</option>
        <option value="yesterday">Yesterday</option>
        <option value="last2days">2 days ago</option>
      </Select>
    </SelectorWrapper>

    <SelectorWrapper>
      <Label>Start Time:</Label>
      <TimeSelect
        value={startTime}
        onChange={handleTimeChange(setStartTime, 'start')}
      >
        <option value="">Select Start Time</option>
        {timeOptions.map((t) => (
          <option key={t} value={t}>
            {t}
          </option>
        ))}
      </TimeSelect>
    </SelectorWrapper>

    <SelectorWrapper>
      <Label>End Time:</Label>
      <TimeSelect
        value={endTime}
        onChange={handleTimeChange(setEndTime, 'end')}
      >
        <option value="">Select End Time</option>
        {timeOptions.map((t) => (
          <option key={t} value={t}>
            {t}
          </option>
        ))}
      </TimeSelect>
    </SelectorWrapper>

    <ClearButton onClick={handleClearFilters}>Clear Filters</ClearButton>
  </TimeRow>
</FilterContainer>


      {/* Validation errors */}
      {validationError && <ErrorText>{validationError}</ErrorText>}
      {maxVideosError && <ErrorText>{maxVideosError}</ErrorText>}

      {/* If filters aren't valid, show a friendly prompt */}
      {!validFilters && (
        <Message>Please select a date, time range, and camera to see videos.</Message>
      )}

      {/* If filters are valid but no videos found, or else show the videos */}
      {validFilters && (
        <>
          {isNavigating && (
            <Message>Redirecting to confirmation...</Message>
          )}

          {videos.length === 0 ? (
            <NoVideosMessage>
              No videos available for the selected timeframe.
            </NoVideosMessage>
          ) : (
            <>
              <InfoText>
                Confirm desired video segments for purchase. Multiple selections will be combined
                into one clip.
              </InfoText>
              <InfoText>Max purchasable length: 2 hours of video (no audio).</InfoText>

              {/* Video Grid */}
              <GridContainer>
                {filteredVideos.map((video) => (
                  <VideoCard
                    key={video.id}
                    video={video}
                    isSelected={selectedVideos.includes(video.id)}
                    onSelect={() => handleVideoSelection(video.id)}
                  />
                ))}
              </GridContainer>

              {/* Proceed Button */}
              {selectedVideos.length > 0 && (
                <StickyButtonWrapper>
                  <PrimaryButton onClick={handleProceedToConfirmation}>
                    Proceed to Confirmation
                  </PrimaryButton>
                </StickyButtonWrapper>
              )}

              {/* Pagination */}
              {totalPages > 1 && (
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={(page) => {
                    setCurrentPage(page);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }}
                />
              )}
            </>
          )}
        </>
      )}
    </Container>
  );
};
export default VideoListing;


// --- Styled Components ---

const Container = styled.div`
  max-width: 1200px;
  margin: ${({ theme }) => theme.spacing.xl} auto;
  padding: ${({ theme }) => theme.spacing.md};
  font-family: ${({ theme }) => theme.fonts.primary};
  background: ${({ theme }) => theme.colors.primaryBackground};
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column; /* Stack each section vertically */
  align-items: center;
  width: 100%;
  max-width: ${({ theme }) => theme.breakpoints.desktop};
  margin: 0 auto ${({ theme }) => theme.spacing.lg} auto;
  padding: ${({ theme }) => theme.spacing.md};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: ${({ theme }) => theme.spacing.xs};
  background-color: ${({ theme }) => theme.colors.primaryBackground};
  box-shadow: ${({ theme }) => theme.shadows.medium};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: ${({ theme }) => theme.spacing.sm};
    margin-bottom: ${({ theme }) => theme.spacing.md};
  }
`;

const LocationSection = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.md};
  text-align: center;
  width: 100%;

`;

const TimeRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.md};
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  width: 100%;
`;

const Label = styled.label`
  font-size: ${({ theme }) => theme.fontSizes.md};
  margin-bottom: ${({ theme }) => theme.spacing.xs};
  display: block;
  color: ${({ theme }) => theme.colors.textPrimary};
  font-family: ${({ theme }) => theme.fonts.primary};
  text-align: center;
`;

const FullWidthWrapper = styled.div`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
`;

const SelectedCourtIndicator = styled.div`
  padding: ${({ theme }) => theme.spacing.xs};
  background-color: ${({ theme }) => theme.colors.accents[0]}15;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  border-radius: ${({ theme }) => theme.spacing.xs};
  margin-bottom: ${({ theme }) => theme.spacing.sm};
  font-family: ${({ theme }) => theme.fonts.secondary};
  text-align: left;
`;

const Select = styled.select`
  padding: ${({ theme }) => theme.spacing.sm};
  font-size: ${({ theme }) => theme.fontSizes.md};
  width: 100%;
  border-radius: ${({ theme }) => theme.spacing.xs};
  border: 1px solid ${({ theme }) => theme.colors.border};
  background-color: ${({ theme }) => theme.colors.inputBackground};
  transition: ${({ theme }) => theme.transitions.input};
  font-family: ${({ theme }) => theme.fonts.secondary};

  &:focus {
    border-color: ${({ theme }) => theme.colors.secondary};
    outline: none;
    box-shadow: 0 0 5px ${({ theme }) => theme.colors.secondary}50;
  }
`;

/** Special select for scrolling time options. */
const TimeSelect = styled(Select)`
  max-height: 150px;
  overflow-y: auto;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.inputBackground};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.secondary};
    border-radius: 4px;
  }
  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => theme.colors.secondary} ${({ theme }) => theme.colors.inputBackground};
`;

const SelectorWrapper = styled.div`
  flex: 1 1 200px;
  min-width: 150px;
  text-align: center;
`;

const ClearButton = styled.button`
  background-color: ${({ theme }) => theme.colors.accents[1]};
  color: #ffffff;
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.lg};
  border: none;
  border-radius: ${({ theme }) => theme.spacing.xs};
  cursor: pointer;
  margin-top: auto;
  transition: ${({ theme }) => theme.transitions.button};
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-family: ${({ theme }) => theme.fonts.primary};

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 5px ${({ theme }) => theme.colors.secondary}50;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-top: 0;
    width: 100%;
  }
`;

const StepsIndicator = styled.div`
  width: 100%;
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.fontSizes.lg};
  color: ${({ theme }) => theme.colors.accents[0]};
  margin-bottom: ${({ theme }) => theme.spacing.md};
  font-weight: bold;
  line-height: 1.5;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.fontSizes.md};
    margin-bottom: ${({ theme }) => theme.spacing.sm};
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: ${({ theme }) => theme.spacing.md};
  margin-bottom: ${({ theme }) => theme.spacing.lg};
`;

const NoVideosMessage = styled.p`
  /* same styling you had */
`;

const StickyButtonWrapper = styled.div`
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.primaryBackground};
  padding: ${({ theme }) => theme.spacing.sm} 0;
  z-index: 999;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: ${({ theme }) => theme.spacing.sm};
    margin-bottom: ${({ theme }) => theme.spacing.md};
  }
`;

const PrimaryButton = styled.button`
  background-color: ${({ theme }) => theme.colors.secondary};
  color: #ffffff;
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.lg};
  border: none;
  border-radius: ${({ theme }) => theme.spacing.xs};
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSizes.md};
  transition: ${({ theme }) => theme.transitions.button};
  box-shadow: ${({ theme }) => theme.shadows.small};
  font-family: ${({ theme }) => theme.fonts.primary};

  &:hover {
    background-color: ${({ theme }) => theme.colors.accents[1]};
    transform: translateY(-2px);
    box-shadow: ${({ theme }) => theme.shadows.medium};
  }
  &:active {
    transform: translateY(0);
    box-shadow: ${({ theme }) => theme.shadows.small};
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 5px ${({ theme }) => theme.colors.secondary}50;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
    padding: ${({ theme }) => theme.spacing.sm};
  }
`;

const ErrorText = styled.p`
  color: ${({ theme }) => theme.colors.error};
  text-align: center;
`;

const InfoText = styled.p`
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing.sm};
  color: ${({ theme }) => theme.colors.secondary};
`;

const Message = styled.p`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.md};
  color: ${({ theme }) => theme.colors.textSecondary};
`;

const RetryButton = styled.button`
  background-color: ${({ theme }) => theme.colors.secondary};
  color: #ffffff;
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.lg};
  border: none;
  border-radius: ${({ theme }) => theme.spacing.xs};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.primary};
  margin: ${({ theme }) => theme.spacing.md} auto;
  display: block;

  &:hover {
    background-color: ${({ theme }) => theme.colors.accents[1]};
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 5px ${({ theme }) => theme.colors.secondary}50;
  }
`;