import React, { useState, useRef, useEffect } from 'react';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useNavigate } from 'react-router-dom';
import {
  FaUserCircle,
  FaTachometerAlt,
  FaUserEdit,
  FaTag,
  FaEnvelope,
  FaSignOutAlt,
} from 'react-icons/fa';
import { useAuth } from '../Auth/AuthProvider';
import styled, { ThemeProvider } from 'styled-components';
import Logo from './Logo';
import { theme } from '../common/theme';
import { media } from '../../utils/media';

const NavBar = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const closeTimeoutRef = useRef(null);

  // Check if device supports hover
  const hasHover = window.matchMedia('(any-hover: hover)').matches;

  const handleOpenDropdown = () => {
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current);
    }
    setIsDropdownOpen(true);
  };

  const handleCloseDropdown = () => {
    closeTimeoutRef.current = setTimeout(() => {
      setIsDropdownOpen(false);
    }, 200);
  };

  // Toggle logic for hover vs. click-based devices
  const wrapperProps = hasHover
    ? {
        onMouseEnter: handleOpenDropdown,
        onMouseLeave: handleCloseDropdown,
      }
    : {
        onClick: () => setIsDropdownOpen(!isDropdownOpen),
      };

  // Role checks
  const isAdmin = user && user.roles.includes('ADMIN');
  const isSiteOwner = user && user.roles.includes('SITE_OWNER');
  const isSiteOwnerAndAdmin = user && isSiteOwner && isAdmin;
  const isUser = user && user.roles.includes('USER');

  const handleLogout = async (e) => {
    e.preventDefault();
    await logout();
    navigate('/login');
  };

  useEffect(() => {
    return () => {
      // Clear any pending dropdown close timeouts
      if (closeTimeoutRef.current) {
        clearTimeout(closeTimeoutRef.current);
      }
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <NavbarWrapper>
        <BackgroundOverlay />
        <StyledNavbar expand="lg" variant="light">
          <ContainerStyled>
            {/* Brand Section (Left) */}
            <Navbar.Brand aria-label="Go to home">
              <LinkContainer to="/">
                <LogoLink>
                  <Logo 
                    logoSrc="/1.svg"
                    alt="Akiture Logo"
                    size="medium"
                  />
                </LogoLink>
              </LinkContainer>
            </Navbar.Brand>

            {/* Toggler (Hamburger Icon) for small screens */}
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />

            {/* Collapsible nav items */}
            <Navbar.Collapse id="responsive-navbar-nav">
              {/* Center Section */}
              <Nav className="mx-auto">
                <LinkContainer to="/">
                  <StyledNavLink>Home</StyledNavLink>
                </LinkContainer>

                <LinkContainer to="/explore">
                  <StyledNavLink>Purchase</StyledNavLink>
                </LinkContainer>

                <LinkContainer to="/all-livestream-events">
                  <StyledNavLink>All Livestreams</StyledNavLink>
                </LinkContainer>

                {/* Admin Link */}
                {user && isAdmin && (
                  <LinkContainer to="/admin/dashboard">
                    <StyledNavLink>Dashboard</StyledNavLink>
                  </LinkContainer>
                )}

                {/* Normal User Link */}
                {isUser && (
                  <LinkContainer to="/dashboard">
                    <StyledNavLink>Dashboard</StyledNavLink>
                  </LinkContainer>
                )}

                {/* Site Owner Link */}
                {user && isSiteOwner && (
                  <LinkContainer to="/courts">
                    <StyledNavLink>Courts</StyledNavLink>
                  </LinkContainer>
                )}

                {/* Livestreams dropdown, visible only to Site Owner & Admin */}
                {user && isSiteOwnerAndAdmin && (
                  <NavDropdown title="Livestreams" id="livestreams-dropdown">
                    <LinkContainer to="/schedule/live">
                      <NavDropdown.Item>Schedule Livestream</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/livestreams/watch">
                      <NavDropdown.Item>Watch Livestream</NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown>
                )}
              </Nav>

              {/* Right Section: User / Login */}
              <Nav className="ms-auto">
                {user ? (
                  <DropdownWrapper
                    {...wrapperProps}
                    aria-haspopup="true"
                    aria-expanded={isDropdownOpen}
                  >
                    <UserIcon aria-label="User Profile">
                      <FaUserCircle />
                    </UserIcon>
                    {isDropdownOpen && (
                      <DropdownMenu>
                        <DropdownHeader>
                          <SmallText>Logged in as:</SmallText>
                          <SmallBold>{user.username}</SmallBold>
                        </DropdownHeader>
                        <DropdownDivider />

                        {/* Admin Quick Link */}
                        {isAdmin && (
                          <LinkContainer to="/admin/dashboard">
                            <DropdownItem>
                              <FaTachometerAlt />
                              Admin
                            </DropdownItem>
                          </LinkContainer>
                        )}

                        {/* Site Owner Quick Link */}
                        {isSiteOwner && (
                          <LinkContainer to="/courts">
                            <DropdownItem>
                              <FaTachometerAlt />
                              Courts
                            </DropdownItem>
                          </LinkContainer>
                        )}

                        {/* User Quick Link */}
                        {isUser && (
                          <LinkContainer to="/dashboard">
                            <DropdownItem>
                              <FaTachometerAlt />
                              Dashboard
                            </DropdownItem>
                          </LinkContainer>
                        )}

                        <DropdownItem as="button">
                          <FaUserEdit />
                          Edit Account Details
                        </DropdownItem>
                        <DropdownItem as="button">
                          <FaTag />
                          Pricing
                        </DropdownItem>

                        <LinkContainer to="/contact">
                          <DropdownItem>
                            <FaEnvelope />
                            Contact Support
                          </DropdownItem>
                        </LinkContainer>

                        <DropdownDivider />

                        <DropdownItem as="button" onClick={handleLogout}>
                          <FaSignOutAlt />
                          Logout
                        </DropdownItem>
                      </DropdownMenu>
                    )}
                  </DropdownWrapper>
                ) : (
                  <LinkContainer to="/login">
                    <Nav.Link as="button">
                      <LoginButton>Login</LoginButton>
                    </Nav.Link>
                  </LinkContainer>
                )}
              </Nav>
            </Navbar.Collapse>
          </ContainerStyled>

          {/* Overlay for the dropdown on mobile */}
          {isDropdownOpen && <DropdownOverlay onClick={() => setIsDropdownOpen(false)} />}
        </StyledNavbar>
      </NavbarWrapper>
    </ThemeProvider>
  );
};

export default NavBar;

/* ─────────────────────────────────────────────
   STYLED COMPONENTS
────────────────────────────────────────────── */

/* 
  Wrapper to hold both the gradient overlay (behind) 
  and the 90% width navbar (in front).
*/
const NavbarWrapper = styled.div`
  position: relative;
  width: 100%;
`;

/* 
  Full-width gradient overlay banner. 
  Subtler gradient for a more modern feel.
*/
const BackgroundOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 102px; /* or match your navbar's height if desired */
  background: linear-gradient(135deg, rgba(102, 88, 85, 0.4) 0%, rgba(255, 255, 255, 0.2) 100%);
  z-index: 500; /* sits behind the navbar */
`;

/* 
  Main navbar container:
  - 90% width, centered
  - subtle shadow
  - fixed at top
  - consistent padding
*/
const StyledNavbar = styled(Navbar)`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.small};
  border: none;
  width: 90%;
  margin: 0 auto;
  position: fixed;
  top: 0;
  left: 0; 
  right: 0;
  z-index: 1000; /* above the overlay */
  display: flex;
  align-items: center;
  border-radius: 0;

  /* Default padding for desktop */
  padding: ${({ theme }) => theme.spacing.md} ${({ theme }) => theme.spacing.lg};

  ${media.tablet(`
    padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.md};
  `)}

  ${media.mobile(`
    padding: ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.sm};
  `)}
`;

const ContainerStyled = styled(Container)`
  width: 100%;
  max-width: 100%;
  padding: 0;
`;

/* 
  Logo container:
  - Slight hover scale 
  - Crisp transitions
*/
const LogoLink = styled.div`
  display: flex;
  align-items: center;
  transition: ${({ theme }) => theme.transitions.default};

  img {
    width: ${({ theme }) => theme.sizes.logo.medium};
    height: auto;
    object-fit: contain;
  }

  &:hover {
    transform: scale(1.02);
  }

  ${media.mobile(`
    img {
      width: ${({ theme }) => theme.sizes.logo.small};
    }
  `)}
`;

/* 
  Nav links:
  - Crisp font size
  - Subtle hover transitions
  - Minimal radius
*/
const StyledNavLink = styled(Nav.Link)`
  color: ${({ theme }) => theme.colors.textPrimary} !important;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  transition: ${({ theme }) => theme.transitions.link};
  padding: 0.75rem 1rem; 
  margin: 0 ${({ theme }) => theme.spacing.xs};
  border-radius: ${({ theme }) => theme.borderRadius.xs};

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.accents[1]} !important;
    background: ${({ theme }) => theme.colors.lightBackground};
    text-decoration: none;
  }

  ${media.tablet(`
    padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.md};
    margin: 0;
  `)}
`;

/* 
  Dropdown logic
*/
const DropdownWrapper = styled.div`
  position: relative;
  cursor: pointer;
`;

const UserIcon = styled.div`
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.grey};
  transition: ${({ theme }) => theme.transitions.link};
  padding: ${({ theme }) => theme.spacing.xs};
  border-radius: ${({ theme }) => theme.borderRadius.md};

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.teal};
    background: ${({ theme }) => theme.colors.lightBackground};
  }

  ${media.tablet(`
    font-size: 1.5rem;
  `)}
`;

/* 
  Dropdown menu:
  - Subtle animation
  - Crisp edges and shadow
  - Responsive "bottom sheet" on mobile
*/
const DropdownMenu = styled.div`
  position: absolute;
  right: 0;
  top: 110%;
  width: 14rem;
  padding: ${({ theme }) => theme.spacing.sm};
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  box-shadow: ${({ theme }) => theme.shadows.small};
  z-index: 9999;
  animation: slideDown 0.2s ease-out forwards;

  @keyframes slideDown {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  ${media.tablet(`
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-height: 80vh;
    overflow-y: auto;
    box-shadow: 0 -2px 10px rgba(0,0,0,0.1);
    border-radius: ${({ theme }) => theme.borderRadius.lg} ${({ theme }) => theme.borderRadius.lg} 0 0;
    padding-bottom: env(safe-area-inset-bottom);
    background-color: ${({ theme }) => theme.colors.secondaryBackground};
    backdrop-filter: blur(8px);
    z-index: 99999;
  `)}
`;

/* 
  Overlay for mobile "bottom sheet" dropdown.
  Darkens background when dropdown is open.
*/
const DropdownOverlay = styled.div`
  display: none;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99998;
  backdrop-filter: blur(4px);

  ${media.tablet(`
    display: block;
  `)}
`;

/* 
  Dropdown header:
  - Shows user info in the dropdown
*/
const DropdownHeader = styled.div`
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.md};
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightBackground};

  ${media.tablet(`
    background-color: ${({ theme }) => theme.colors.primaryBackground};
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  `)}
`;

const SmallText = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${({ theme }) => theme.colors.textSecondary};
  margin: 0;
`;

const SmallBold = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${({ theme }) => theme.colors.title};
  font-weight: 600;
  margin: 0;

  ${media.tablet(`
    color: ${({ theme }) => theme.colors.textPrimary};
  `)}
`;

/* 
  Dropdown item:
  - Crisp transitions 
  - Subtle background highlight on hover
*/
const DropdownItem = styled.button`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs};
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.md};
  font-size: ${({ theme }) => theme.fontSizes.md};
  color: ${({ theme }) => theme.colors.textPrimary};
  background: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  border: none;
  font-family: inherit;

  &:hover {
    background-color: ${({ theme }) => theme.colors.lightBackground};
  }

  &:focus {
    outline: none;
    background-color: ${({ theme }) => theme.colors.lightBackground};
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.primary};
  }

  ${media.tablet(`
    padding: ${({ theme }) => theme.spacing.md};
    border-radius: 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};

    &:hover {
      background-color: ${({ theme }) => theme.colors.lightBackground};
    }
  `)}
`;

const DropdownDivider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.border};
  margin: ${({ theme }) => theme.spacing.xs} 0;

  ${media.tablet(`
    display: none;
  `)}
`;

/* 
  Login button:
  - Crisp shape
  - Subtle hover transform
  - Distinguishable color shift on hover/focus
*/
const LoginButton = styled.span`
  font-family: ${({ theme }) => theme.fonts.primary};
  background-color: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.white};
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.xs};
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.md};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  cursor: pointer;
  transition: ${({ theme }) => theme.transitions.button};

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.orange};
    outline: none;
    transform: translateY(-1px);
  }

  ${media.tablet(`
    padding: ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.sm};
    font-size: ${({ theme }) => theme.fontSizes.xs};
  `)}
`;
