// src/components/CompetitiveAdvantageSection.js

import React from 'react';
import styled from 'styled-components';
import { FaRocket, FaShieldAlt, FaChartBar, FaUsers, FaClock, FaHandshake } from 'react-icons/fa';
import { Section, HorizontalLine, IconWrapper } from './common/Sections';
import { media } from '../utils/media';

const CompetitiveAdvantage = () => {
  return (
    <Section id="competitive-advantage">
      <Container>
        <ContentWrapper>
          <TextContent>
            <TitleWrapper>
              <IconWrapper>
                <FaRocket />
              </IconWrapper>
              <Title>Competitive Advantage</Title>
              <HorizontalLine />
            </TitleWrapper>
            <Description>
              We stand out in the market with our unique combination of features and services
              that deliver exceptional value to our users and partners.
            </Description>
          </TextContent>
          <AdvantageGrid>
            <AdvantageCard>
              <CardIcon>
                <FaShieldAlt />
              </CardIcon>
              <CardTitle>Trusted Platform</CardTitle>
              <CardDescription>
                Built with security and reliability in mind, ensuring safe and seamless transactions.
              </CardDescription>
            </AdvantageCard>
            <AdvantageCard>
              <CardIcon>
                <FaChartBar />
              </CardIcon>
              <CardTitle>Data-Driven Insights</CardTitle>
              <CardDescription>
                Leveraging advanced analytics to provide valuable insights and optimize performance.
              </CardDescription>
            </AdvantageCard>
            <AdvantageCard>
              <CardIcon>
                <FaUsers />
              </CardIcon>
              <CardTitle>Community Focus</CardTitle>
              <CardDescription>
                Building strong relationships and fostering connections within the sports community.
              </CardDescription>
            </AdvantageCard>
            <AdvantageCard>
              <CardIcon>
                <FaClock />
              </CardIcon>
              <CardTitle>24/7 Availability</CardTitle>
              <CardDescription>
                Round-the-clock access to booking and management features for ultimate convenience.
              </CardDescription>
            </AdvantageCard>
            <AdvantageCard>
              <CardIcon>
                <FaHandshake />
              </CardIcon>
              <CardTitle>Partner Integration</CardTitle>
              <CardDescription>
                Seamless integration with sports facilities and venue management systems.
              </CardDescription>
            </AdvantageCard>
          </AdvantageGrid>
        </ContentWrapper>
      </Container>
    </Section>
  );
};

// Styled Components
const Container = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 ${({ theme }) => theme.spacing.lg};
  position: relative;
  z-index: 2;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xl};
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.lg};
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  animation: fadeInUp 1s ease-out;

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(30px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.md};
`;

const Title = styled.h2`
  font-size: clamp(2rem, 4vw, 2.5rem);
  font-weight: 700;
  color: ${({ theme }) => theme.colors.title};
  margin: 0;
  position: relative;
  display: inline-block;

  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 4px;
    background: linear-gradient(
      90deg,
      ${({ theme }) => theme.colors.primary},
      ${({ theme }) => theme.colors.secondary}
    );
    border-radius: 2px;
  }
`;

const Description = styled.p`
  font-size: clamp(1rem, 2vw, 1.1rem);
  color: ${({ theme }) => theme.colors.textSecondary};
  line-height: 1.6;
  margin: 0;
  position: relative;
  padding: 0 ${({ theme }) => theme.spacing.lg};
  
  &::before,
  &::after {
    content: '"';
    position: absolute;
    font-size: 4rem;
    color: ${({ theme }) => theme.colors.primary};
    opacity: 0.2;
  }

  &::before {
    left: 0;
    top: -20px;
  }

  &::after {
    right: 0;
    bottom: -20px;
    transform: rotate(180deg);
  }

  ${media.tablet(`
    padding: 0 ${({ theme }) => theme.spacing.md};
  `)}
`;

const AdvantageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: ${({ theme }) => theme.spacing.lg};
  margin-top: ${({ theme }) => theme.spacing.xl};
`;

const CardIcon = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    135deg,
    ${({ theme }) => theme.colors.primary} 0%,
    ${({ theme }) => theme.colors.secondary} 100%
  );
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.white};
  font-size: 2rem;
  margin-bottom: ${({ theme }) => theme.spacing.lg};
  transition: all 0.3s ease;
  position: relative;
  z-index: 1;

  &::after {
    content: '';
    position: absolute;
    inset: -5px;
    border-radius: 50%;
    background: linear-gradient(
      135deg,
      ${({ theme }) => theme.colors.primary}20,
      ${({ theme }) => theme.colors.secondary}20
    );
    z-index: -1;
  }
`;

const CardTitle = styled.h3`
  font-size: 1.4rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.title};
  margin: 0 0 ${({ theme }) => theme.spacing.sm} 0;
  position: relative;
  z-index: 1;
`;

const CardDescription = styled.p`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.textSecondary};
  line-height: 1.5;
  margin: 0;
  position: relative;
  z-index: 1;
`;

const AdvantageCard = styled.div`
  background: ${({ theme }) => theme.colors.secondaryBackground};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  padding: ${({ theme }) => theme.spacing.xl};
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  animation: fadeInUp 0.5s ease-out;
  animation-fill-mode: both;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      135deg,
      rgba(255, 111, 60, 0.1),
      rgba(255, 111, 60, 0.05)
    );
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1);
    border-color: ${({ theme }) => theme.colors.primary}40;

    &::before {
      opacity: 1;
    }

    ${CardIcon} {
      transform: scale(1.1) rotate(5deg);
      box-shadow: 0 10px 20px rgba(255, 111, 60, 0.2);
    }
  }

  &:nth-child(1) { animation-delay: 0.1s; }
  &:nth-child(2) { animation-delay: 0.2s; }
  &:nth-child(3) { animation-delay: 0.3s; }
  &:nth-child(4) { animation-delay: 0.4s; }
  &:nth-child(5) { animation-delay: 0.5s; }
`;

export default CompetitiveAdvantage;
