// src/components/LoginComponent.js

import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { login } from '../../store/authSlice';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import debounce from 'lodash/debounce';

import RegisterNavbar from './RegisterNavbar';
import {
  Container,
  StyledForm,
  LogoWrapper,
  Logo,
  FormTitle,
  FormSubtitle,
  FormGroup,
  Label,
  Input,
  PasswordWrapper,
  ToggleButton,
  FormError,
  ButtonGroup,
  Button,
  SecondaryButton,
  Error,
  Success,
} from './AuthStyles';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const authState = useSelector((state) => state.auth);
  
  const [form, setForm] = useState({ usernameOrEmail: '', password: '' });
  const [formErrors, setFormErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  // Extract state passed from confirmation page
  const {
    from = '/explore',
    videoIds,
    thumbnails,
    duration,
  } = location.state || {};
  

  useEffect(() => {
    if (authState.user) {
      if (videoIds && thumbnails && duration) {
        navigate('/confirmation', {
          state: {
            videoIds,
            thumbnails,
            duration
          },
        });
      } else {
        // Redirect to the page user was trying to access before login
        navigate(from, { replace: true });
      }
    }
  }, [authState.user, videoIds, thumbnails, duration, from, navigate]);

  // Debounced form validation
  useEffect(() => {
    const debouncedValidate = debounce(() => {
      const errors = {};
      if (!form.usernameOrEmail.trim()) errors.usernameOrEmail = 'Username or Email is required.';
      if (!form.password.trim()) errors.password = 'Password is required.';
      setFormErrors(errors);
      setIsFormValid(Object.keys(errors).length === 0);
    }, 300);

    debouncedValidate();

    return () => {
      debouncedValidate.cancel(); // Clean up debounce
    };
  }, [form]);


  const handleChange = useCallback((e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  }, [form]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isFormValid) return;
    dispatch(login(form));
  };

  return (
    <>
      <RegisterNavbar logoSrc="/1.svg" />
      <Container as="main">
        <StyledForm onSubmit={handleSubmit} noValidate>
          <LogoWrapper>
            <Logo 
              src="/1.svg" 
              alt="Akiture Logo"
              width={120}
              height={40}
              priority
            />
          </LogoWrapper>
          <FormTitle>Welcome Back!</FormTitle>
          <FormSubtitle>Please login to your account.</FormSubtitle>
          <FormGroup>
            <Label htmlFor="username or Email">Username or Email</Label>
            <Input
              type="text"
              id="usernameOrEmail"
              name="usernameOrEmail"
              value={form.usernameOrEmail}
              onChange={handleChange}
              placeholder="Username or Email (e.g., johndoe123)"
              disabled={authState.loading}
              aria-invalid={formErrors.usernameOrEmail ? 'true' : 'false'}
              aria-describedby="username-error"
            />
            {formErrors.usernameOrEmail && <FormError id="username-error">{formErrors.usernameOrEmail}</FormError>}
          </FormGroup>
          <FormGroup>
            <Label htmlFor="password">Password</Label>
            <PasswordWrapper>
              <Input
                type={showPassword ? 'text' : 'password'}
                id="password"
                name="password"
                value={form.password}
                onChange={handleChange}
                placeholder="Password"
                disabled={authState.loading}
                aria-invalid={formErrors.password ? 'true' : 'false'}
                aria-describedby="password-error"
              />
              <ToggleButton
                type="button"
                onClick={() => setShowPassword((prev) => !prev)}
                aria-label={showPassword ? 'Hide password' : 'Show password'}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </ToggleButton>
            </PasswordWrapper>
            {formErrors.password && <FormError id="password-error">{formErrors.password}</FormError>}
          </FormGroup>
          <ButtonGroup>
            <Button type="submit" disabled={!isFormValid || authState.loading}>
              {authState.loading ? 'Logging In...' : 'Login'}
            </Button>
            <SecondaryButton 
              type="button" 
              onClick={() => navigate('/register', { 
                state: { 
                  from: '/confirmation',
                  videoIds,
                  thumbnails,
                  duration
                }
              })} 
              disabled={authState.loading}
            >
              Not a member? Register to access videos
            </SecondaryButton>
            <SecondaryButton type="button" onClick={() => navigate('/forgot-password')} disabled={authState.loading}>
              Forgot password?
            </SecondaryButton>
          </ButtonGroup>
          {authState.error && <Error>{authState.error}</Error>}
          {authState.user && <Success>Login successful!</Success>}
        </StyledForm>
      </Container>
    </>
  );
};

export default Login;
