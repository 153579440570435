import React, { useEffect, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import api from '../utils/api'
import { theme } from '../components/common/theme'

function PaymentSuccess() {
  const [loading, setLoading] = useState(true)
  const [status, setStatus] = useState('Checking payment status...')
  const [error, setError] = useState(null)

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const sessionId = searchParams.get('session_id')
  const processId = searchParams.get('processId')

  useEffect(() => {
    if (sessionId) {
      const response = api
        .get(`/livestreams/confirm-session?session_id=${sessionId}`)
        .then((response) => {
          console.log('Session response:', response.data)
          if (response.data.success) {
            setStatus('Payment complete! Thank you.')
          } else {
            setStatus('Payment not completed or an error occurred.')
          }
        })
        .catch((err) => {
          console.error('Error checking session:', err)
          setError('Error verifying payment. Please try again later.')
        })
        .finally(() => setLoading(false))
    } else {
      setStatus('No session ID found in the URL.')
      setLoading(false)
    }
  }, [sessionId])

  return (
    <div style={styles.container}>
      <h2 style={styles.header}>Payment Success</h2>

      {loading ? (
        <p style={styles.message}>Verifying your payment. Please wait...</p>
      ) : error ? (
        <p style={{ ...styles.message, color: theme.colors.error }}>{error}</p>
      ) : (
        <p style={styles.message}>{status}</p>
      )}

      {processId && (
        <p style={styles.processInfo}>
          <strong>Process ID:</strong> {processId}
        </p>
      )}

      {/* Example “Next Steps” or navigation link */}
      <div style={styles.nextSteps}>
        <Link to="/dashboard" style={styles.linkButton}>
          Go to Dashboard
        </Link>
      </div>
    </div>
  )
}

export default PaymentSuccess

const styles = {
  container: {
    backgroundColor: theme.colors.primaryBackground,
    color: theme.colors.textPrimary,
    padding: theme.spacing.lg,
    borderRadius: theme.borderRadius.md,
    maxWidth: '600px',
    margin: '4rem auto',
    textAlign: 'center',
    boxShadow: theme.shadows.medium,
  },
  header: {
    fontFamily: theme.fonts.primary,
    fontSize: theme.fontSizes.lg,
    marginBottom: theme.spacing.md,
    color: theme.colors.title,
  },
  message: {
    fontFamily: theme.fonts.secondary,
    fontSize: theme.fontSizes.md,
    marginBottom: theme.spacing.md,
  },
  processInfo: {
    fontFamily: theme.fonts.secondary,
    fontSize: theme.fontSizes.sm,
    color: theme.colors.textSecondary,
    marginBottom: theme.spacing.lg,
  },
  nextSteps: {
    marginTop: theme.spacing.lg,
  },
  linkButton: {
    padding: `${theme.spacing.sm} ${theme.spacing.md}`,
    backgroundColor: theme.colors.secondary,
    color: theme.colors.white,
    textDecoration: 'none',
    borderRadius: theme.borderRadius.sm,
    transition: theme.transitions.button,
    fontFamily: theme.fonts.primary,
  },
}
