// src/components/AdminDashboard/sections/DiscountCodesSection.jsx
import React from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  CircularProgress,
  Tooltip
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
import { StyledLinkButton, StyledDeleteButton, ResponsiveButton, NoDataMessage, ErrorText, TableWrapper, StyledTable, StyledTableCell, SectionCard } from '../AdminDashboard.styles';

function DiscountCodesSection({
  discountCodes,
  loadingDiscountCodes,
  deleteError,
  handleDelete
}) {
  return (
    <SectionCard>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Discount Codes</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ResponsiveButton
            component={Link}
            to="/admin/discount-code/create"
            variant="contained"
            color="secondary"
          >
            Create New Discount Code
          </ResponsiveButton>
          <TableWrapper>
            <StyledTable>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Code</StyledTableCell>
                  <StyledTableCell>Description</StyledTableCell>
                  <StyledTableCell>Type</StyledTableCell>
                  <StyledTableCell>Value</StyledTableCell>
                  <StyledTableCell>Expiration Date</StyledTableCell>
                  <StyledTableCell>Usage Limit</StyledTableCell>
                  <StyledTableCell>Times Used</StyledTableCell>
                  <StyledTableCell>Actions</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loadingDiscountCodes ? (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : (
                  discountCodes.map((code) => (
                    <TableRow key={code.id}>
                      <TableCell>{code.code}</TableCell>
                      <TableCell>{code.description || 'N/A'}</TableCell>
                      <TableCell>{code.discountType || 'N/A'}</TableCell>
                      <TableCell>{code.value || 'N/A'}</TableCell>
                      <TableCell>
                        {code.expirationDate
                          ? new Date(code.expirationDate).toLocaleDateString()
                          : 'N/A'}
                      </TableCell>
                      <TableCell>{code.usageLimit || 'N/A'}</TableCell>
                      <TableCell>{code.timesUsed || '0'}</TableCell>
                      <TableCell>
                        <Tooltip title="Edit Discount Code">
                          <StyledLinkButton
                            component={Link}
                            to={`/admin/discount-code/update/${code.id}`}
                            variant="outlined"
                            color="secondary"
                          >
                            Edit
                          </StyledLinkButton>
                        </Tooltip>
                        <Tooltip title="Delete Discount Code">
                          <StyledDeleteButton
                            variant="outlined"
                            color="error"
                            onClick={() => handleDelete(code.id)}
                          >
                            Delete
                          </StyledDeleteButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </StyledTable>
            {!loadingDiscountCodes && discountCodes.length === 0 && !deleteError && (
              <NoDataMessage>No discount codes available.</NoDataMessage>
            )}
            {deleteError && <ErrorText>{deleteError}</ErrorText>}
          </TableWrapper>
        </AccordionDetails>
      </Accordion>
    </SectionCard>
  );
}

DiscountCodesSection.propTypes = {
  discountCodes: PropTypes.array.isRequired,
  loadingDiscountCodes: PropTypes.bool,
  deleteError: PropTypes.string,
  handleDelete: PropTypes.func.isRequired
};

export default DiscountCodesSection;
