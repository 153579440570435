// src/components/AdminDashboard/sections/FeedbackManagementSection.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Tooltip, CircularProgress } from '@mui/material';
import { SectionCard, SectionHeader, TableWrapper, StyledTable, StyledTableCell, StyledDeleteButton, NoDataMessage, ErrorText } from '../AdminDashboard.styles';
import { Table, TableHead, TableRow, TableBody, TableCell } from '@mui/material';

const FeedbackManagementSection = ({ feedbackList, loadingFeedback, feedbackError, handleDeleteFeedback }) => (
  <SectionCard>
    <SectionHeader>
      <Typography variant="h6">Feedback Management</Typography>
    </SectionHeader>
    <TableWrapper>
      <StyledTable>
        <TableHead>
          <TableRow>
            <StyledTableCell>Username</StyledTableCell>
            <StyledTableCell>Email</StyledTableCell>
            <StyledTableCell>Content</StyledTableCell>
            <StyledTableCell>Rating</StyledTableCell>
            <StyledTableCell>Timestamp</StyledTableCell>
            <StyledTableCell>Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loadingFeedback ? (
            <TableRow>
              <TableCell colSpan={6} align="center">
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : (
            feedbackList.map((feedback) => (
              <TableRow key={feedback.id}>
                <TableCell>{feedback.username}</TableCell>
                <TableCell>{feedback.email}</TableCell>
                <TableCell>{feedback.feedbackContent || 'N/A'}</TableCell>
                <TableCell>{feedback.rating || 'N/A'}</TableCell>
                <TableCell>
                  {feedback.timestamp
                    ? new Date(feedback.timestamp).toLocaleString()
                    : 'N/A'}
                </TableCell>
                <TableCell>
                  <Tooltip title="Delete Feedback">
                    <StyledDeleteButton
                      variant="outlined"
                      color="error"
                      onClick={() => handleDeleteFeedback(feedback.id)}
                    >
                      Delete
                    </StyledDeleteButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </StyledTable>
      {!loadingFeedback && feedbackList.length === 0 && !feedbackError && (
        <NoDataMessage>No feedback available.</NoDataMessage>
      )}
      {feedbackError && <ErrorText>{feedbackError}</ErrorText>}
    </TableWrapper>
  </SectionCard>
);

FeedbackManagementSection.propTypes = {
  feedbackList: PropTypes.array.isRequired,
  loadingFeedback: PropTypes.bool.isRequired,
  feedbackError: PropTypes.string,
  handleDeleteFeedback: PropTypes.func.isRequired
};

export default FeedbackManagementSection;
