// src/components/AdminDashboard/sections/UserPurchasesSection.jsx
import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Button, // added Button import
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  SectionCard,
  TableWrapper,
  StyledTable,
  StyledTableCell,
  NoDataMessage
} from '../AdminDashboard.styles';
import { media } from '../../../../utils/media';

const UserPurchasesSection = ({ purchases, loadingPurchases, purchaseError }) => {
  // Use local state to toggle between a limited view (5 rows) and full view.
  const [expanded, setExpanded] = useState(false);
  const visiblePurchases = expanded ? purchases : purchases.slice(0, 5);

  return (
    <SectionCard>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">User Purchases</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableWrapper>
            <StyledTable>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Purchase ID</StyledTableCell>
                  <StyledTableCell>Court Name</StyledTableCell>
                  <StyledTableCell>Username</StyledTableCell>
                  <StyledTableCell>Email</StyledTableCell>
                  <StyledTableCell>Video Title</StyledTableCell>
                  <StyledTableCell>Purchase Date</StyledTableCell>
                  <StyledTableCell>Price</StyledTableCell>
                  <StyledTableCell>Video Link</StyledTableCell>
                  <StyledTableCell>Discount Code</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loadingPurchases ? (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : (
                  visiblePurchases.map((purchase) => (
                    <TableRow key={purchase.id}>
                      <TableCell>{purchase.id}</TableCell>
                      <TableCell>{purchase.video.courtName || 'N/A'}</TableCell>
                      <TableCell>{purchase.user.username}</TableCell>
                      <TableCell>{purchase.user.email}</TableCell>
                      <TableCell>{purchase.video.title}</TableCell>
                      <TableCell>
                        {purchase.purchaseDate
                          ? new Date(purchase.purchaseDate).toLocaleString()
                          : 'N/A'}
                      </TableCell>
                      <TableCell>
                        {purchase.price != null
                          ? `$${purchase.price.toFixed(2)}`
                          : 'N/A'}
                      </TableCell>
                      <TableCell>
                        {purchase.video.preSignedS3Url ? (
                          <a
                            href={purchase.video.preSignedS3Url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View Video
                          </a>
                        ) : (
                          'N/A'
                        )}
                      </TableCell>
                      <TableCell>{purchase.discountCode || 'N/A'}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </StyledTable>
            {!loadingPurchases && purchases.length === 0 && !purchaseError && (
              <NoDataMessage>No purchases available.</NoDataMessage>
            )}
            {purchaseError && <Typography color="error">{purchaseError}</Typography>}
          </TableWrapper>
          {purchases.length > 5 && (
            <ToggleButton onClick={() => setExpanded(!expanded)}>
              {expanded ? 'Collapse Purchases' : 'View All Purchases'}
            </ToggleButton>
          )}
        </AccordionDetails>
      </Accordion>
    </SectionCard>
  );
};

UserPurchasesSection.propTypes = {
  purchases: PropTypes.array.isRequired,
  loadingPurchases: PropTypes.bool.isRequired,
  purchaseError: PropTypes.string
};

export default UserPurchasesSection;

/* ─────────────────────────────────────────────────────────────
   STYLED COMPONENTS
────────────────────────────────────────────────────────────── */
const ToggleButton = styled(Button)`
  margin-top: 16px;
  background-color: ${({ theme }) => theme.colors.accentTeal};
  color: #fff;
  &:hover {
    background-color: ${({ theme }) => theme.colors.accentOrange};
  }
  ${media.mobile`
    width: 100%;
  `}
`;
