// src/components/CompetitiveAdvantageSection.js

import React from 'react';
import styled from 'styled-components';
import { Section, SectionTitle, IconWrapper, HorizontalLine } from './common/Sections';
import { FaTrophy, FaUsers, FaChartLine, FaShieldAlt } from 'react-icons/fa';
import { media } from '../utils/media';

const CompetitiveAdvantageSection = () => {
  const advantages = [
    {
      icon: <FaTrophy />,
      title: 'Proven Success',
      description: 'Trusted by leading entertainment venues worldwide with a track record of delivering exceptional results.'
    },
    {
      icon: <FaUsers />,
      title: 'Customer-Centric',
      description: 'Our solutions are designed with your customers in mind, ensuring an unforgettable experience.'
    },
    {
      icon: <FaChartLine />,
      title: 'Data-Driven',
      description: 'Leverage powerful analytics and insights to make informed decisions and drive growth.'
    },
    {
      icon: <FaShieldAlt />,
      title: 'Secure & Reliable',
      description: 'Enterprise-grade security and reliability to protect your business and customers.'
    }
  ];

  return (
    <Section>
      <ContentBox>
        <Header>
          <IconWrapper>
            <FaTrophy />
          </IconWrapper>
          <SectionTitle>Competitive Advantage</SectionTitle>
          <HorizontalLine />
        </Header>
        <Grid>
          {advantages.map((advantage, index) => (
            <AdvantageCard key={index}>
              <CardIconWrapper>
                {advantage.icon}
              </CardIconWrapper>
              <CardTitle>{advantage.title}</CardTitle>
              <CardDescription>{advantage.description}</CardDescription>
            </AdvantageCard>
          ))}
        </Grid>
      </ContentBox>
    </Section>
  );
};

const ContentBox = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 ${({ theme }) => theme.spacing.md};
  position: relative;
  z-index: 1;
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing.xl};
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${({ theme }) => theme.spacing.lg};
  margin-top: ${({ theme }) => theme.spacing.xl};

  ${media.tablet(`
    grid-template-columns: 1fr;
    gap: ${({ theme }) => theme.spacing.md};
  `)}
`;

const AdvantageCard = styled.div`
  background: ${({ theme }) => theme.colors.secondaryBackground};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  padding: ${({ theme }) => theme.spacing.lg};
  text-align: center;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: linear-gradient(
      90deg,
      ${({ theme }) => theme.colors.primary},
      ${({ theme }) => theme.colors.secondary}
    );
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1);

    &::before {
      transform: scaleX(1);
    }
  }
`;

const CardIconWrapper = styled.div`
  font-size: 2.5rem;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: ${({ theme }) => theme.spacing.md};
  transition: all 0.3s ease;

  ${AdvantageCard}:hover & {
    transform: scale(1.1);
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const CardTitle = styled.h3`
  font-size: ${({ theme }) => theme.fontSizes.lg};
  color: ${({ theme }) => theme.colors.text};
  margin-bottom: ${({ theme }) => theme.spacing.sm};
  font-weight: 600;
`;

const CardDescription = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.md};
  color: ${({ theme }) => theme.colors.textLight};
  line-height: 1.6;
  margin: 0;
`;

export default CompetitiveAdvantageSection;
