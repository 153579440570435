// PaymentCancel.js
import React from 'react';
import { useLocation } from 'react-router-dom';

function PaymentCancel() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sessionId = searchParams.get('session_id');
  const processId = searchParams.get('processId');

  return (
    <div>
      <h2>Payment Cancelled</h2>
      <p>Session ID: {sessionId}</p>
      <p>Process ID: {processId}</p>
      <p>You cancelled or something went wrong with the payment.</p>
    </div>
  );
}

export default PaymentCancel;
