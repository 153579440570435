import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import videoReducer from './videoSlice';
import authReducer from './authSlice';
import auth from '../utils/auth';
import courtReducer from './CourtSlice';
import livestreamReducer from './LivestreamSlice';

// Retrieve initial auth state from utilities
const authenticatedUser = auth.getUser();
const token = auth.getToken();

// Configure the store
export const reduxStore = configureStore({
  reducer: {
    user: userReducer,
    video: videoReducer,
    auth: authReducer,
    court: courtReducer,
    livestream: livestreamReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore actions with these types
        ignoredActions: ['video/download/fulfilled'],
        // Ignore these field paths in actions
        ignoredActionPaths: ['payload'],
        // Ignore these paths in state
        ignoredPaths: ['video.blob'],
      },
    }),
  preloadedState: {
    auth: {
      user: authenticatedUser || null,
      token: token || null,
      loading: false,
      error: null,
    },
  },
});
