// src/components/AdminDashboard/sections/CourtOwnershipSection.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { Typography, MenuItem } from '@mui/material';
import { SectionCard, SectionHeader, Form, FormControlStyled, StyledSelect, StyledSubmitButton } from '../AdminDashboard.styles';

const CourtOwnershipSection = ({
  courts,
  users,
  loadingUsers,
  selectedCourt,
  setSelectedCourt,
  selectedCourtOwner,
  setSelectedCourtOwner,
  handleAssignCourtOwnership,
  loadingAssignCourt
}) => (
  <SectionCard>
    <SectionHeader>
      <Typography variant="h6">Court Ownership</Typography>
    </SectionHeader>
    <Form onSubmit={handleAssignCourtOwnership}>
      <FormControlStyled variant="outlined" required>
        <StyledSelect
          labelId="select-court-label"
          value={selectedCourt}
          label="Select Court"
          onChange={(e) => setSelectedCourt(e.target.value)}
        >
          {courts.length === 0 ? (
            <MenuItem value="">
              <em>No courts found</em>
            </MenuItem>
          ) : (
            courts.map((court) => (
              <MenuItem key={court.courtId} value={court.courtId}>
                {court.courtName} ({court.courtId})
              </MenuItem>
            ))
          )}
        </StyledSelect>
      </FormControlStyled>

      <FormControlStyled variant="outlined" required>
        <StyledSelect
          labelId="select-court-owner-label"
          value={selectedCourtOwner}
          label="Select User"
          onChange={(e) => setSelectedCourtOwner(e.target.value)}
        >
          {loadingUsers ? (
            <MenuItem value="">
              <em>Loading...</em>
            </MenuItem>
          ) : (
            users.map((user) => (
              <MenuItem key={user.id} value={user.username}>
                {user.username} ({user.email})
              </MenuItem>
            ))
          )}
        </StyledSelect>
      </FormControlStyled>

      <StyledSubmitButton type="submit" variant="contained" color="secondary" disabled={loadingAssignCourt}>
        {loadingAssignCourt ? 'Assigning...' : 'Assign Court Ownership'}
      </StyledSubmitButton>
    </Form>
  </SectionCard>
);

CourtOwnershipSection.propTypes = {
  courts: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  loadingUsers: PropTypes.bool.isRequired,
  selectedCourt: PropTypes.string.isRequired,
  setSelectedCourt: PropTypes.func.isRequired,
  selectedCourtOwner: PropTypes.string.isRequired,
  setSelectedCourtOwner: PropTypes.func.isRequired,
  handleAssignCourtOwnership: PropTypes.func.isRequired,
  loadingAssignCourt: PropTypes.bool.isRequired
};

export default CourtOwnershipSection;
