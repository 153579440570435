import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../utils/api";

export const fetchLivestreams = createAsyncThunk(
    "livestreams/fetchLivestreams",
    async (_, { rejectWithValue }) => {
        try {
            const response = await api.get("/livestreams/all");
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

const livestreamSlice = createSlice({
    name: "livestream",
    initialState: {
        livestreams: [],
        loading: false,
        error: null,
    },
    reducers: {
        clearLivestreams: (state) => {
            state.livestreams = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchLivestreams.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchLivestreams.fulfilled, (state, action) => {
                state.loading = false;
                state.livestreams = action.payload;
                state.error = null;
            })
            .addCase(fetchLivestreams.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || "Failed to load livestreams.";
            });
    },
});

export const { clearLivestreams } = livestreamSlice.actions;

export default livestreamSlice.reducer;