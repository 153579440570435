// LivestreamWatchPage.jsx
import React from 'react';
import { useLocation } from 'react-router-dom';
import HlsPlayer from './HlsPlayer'; // your HLS player component
import styled from 'styled-components';

function LivestreamWatchPage() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const watchUrl = queryParams.get('url');

  if (!watchUrl) {
    return (
      <Container>
        <StyledH2>No Watch URL Provided</StyledH2>
        <p>Cannot load the stream without a watchUrl.</p>
      </Container>
    );
  }

  return (
    <Container>
      <StyledH2>Watching Livestream</StyledH2>
      <HlsPlayer hlsUrl={watchUrl} autoPlay={true} />
    </Container>
  );
}

export default LivestreamWatchPage;

const Container = styled.div`
  margin: ${({ theme }) => theme.spacing.xl} auto;
  max-width: 800px;
  background-color: ${({ theme }) => theme.colors.primaryBackground};
  padding: ${({ theme }) => theme.spacing.lg};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  box-shadow: ${({ theme }) => theme.shadows.medium};
  text-align: center;
`;

const StyledH2 = styled.h2`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSizes['2xl']};
  color: ${({ theme }) => theme.colors.title};
  margin-bottom: ${({ theme }) => theme.spacing.md};
  margin-top: 20px;
  text-align: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.fontSizes.xl};
  }
`;
