// src/components/AdminDashboard/sections/AddCourtSection.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { Typography, TextField, Button } from '@mui/material';
import { SectionCard, SectionHeader, Form } from '../AdminDashboard.styles';
import styled from 'styled-components';

const SessionFields = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  align-items: center;
`;

const SessionList = styled.div`
  margin-top: 16px;
`;

const AddCourtSection = ({
  newCourtName,
  setNewCourtName,
  newCourtHlsUrl,
  setNewCourtHlsUrl,
  newCourtCameraName,
  setNewCourtCameraName,
  newCourtCameraUrl,
  setNewCourtCameraUrl,
  sessions,
  tempSessionName,
  setTempSessionName,
  tempDayOfWeek,
  setTempDayOfWeek,
  tempStartTime,
  setTempStartTime,
  tempEndTime,
  setTempEndTime,
  tempActive,
  setTempActive,
  handleAddSession,
  handleRemoveSession,
  handleAddCourt
}) => (
  <SectionCard>
    <SectionHeader>
      <Typography variant="h6">Add Court</Typography>
    </SectionHeader>
    <Form onSubmit={handleAddCourt}>
      <TextField
        label="Court Name"
        value={newCourtName}
        onChange={(e) => setNewCourtName(e.target.value)}
        required
      />
      <TextField
        label="HLS URL"
        value={newCourtHlsUrl}
        onChange={(e) => setNewCourtHlsUrl(e.target.value)}
      />
      <TextField
        label="Camera Name"
        value={newCourtCameraName}
        onChange={(e) => setNewCourtCameraName(e.target.value)}
      />
      <TextField
        label="Camera RTSP URL"
        value={newCourtCameraUrl}
        onChange={(e) => setNewCourtCameraUrl(e.target.value)}
      />
      <Typography variant="subtitle1">Add Court Sessions</Typography>
      <SessionFields>
        <TextField
          label="Session Name"
          value={tempSessionName}
          onChange={(e) => setTempSessionName(e.target.value)}
          placeholder="(Optional) e.g. Monday Morning"
        />
        <TextField
          select
          label="Day of Week"
          value={tempDayOfWeek}
          onChange={(e) => setTempDayOfWeek(e.target.value)}
          style={{ width: '150px' }}
          SelectProps={{ native: false }}
        >
          {['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'].map((day) => (
            <option key={day} value={day}>
              {day}
            </option>
          ))}
        </TextField>
        <TextField
          label="Start Time (HH:mm:ss)"
          value={tempStartTime}
          onChange={(e) => setTempStartTime(e.target.value)}
        />
        <TextField
          label="End Time (HH:mm:ss)"
          value={tempEndTime}
          onChange={(e) => setTempEndTime(e.target.value)}
        />
        <TextField
          select
          label="Active?"
          value={tempActive}
          onChange={(e) => setTempActive(e.target.value === 'true')}
          style={{ width: '100px' }}
          SelectProps={{ native: false }}
        >
          <option value="true">Yes</option>
          <option value="false">No</option>
        </TextField>
        <Button variant="contained" onClick={handleAddSession}>
          Add Session
        </Button>
      </SessionFields>
      {sessions.length > 0 && (
        <SessionList>
          <Typography>Current Sessions:</Typography>
          <ul>
            {sessions.map((s, index) => (
              <li key={index}>
                {s.sessionName} – {s.dayOfWeek}, {s.startTime}-{s.endTime}, Active={String(s.active)}
                <Button color="error" onClick={() => handleRemoveSession(index)}>
                  Remove
                </Button>
              </li>
            ))}
          </ul>
        </SessionList>
      )}
      <Button type="submit" variant="contained" color="secondary">
        Create Court
      </Button>
    </Form>
  </SectionCard>
);

AddCourtSection.propTypes = {
  newCourtName: PropTypes.string.isRequired,
  setNewCourtName: PropTypes.func.isRequired,
  newCourtHlsUrl: PropTypes.string.isRequired,
  setNewCourtHlsUrl: PropTypes.func.isRequired,
  newCourtCameraName: PropTypes.string.isRequired,
  setNewCourtCameraName: PropTypes.func.isRequired,
  newCourtCameraUrl: PropTypes.string.isRequired,
  setNewCourtCameraUrl: PropTypes.func.isRequired,
  sessions: PropTypes.array.isRequired,
  tempSessionName: PropTypes.string.isRequired,
  setTempSessionName: PropTypes.func.isRequired,
  tempDayOfWeek: PropTypes.string.isRequired,
  setTempDayOfWeek: PropTypes.func.isRequired,
  tempStartTime: PropTypes.string.isRequired,
  setTempStartTime: PropTypes.func.isRequired,
  tempEndTime: PropTypes.string.isRequired,
  setTempEndTime: PropTypes.func.isRequired,
  tempActive: PropTypes.bool.isRequired,
  setTempActive: PropTypes.func.isRequired,
  handleAddSession: PropTypes.func.isRequired,
  handleRemoveSession: PropTypes.func.isRequired,
  handleAddCourt: PropTypes.func.isRequired
};

export default AddCourtSection;
