import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import api from '../../utils/api';
import VideoCard from '../Video/VideoCard';
import { media } from '../../utils/media';

const PurchasedVideos = () => {
  const user = useSelector((state) => state.auth.user);
  const [purchasedVideos, setPurchasedVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  
  // Spinner and polling state
  const [isProcessing, setIsProcessing] = useState(false);
  const [processId, setProcessId] = useState(null);
  const [progressPercentage, setProgressPercentage] = useState(0);

  const location = useLocation();

  // On mount, check for a processId from the query string or location.state
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const processIdFromUrl = queryParams.get('processId');
    const processIdFromState = location.state?.processId;
    const processIdToUse = processIdFromUrl || processIdFromState;
    console.log('Found processId:', processIdToUse);  // Debug log

    if (processIdToUse) {
      setProcessId(processIdToUse);
      setIsProcessing(true);
    }
  }, [location]);

  // Fetch purchased videos for the user
  const fetchPurchasedVideos = async () => {
    try {
      const response = await api.get(`/users/${user.id}/purchased-videos`);
      const videos = response.data;
      videos.sort((a, b) => new Date(b.uploadDate) - new Date(a.uploadDate));
      setPurchasedVideos(videos);

      // If there's no active processId, check if any video is marked as IN_PROGRESS
      if (!processId) {
        const anyInProgress = videos.some(video => video.trimStatus === 'IN_PROGRESS');
        setIsProcessing(anyInProgress);
      }
    } catch (err) {
      setError('Error fetching purchased videos. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  // Polling function: check /videos/status/{processId}
  const checkVideoProcessingStatus = async (id) => {
    try {
      const statusResponse = await api.get(`/videos/status/${id}`);
      console.log('Status response:', statusResponse.data);  // Debug log
      
      // Use keys as returned by your backend:
      const { status, progressPercentage: percentage, message: serverMessage } = statusResponse.data;

      if (status === 'PROCESSING') {
        setIsProcessing(true);
        setProgressPercentage(percentage);
      } else if (status === 'PROCESSED') {
        // Once processed, refresh the purchased videos and stop polling
        fetchPurchasedVideos();
        setIsProcessing(false);
        setProcessId(null);
      } else if (status === 'FAILED') {
        setError(serverMessage || 'Video processing failed. Please try again.');
        setIsProcessing(false);
        setProcessId(null);
      }
    } catch (error) {
      console.error('Error checking video processing status:', error);
      setError('Error checking video processing status.');
      setIsProcessing(false);
      setProcessId(null);
    }
  };

  // Fetch purchased videos when user or processId changes
  useEffect(() => {
    if (user?.id) {
      fetchPurchasedVideos();
    }
  }, [user?.id, processId]);

  // Set up polling if processId exists
  useEffect(() => {
    if (processId) {
      const intervalId = setInterval(() => {
        checkVideoProcessingStatus(processId);
      }, 5000);
      return () => clearInterval(intervalId);
    }
  }, [processId]);

  // Example handler to manually start processing (if needed)
  const handleStartProcessing = async () => {
    try {
      const response = await api.post('/videos/process', { /* ...request data... */ });
      const { processId } = response.data;
      setProcessId(processId);
      setIsProcessing(true);
    } catch (error) {
      console.error('Error starting video processing:', error);
      setError('Error initiating video processing.');
    }
  };

  return (
    <PurchasedVideosContainer>
      <Header>
        <Title>My Purchased Videos</Title>
        <RedNote>
          *If you are experiencing download issues, please try desktop or another mobile browser.*
        </RedNote>
        <RedNote>*Leave us feedback below or via the "Leave Feedback" tab.*</RedNote>
      </Header>

      {loading && <LoadingMessage>Loading your purchased videos...</LoadingMessage>}
      {error && <ErrorMessage>{error}</ErrorMessage>}

      {isProcessing && !error && (
        <ProcessingContainer>
          <ProcessingMessage>
            <Spinner /> Gettin' it ready for ya! Progress: {progressPercentage}%
          </ProcessingMessage>
          <ProgressBarContainer>
            <ProgressBarFill style={{ width: `${progressPercentage}%` }} />
          </ProgressBarContainer>
        </ProcessingContainer>
      )}

      {!loading && !error && purchasedVideos.length > 0 ? (
        <VideoListing>
          {purchasedVideos.map((video) => (
            <VideoCard key={video.id} video={video}>
              {video.trimStatus === 'IN_PROGRESS' ? (
                <TrimmingMessage>
                  <Spinner /> Trimming in progress... Your video will be available soon.
                </TrimmingMessage>
              ) : video.trimStatus === 'COMPLETED' ? (
                <DownloadButton href={video.trimmedSignedUrl} download>
                  Download Trimmed Video
                </DownloadButton>
              ) : video.trimStatus === 'FAILED' ? (
                <ErrorMessage>Video trimming failed. Please try again.</ErrorMessage>
              ) : (
                <ButtonContainer>
                  <StartProcessingButton onClick={handleStartProcessing}>
                    Process Video
                  </StartProcessingButton>
                </ButtonContainer>
              )}
            </VideoCard>
          ))}
        </VideoListing>
      ) : (
        !loading && !isProcessing && (
          <NoVideosMessage>No videos purchased yet.</NoVideosMessage>
        )
      )}
    </PurchasedVideosContainer>
  );
};

export default PurchasedVideos;

/* ---------------- Styled Components ---------------- */

const PurchasedVideosContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 30px;
`;

const Title = styled.h2`
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 10px;
  font-family: 'Raleway', sans-serif;
`;

const RedNote = styled.p`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 0.9rem;
  margin-bottom: 4px;
  font-family: 'Raleway', sans-serif;
`;

const LoadingMessage = styled.p`
  font-size: 1.3rem;
  color: ${({ theme }) => theme.colors.secondary};
  text-align: center;
  font-family: 'Raleway', sans-serif;
`;

const ErrorMessage = styled.p`
  font-size: 1.1rem;
  color: ${({ theme }) => theme.colors.error || '#d32f2f'};
  text-align: center;
  margin-top: 10px;
  font-family: 'Raleway', sans-serif;
`;

const ProcessingContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
`;

const ProcessingMessage = styled.p`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.accentBlueGray || '#607d8b'};
  text-align: center;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-family: 'Raleway', sans-serif;
`;

const ProgressBarContainer = styled.div`
  width: 80%;
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 10px;
`;

const ProgressBarFill = styled.div`
  height: 100%;
  background-color: #ff5722;
  transition: width 0.3s ease-in-out;
`;

const Spinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid ${({ theme }) => theme.colors.accentOrange || '#FF5722'};
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const VideoListing = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 25px;
  max-height: 600px;
  overflow-y: auto;
  padding-right: 8px;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.secondary || '#FF5722'};
    border-radius: 4px;
    border: 1px solid #f1f1f1;
  }

  ${media.tablet`
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  `}

  ${media.mobile`
    grid-template-columns: 1fr;
    max-height: none;
    overflow-y: visible;
    padding-right: 0;
  `}
`;

const NoVideosMessage = styled.p`
  font-size: 1.3rem;
  color: #666;
  text-align: center;
  margin-top: 50px;
  font-family: 'Raleway', sans-serif;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: center;
`;

const StartProcessingButton = styled.button`
  padding: 12px 24px;
  background-color: ${({ theme }) => theme.colors.accentOrange || '#FF5722'};
  color: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 20px;
  font-family: 'Raleway', sans-serif;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary || '#FF8A65'};
  }

  ${media.mobile`
    padding: 10px 20px;
    font-size: 0.9rem;
  `}
`;

const DownloadButton = styled.a`
  display: inline-block;
  padding: 12px 24px;
  background-color: ${({ theme }) => theme.colors.primary || '#1976d2'};
  color: #fff;
  text-align: center;
  border-radius: 6px;
  text-decoration: none;
  margin-top: 20px;
  font-family: 'Raleway', sans-serif;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.accentOrange || '#FF5722'};
  }

  ${media.mobile`
    padding: 10px 20px;
    font-size: 0.9rem;
  `}
`;

const TrimmingMessage = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.accentOrange || '#FF5722'};
  font-size: 1.2rem;
  margin-top: 10px;
  font-family: 'Raleway', sans-serif;

  ${media.mobile`
    font-size: 1rem;
  `}
`;
