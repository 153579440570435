// src/pages/Landing.js

import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { theme } from '../components/common/theme';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { media } from '../utils/media';

import MainNavbar from '../components/common/MainNavbar';
import HeroSection from '../components/HeroSection';
import VisionSection from '../components/VisionSection';
import MissionSection from '../components/MissionSection';
import CompetitiveAdvantageSection from '../components/CompetitiveAdvantageSection';
import CompetitiveAdvantage from '../components/CompetitiveAdvantage';
import Footer from '../components/common/Footer';

const Landing = () => {
  const navigate = useNavigate();
  const { user: currentUser } = useSelector((state) => state.auth);

  const handleLogin = () => {
    navigate('/login');
  };

  const logout = () => {
    // Handle logout logic
  };

  return (
    <ThemeProvider theme={theme}>
      <LandingContainer>
        <MainNavbar user={currentUser} />
        <MainContent>
          <HeroSection user={currentUser} handleLogin={handleLogin} />
          <VisionSection />
          <MissionSection />
          <CompetitiveAdvantageSection />
          <CompetitiveAdvantage />
        </MainContent>
        <Footer />
      </LandingContainer>
    </ThemeProvider>
  );
};

export default Landing;

// Styled Components

const LandingContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryBackground};
  overflow-x: hidden;
  scroll-behavior: smooth;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const MainContent = styled.main`
  flex: 1;
  padding-top: 100px; /* Account for fixed navbar */
  width: 100%;
  position: relative;
  z-index: 1;

  ${media.tablet(`
    padding-top: 90px;
  `)}

  ${media.mobile(`
    padding-top: 80px;
  `)}
`;
