import { useState, useMemo, useEffect } from 'react';
import { getCourtName } from '../../utils/helper';

/**
 * Helper that converts e.g. "11:30 PM" -> "23:30"
 */
function parseTimeToLocalTime(timeString) {
  if (!timeString) return null;
  const [time, modifier] = timeString.split(' ');
  let [hours, minutes] = time.split(':').map(Number);

  if (modifier === 'PM') {
    hours = hours === 12 ? 12 : hours + 12;
  } else if (modifier === 'AM') {
    hours = hours === 12 ? 0 : hours;
  }

  if (
    isNaN(hours) || isNaN(minutes) ||
    hours < 0 || hours > 23 ||
    minutes < 0 || minutes > 59
  ) {
    console.warn(`Invalid time format: ${timeString}`);
    return null;
  }
  return `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}`;
}

function getDayRange(selectedDateString) {
  // get “today” in America/Chicago, at 00:00
  const now = new Date(
    new Date().toLocaleString('en-US', { timeZone: 'America/Chicago' })
  );
  now.setHours(0, 0, 0, 0);

  let dayStart = new Date(now); // default = today
  if (selectedDateString === 'yesterday') {
    dayStart.setDate(dayStart.getDate() - 1);
  } else if (selectedDateString === 'last2days') {
    dayStart.setDate(dayStart.getDate() - 2);
  } else if (selectedDateString.match(/^\d{4}-\d{2}-\d{2}$/)) {
    const [year, month, day] = selectedDateString.split('-').map(Number);
    dayStart = new Date(year, month - 1, day);
  }
  // dayEnd => next midnight
  const dayEnd = new Date(dayStart);
  dayEnd.setDate(dayEnd.getDate() + 1);

  return { dayStart, dayEnd };
}

function createTimeWindow(baseDay, startLocalTime, endLocalTime) {
  const startDateTime = new Date(baseDay.getTime());
  const endDateTime   = new Date(baseDay.getTime());

  const [startH, startM] = startLocalTime.split(':').map(Number);
  const [endH, endM]     = endLocalTime.split(':').map(Number);

  startDateTime.setHours(startH, startM, 0, 0);
  endDateTime.setHours(endH, endM, 0, 0);

  // If end is earlier than start, assume user wants to cross midnight
  if (endDateTime < startDateTime) {
    endDateTime.setDate(endDateTime.getDate() + 1);
  }
  return { startDateTime, endDateTime };
}

export default function useFilteredVideos(
  videos,
  selectedDate,  // “today”, “yesterday”, “last2days”, or “2023-09-01”
  startTime,     // e.g. “11:00 PM”
  endTime,       // e.g. “12:00 AM”
  selectedCourt
) {
  const [filteredVideos, setFilteredVideos] = useState([]);

  const filteredMemo = useMemo(() => {
    if (!videos?.length || !selectedDate || !startTime || !endTime) {
      return [];
    }

    // 1) Build the day range (dayStart, dayEnd) for the user’s chosen date
    const { dayStart, dayEnd } = getDayRange(selectedDate);

    // 2) Convert startTime / endTime => “HH:mm”
    const startLocalTime = parseTimeToLocalTime(startTime);
    const endLocalTime   = parseTimeToLocalTime(endTime);
    if (!startLocalTime || !endLocalTime) {
      return [];
    }

    // 3) Create “startDateTime” and “endDateTime” for the selected day
    const { startDateTime, endDateTime } = createTimeWindow(
      dayStart,
      startLocalTime,
      endLocalTime
    );

    // Now we have two “windows” that must overlap:
    // (A) The day window:   [dayStart, dayEnd]
    // (B) The user’s time window: [startDateTime, endDateTime]
    // The final range is effectively the intersection.

    // Filter
    return videos.filter((video) => {
      const videoStart = new Date(video.startTime);
      const videoEnd   = new Date(video.endTime);

      // *** 1) Must at least partially overlap the user’s day (e.g. “today”) ***
      const intersectsDay =
        videoEnd > dayStart && videoStart < dayEnd;
      if (!intersectsDay) return false;

      // *** 2) Must overlap the user’s time range ***
      const intersectsTime =
        videoEnd > startDateTime && videoStart < endDateTime;
      if (!intersectsTime) return false;

      // *** 3) Court filter ***
      if (selectedCourt) {
        const videoCourt = getCourtName(video.title);
        if (videoCourt !== selectedCourt) return false;
      }

      return true;
    });
  }, [videos, selectedDate, startTime, endTime, selectedCourt]);

  useEffect(() => {
    setFilteredVideos(filteredMemo);
  }, [filteredMemo]);

  return filteredVideos;
}
