// src/components/MissionSection.js

import React from 'react';
import styled from 'styled-components';
import { FaLightbulb, FaHandshake, FaChartLine, FaUsers } from 'react-icons/fa';
import { Section, HorizontalLine, IconWrapper } from './common/Sections';
import { media } from '../utils/media';
import MissionImage from '../assets/images/mission.jpg';

const MissionSection = () => {
  return (
    <Section id="mission">
      <Container>
        <ContentWrapper>
          <TextContent>
            <TitleWrapper>
              <IconWrapper>
                <FaLightbulb />
              </IconWrapper>
              <Title>Our Mission</Title>
              <HorizontalLine />
            </TitleWrapper>
            <Description>
            We aim to empower every athlete by making high-quality sports footage accessible to all,
              while fostering a strong, connected local sports community. Your moments deserve to be
              celebrated, studied, and shared without barriers.
            </Description>
            <MissionGrid>
              <MissionCard>
                <CardIcon>
                  <FaHandshake />
                </CardIcon>
                <CardTitle>Community First</CardTitle>
                <CardDescription>
                  Building strong relationships and fostering connections within the sports community.
                </CardDescription>
              </MissionCard>
              <MissionCard>
                <CardIcon>
                  <FaChartLine />
                </CardIcon>
                <CardTitle>Innovation</CardTitle>
                <CardDescription>
                  Continuously improving and innovating to provide the best user experience.
                </CardDescription>
              </MissionCard>
              <MissionCard>
                <CardIcon>
                  <FaUsers />
                </CardIcon>
                <CardTitle>Accessibility</CardTitle>
                <CardDescription>
                  Making sports facilities accessible to everyone.
                </CardDescription>
              </MissionCard>
            </MissionGrid>
          </TextContent>
          <ImageContent>
            <ImageWrapper>
              <ImageOverlay />
              <Image src={MissionImage} alt="Our Mission" />
            </ImageWrapper>
          </ImageContent>
        </ContentWrapper>
      </Container>
    </Section>
  );
};

// Styled Components
const Container = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 ${({ theme }) => theme.spacing.lg};
  position: relative;
  z-index: 2;
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  gap: ${({ theme }) => theme.spacing.xl};
  align-items: center;

  ${media.tablet(`
    grid-template-columns: 1fr;
    gap: ${({ theme }) => theme.spacing.lg};
  `)}
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.lg};
  animation: fadeInLeft 1s ease-out;

  @keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translateX(-30px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  ${media.tablet(`
    text-align: center;
  `)}
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing.md};

  ${media.tablet(`
    align-items: center;
  `)}
`;

const Title = styled.h2`
  font-size: clamp(2rem, 4vw, 2.5rem);
  font-weight: 700;
  color: ${({ theme }) => theme.colors.title};
  margin: 0;
  position: relative;
  display: inline-block;

  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 60px;
    height: 4px;
    background: linear-gradient(
      90deg,
      ${({ theme }) => theme.colors.primary},
      ${({ theme }) => theme.colors.secondary}
    );
    border-radius: 2px;
  }

  ${media.tablet(`
    &::after {
      left: 50%;
      transform: translateX(-50%);
    }
  `)}
`;

const Description = styled.p`
  font-size: clamp(1rem, 2vw, 1.1rem);
  color: ${({ theme }) => theme.colors.textSecondary};
  line-height: 1.6;
  margin: 0;
  position: relative;
  padding-left: 20px;
  
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 4px;
    height: 80%;
    background: linear-gradient(
      to bottom,
      ${({ theme }) => theme.colors.primary},
      ${({ theme }) => theme.colors.secondary}
    );
    border-radius: 2px;
  }

  ${media.tablet(`
    padding-left: 0;
    
    &::before {
      display: none;
    }
  `)}
`;

const MissionGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: ${({ theme }) => theme.spacing.md};
  margin-top: ${({ theme }) => theme.spacing.lg};
`;

const MissionCard = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  padding: ${({ theme }) => theme.spacing.lg};
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      135deg,
      rgba(255, 111, 60, 0.1),
      rgba(255, 111, 60, 0.05)
    );
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);

    &::before {
      opacity: 1;
    }
  }
`;

const CardIcon = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    135deg,
    ${({ theme }) => theme.colors.primary} 0%,
    ${({ theme }) => theme.colors.secondary} 100%
  );
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.8rem;
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

const CardTitle = styled.h3`
  font-size: 1.3rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.title};
  margin: 0 0 ${({ theme }) => theme.spacing.sm} 0;
`;

const CardDescription = styled.p`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.textSecondary};
  line-height: 1.5;
  margin: 0;
`;

const ImageContent = styled.div`
  position: relative;
  animation: fadeInRight 1s ease-out forwards;
  width: 100%;

  @keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translateX(30px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  ${media.tablet(`
    order: -1;
  `)}

  ${media.mobile(`
    margin: ${({ theme }) => theme.spacing.md} 0;
  `)}
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 75%; /* 4:3 Aspect Ratio */
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transform: perspective(1000px) rotateY(-5deg);
  transition: transform 0.3s ease;

  &:hover {
    transform: perspective(1000px) rotateY(0deg);
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;
  }

  &:hover img {
    transform: scale(1.05);
  }

  ${media.tablet(`
    padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
    transform: none;
    margin: ${({ theme }) => theme.spacing.md} 0;

    &:hover {
      transform: none;
    }
  `)}

  ${media.mobile(`
    padding-bottom: 75%; /* 4:3 Aspect Ratio for larger mobile view */
    border-radius: ${({ theme }) => theme.borderRadius.md};
    margin: ${({ theme }) => theme.spacing.md} 0;
    min-height: 300px;
  `)}
`;

const ImageOverlay = styled.div`
  position: absolute;
  inset: 0;
  background: linear-gradient(
    135deg,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0.1) 100%
  );
  z-index: 1;

  ${media.mobile(`
    background: linear-gradient(
      135deg,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0.1) 100%
    );
  `)}
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;

  ${ImageWrapper}:hover & {
    transform: scale(1.05);
  }
`;

export default MissionSection;
