/**
 * ScheduleLivestream.jsx
 */
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchCourts } from '../../store/CourtSlice'
import styled from 'styled-components'
import api from '../../utils/api'
import { Modal } from 'react-bootstrap'
import { theme } from '../common/theme'

// -------------------- STYLED COMPONENTS -------------------- //

const Container = styled.div`
  max-width: 600px;
  margin: 2rem auto;
  padding: ${(props) => props.theme.spacing.xl};
  font-family: ${(props) => props.theme.fonts.primary};
  background-color: ${(props) => props.theme.colors.primaryBackground};
  border-radius: ${(props) => props.theme.borderRadius.lg};
  box-shadow: ${(props) => props.theme.shadows.medium};
`

const Header = styled.h2`
  margin-bottom: ${(props) => props.theme.spacing.md};
  font-size: ${(props) => props.theme.fontSizes['2xl']};
  color: ${(props) => props.theme.colors.title};
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
`

const Label = styled.label`
  margin-bottom: ${(props) => props.theme.spacing.md};
  display: flex;
  flex-direction: column;
  font-weight: bold;
  color: ${(props) => props.theme.colors.textPrimary};
`

const Input = styled.input`
  margin-top: ${(props) => props.theme.spacing.sm};
  padding: ${(props) => props.theme.spacing.sm};
  font-size: ${(props) => props.theme.fontSizes.md};
  border: 1px solid ${(props) => props.theme.colors.border};
  border-radius: ${(props) => props.theme.borderRadius.sm};
  background-color: ${(props) => props.theme.colors.inputBackground};

  &:focus {
    outline: none;
    border-color: ${(props) => props.theme.colors.teal};
    box-shadow: 0 0 0 2px ${(props) => props.theme.colors.inputFocusBackground};
  }
`

const Select = styled.select`
  margin-top: ${(props) => props.theme.spacing.sm};
  padding: ${(props) => props.theme.spacing.sm};
  font-size: ${(props) => props.theme.fontSizes.md};
  border: 1px solid ${(props) => props.theme.colors.border};
  border-radius: ${(props) => props.theme.borderRadius.sm};
  background-color: ${(props) => props.theme.colors.inputBackground};

  &:focus {
    outline: none;
    border-color: ${(props) => props.theme.colors.teal};
    box-shadow: 0 0 0 2px ${(props) => props.theme.colors.inputFocusBackground};
  }
`

const TimeRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${(props) => props.theme.spacing.md};
`

const RadioGroup = styled.div`
  margin-bottom: ${(props) => props.theme.spacing.md};
`

const RadioLabel = styled.span`
  display: block;
  margin-bottom: ${(props) => props.theme.spacing.sm};
  color: ${(props) => props.theme.colors.textPrimary};
`

const RadioOption = styled.label`
  margin-right: ${(props) => props.theme.spacing.md};
  font-weight: normal;
  color: ${(props) => props.theme.colors.textSecondary};
`

const SponsorContainer = styled.div`
  margin-bottom: ${(props) => props.theme.spacing.lg};
`

const SponsorInputRow = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing.sm};
`

const SponsorButton = styled.button`
  padding: ${(props) => props.theme.spacing.sm} ${(props) => props.theme.spacing.md};
  font-size: ${(props) => props.theme.fontSizes.md};
  background-color: ${(props) => props.theme.colors.success};
  color: ${(props) => props.theme.colors.white};
  border: none;
  border-radius: ${(props) => props.theme.borderRadius.sm};
  cursor: pointer;
  transition: ${(props) => props.theme.transitions.button};

  &:hover {
    background-color: ${(props) => props.theme.colors.teal};
  }
`

const SponsorList = styled.ul`
  margin-top: ${(props) => props.theme.spacing.sm};
  list-style-type: disc;
  padding-left: ${(props) => props.theme.spacing.md};
  color: ${(props) => props.theme.colors.textSecondary};
`

const CheckoutButton = styled.button`
  padding: ${(props) => props.theme.spacing.md};
  font-size: ${(props) => props.theme.fontSizes.md};
  background-color: ${(props) => props.theme.colors.accents[0]};
  color: ${(props) => props.theme.colors.white};
  border: none;
  border-radius: ${(props) => props.theme.borderRadius.md};
  cursor: pointer;
  transition: ${(props) => props.theme.transitions.button};

  &:hover {
    background-color: ${(props) => props.theme.colors.accents[1]};
  }
`

const SuccessMessage = styled.p`
  color: ${(props) => props.theme.colors.success};
  margin-bottom: ${(props) => props.theme.spacing.md};
`

const ErrorMessage = styled.p`
  color: ${(props) => props.theme.colors.error};
  margin-bottom: ${(props) => props.theme.spacing.md};
`

// ---------------------- COMPONENT ---------------------- //

function ScheduleLivestream() {
  const dispatch = useDispatch()
  const courts = useSelector((state) => state.court.courts)
  const user = useSelector((state) => state.auth.user)

  // Livestream fields
  const [livestreamName, setLivestreamName] = useState('')
  const [date, setDate] = useState('')
  const [startTime, setStartTime] = useState('')
  const [endTime, setEndTime] = useState('')
  // isPublic indicates that the event is shareable (payment required)
  const [isPublic, setIsPublic] = useState(true)
  const [selectedCourtIds, setSelectedCourtIds] = useState([])

  // Additional for purchase
  const [discountCode, setDiscountCode] = useState('')

  // Hard-coded success and cancel URLs (replace with your real URLs)
  const successUrl = 'https://akture.video/payment-success'
  const cancelUrl = 'https://akture.video/payment-cancel'

  // Sponsors state (adapted for SponsorsDTO)
  const [sponsorName, setSponsorName] = useState('')
  const [sponsorLogo, setSponsorLogo] = useState('')
  const [sponsors, setSponsors] = useState([])

  // UI state
  const [scheduleError, setScheduleError] = useState(null)
  const [scheduleSuccess, setScheduleSuccess] = useState(null)
  const [paymentUrl, setPaymentUrl] = useState(null)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    dispatch(fetchCourts()) // Load court data on mount
  }, [dispatch])

  const handleCourtChange = (e) => {
    const selectedValues = Array.from(e.target.selectedOptions, (option) => option.value)
    setSelectedCourtIds(selectedValues)
  }

  const addSponsor = () => {
    if (sponsorName.trim() !== '') {
      // Create an object matching SponsorsDTO (id and livestream will be set on backend)
      const newSponsor = {
        sponsorName: sponsorName.trim(),
        logoUrl: sponsorLogo.trim() || null,
      }
      setSponsors([...sponsors, newSponsor])
      setSponsorName('')
      setSponsorLogo('')
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setScheduleError(null)
    setScheduleSuccess(null)
    setPaymentUrl(null)

    const isoStart = `${date}T${startTime}:00`
    const isoEnd = `${date}T${endTime}:00`
    const status = 'SCHEDULED' // Hard-coded status for new events

    // For demonstration, pick the first selected court
    const selectedCourt = selectedCourtIds[0] || null

    // Build the livestream portion.
    // Note: requiresPayment is true when public (payment required), false when private.
    const livestreamDto = {
      description: livestreamName,
      startTime: isoStart,
      endTime: isoEnd,
      requiresPayment: !isPublic,
      court: { id: selectedCourt },
      price: 10, // example price
      status,
      sponsorsList: sponsors, // send sponsors as an array of sponsor objects
      logoType: 'AKTURE', // example logo type
    }

    console.log('Livestream DTO:', livestreamDto)

    // Build the purchase portion
    const purchase = {
      discountCode,
      processId: Date.now(),
      successUrl, // Include success URL
      cancelUrl, // Include cancel URL
    }

    // Combine into ScheduleLivestreamRequest
    const scheduleRequest = {
      livestream: livestreamDto,
      purchase,
    }

    try {
      // POST to /api/v1/livestreams/schedule
      const res = await api.post('/livestreams/schedule', scheduleRequest)
      console.log('Created Livestream (with Payment):', res.data)
      setScheduleSuccess('Livestream scheduled successfully!')
      if (res.data.paymentUrl) {
        setPaymentUrl(res.data.paymentUrl)
      }
      // Show the success popup modal
      setShowModal(true)
    } catch (error) {
        setScheduleError(error.response?.data.message 
          || 'You do not have permission to schedule a livestream.')
          console.error('Error scheduling livestream:', error)
   
  }
}

  return (
    <Container>
      <Header>Schedule a Livestream</Header>
      {scheduleError && <ErrorMessage>{scheduleError}</ErrorMessage>}
      <Form onSubmit={handleSubmit}>
        <Label>
          Livestream name
          <Input
            type="text"
            value={livestreamName}
            onChange={(e) => setLivestreamName(e.target.value)}
            placeholder="Enter livestream name"
            required
          />
        </Label>

        <Label>
          Choose court(s)
          <Select multiple value={selectedCourtIds} onChange={handleCourtChange}>
            {courts.map((court) => (
              <option key={court.id} value={court.id}>
                {court.courtName}
              </option>
            ))}
          </Select>
        </Label>

        <Label>
          Date
          <Input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            required
          />
        </Label>

        <TimeRow>
          <Label>
            Start time
            <Input
              type="time"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
              required
            />
          </Label>
          <Label>
            End time
            <Input
              type="time"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
              required
            />
          </Label>
        </TimeRow>

        <RadioGroup>
          <RadioLabel>Is this a private or public livestream?</RadioLabel>
          <RadioOption>
            <Input
              type="radio"
              name="livestreamType"
              value="public"
              checked={isPublic}
              onChange={() => setIsPublic(true)}
            />
            Public (Free for users)
          </RadioOption>
          <RadioOption>
            <Input
              type="radio"
              name="livestreamType"
              value="private"
              checked={!isPublic}
              onChange={() => setIsPublic(false)}
            />
            Private (Users pay to watch)
          </RadioOption>
        </RadioGroup>

        <Label>
          Discount Code (optional)
          <Input
            type="text"
            value={discountCode}
            onChange={(e) => setDiscountCode(e.target.value)}
          />
        </Label>

        <SponsorContainer>
          <Label>Sponsors (optional)</Label>
          <SponsorInputRow>
            <Input
              type="text"
              value={sponsorName}
              onChange={(e) => setSponsorName(e.target.value)}
              placeholder="Sponsor Name"
            />
            <Input
              type="text"
              value={sponsorLogo}
              onChange={(e) => setSponsorLogo(e.target.value)}
              placeholder="Logo URL (optional)"
            />
            <SponsorButton type="button" onClick={addSponsor}>
              Add Sponsor
            </SponsorButton>
          </SponsorInputRow>
          {sponsors.length > 0 && (
            <SponsorList>
              {sponsors.map((sponsor, index) => (
                <li key={index}>
                  {sponsor.sponsorName}
                  {sponsor.logoUrl && ` - ${sponsor.logoUrl}`}
                </li>
              ))}
            </SponsorList>
          )}
        </SponsorContainer>

        <CheckoutButton type="submit">Schedule Event</CheckoutButton>
      </Form>

      {/* Modal Popup for Success & Payment */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton style={{ backgroundColor: theme.colors.secondaryBackground }}>
          <Modal.Title style={{ color: theme.colors.title }}>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: theme.colors.primaryBackground }}>
          <p>{scheduleSuccess}</p>
          {paymentUrl && (
            <CheckoutButton onClick={() => (window.location.href = paymentUrl)}>
              Pay Now
            </CheckoutButton>
          )}
        </Modal.Body>
      </Modal>
    </Container>
  )
}

export default ScheduleLivestream
