// src/store/courtSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../utils/api';

// Thunk to fetch admin courts
export const fetchCourts = createAsyncThunk(
  'court/fetchCourtsAdmin',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/users/admin/courts');
      console.log("Response data", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Thunk to fetch site owner courts
export const fetchSiteOwnerCourts = createAsyncThunk(
  'court/fetchSiteOwnerCourts',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/users/courts');
      console.log("Response data", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const courtSlice = createSlice({
  name: 'court',
  initialState: {
    courts: [],
    loading: false,
    error: null,
  },
  reducers: {
    clearCourts: (state) => {
      state.courts = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCourts.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchCourts.fulfilled, (state, action) => {
      state.loading = false;
      state.courts = action.payload;
      state.error = null;
    });
    builder.addCase(fetchCourts.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || 'Failed to load courts.';
    });

    builder.addCase(fetchSiteOwnerCourts.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchSiteOwnerCourts.fulfilled, (state, action) => {
      state.loading = false;
      state.courts = action.payload;
      state.error = null;
    });
    builder.addCase(fetchSiteOwnerCourts.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || 'Failed to load courts.';
    });
  },
});

export const { clearCourts } = courtSlice.actions;
export default courtSlice.reducer;
