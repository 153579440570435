// src/components/AdminDashboard/sections/UserRolesSection.jsx
import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  MenuItem,
  CircularProgress,
  Alert
} from '@mui/material';
import { 
  SectionCard, 
  SectionHeader, 
  Form, 
  FormControlStyled, 
  StyledSelect, 
  StyledSubmitButton, 
  ErrorText 
} from '../AdminDashboard.styles';

function UserRolesSection({
  users,
  loadingUsers,
  selectedUser,
  selectedRole,
  setSelectedUser,
  setSelectedRole,
  handleAssignRole,
  loadingAssign,
  assignError,
  assignSuccess
}) {
  return (
    <SectionCard>
      <SectionHeader>
        <Typography variant="h6">User Roles</Typography>
      </SectionHeader>
      <Form onSubmit={handleAssignRole}>
        <FormControlStyled variant="outlined" required>
          <StyledSelect
            labelId="select-user-label"
            value={selectedUser}
            label="Select User"
            onChange={(e) => setSelectedUser(e.target.value)}
          >
            {loadingUsers ? (
              <MenuItem value="">
                <em>Loading...</em>
              </MenuItem>
            ) : (
              users.map((user) => (
                <MenuItem key={user.id} value={user.username}>
                  {user.username} ({user.email}) - Current Role: {user.roles.join(', ')}
                </MenuItem>
              ))
            )}
          </StyledSelect>
        </FormControlStyled>

        <FormControlStyled variant="outlined" required>
          <StyledSelect
            labelId="select-role-label"
            value={selectedRole}
            label="Select Role"
            onChange={(e) => setSelectedRole(e.target.value)}
          >
            <MenuItem value="ADMIN">Admin</MenuItem>
            <MenuItem value="USER">User</MenuItem>
            {/* Additional roles if needed */}
          </StyledSelect>
        </FormControlStyled>

        <StyledSubmitButton
          type="submit"
          variant="contained"
          color="secondary"
          disabled={loadingAssign}
        >
          {loadingAssign ? <CircularProgress size={24} color="inherit" /> : 'Assign Role'}
        </StyledSubmitButton>
        {assignError && <ErrorText>{assignError}</ErrorText>}
        {assignSuccess && <Alert severity="success">{assignSuccess}</Alert>}
      </Form>
    </SectionCard>
  );
}

UserRolesSection.propTypes = {
  // Define prop types...
};

export default UserRolesSection;
