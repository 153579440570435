export const getCourtName = (title) => {
    if (!title) return 'Unknown Court';
    const match = title.match(/^.*?_(.*?)_video_/);
    return match && match[1] ? match[1] : 'Unknown Court';
  };

  export const formatTime = (timeInSeconds) => {
    const totalSeconds = Math.floor(timeInSeconds);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
  
    if (hours > 0) {
      return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds
        .toString()
        .padStart(2, '0')}`;
    }
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

function getDayRange(selectedDateString) {
  // “today”, “yesterday”, “last2days”, or actual “YYYY-MM-DD”
  const now = new Date(
    new Date().toLocaleString('en-US', { timeZone: 'America/Chicago' })
  );
  now.setHours(0, 0, 0, 0);

  let dayStart = new Date(now); // default = “today”
  if (selectedDateString === 'yesterday') {
    dayStart.setDate(dayStart.getDate() - 1);
  } else if (selectedDateString === 'last2days') {
    dayStart.setDate(dayStart.getDate() - 2);
  } else if (selectedDateString.match(/^\d{4}-\d{2}-\d{2}$/)) {
    // parse actual date e.g. “2023-09-01”
    const [year, month, day] = selectedDateString.split('-').map(Number);
    dayStart = new Date(year, month - 1, day); 
  }

  const dayEnd = new Date(dayStart);
  dayEnd.setDate(dayEnd.getDate() + 1); // next midnight
  
  return { dayStart, dayEnd };
}


  export function hasValidFilters(selectedDate, startTime, endTime, selectedCourt) {
    return (
      selectedDate &&
      startTime &&
      endTime &&
      selectedCourt
    );
  }
  
  