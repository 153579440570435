// src/components/AdminDashboard/sections/RevenueRecordsExportSection.jsx
import React,{ useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, FormControl, MenuItem } from '@mui/material';
import { SectionCard, SectionHeader, FormControlStyled, StyledSelect, ExportButton, ErrorText } from '../AdminDashboard.styles';
import CloudDownloadOutlined from '@mui/icons-material/CloudDownloadOutlined';


const listYears = () => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = 0; i < 5; i++) {
    years.push(currentYear - i);
  }
  return years;
};

export const YEAR_OPTIONS = listYears();

const MONTH_OPTIONS = [
  { value: 1, label: 'January' },
  { value: 2, label: 'February' },
  { value: 3, label: 'March' },
  { value: 4, label: 'April' },
  { value: 5, label: 'May' },
  { value: 6, label: 'June' },
  { value: 7, label: 'July' },
  { value: 8, label: 'August' },
  { value: 9, label: 'September' },
  { value: 10, label: 'October' },
  { value: 11, label: 'November' },
  { value: 12, label: 'December' }
];

const RevenueRecordsExportSection = ({ selectedMonths, selectedYear, handleYearChange, handleMonthChange, handleExportRevenue, exportError }) => {

  return (
    <SectionCard>
      <SectionHeader>
        <Typography variant="h6">Revenue Records</Typography>
      </SectionHeader>
      {exportError && <ErrorText>{exportError}</ErrorText>}
      <FormControlStyled variant="outlined" style={{ minWidth: 120, marginRight: '16px' }}>
        <StyledSelect
          labelId="year-select-label"
          label="Select Year"
          value={selectedYear}
          onChange={handleYearChange}
        >
          {YEAR_OPTIONS.map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControlStyled>
      <FormControlStyled variant="outlined" style={{ minWidth: 200 }}>
        <StyledSelect
          labelId="month-select-label"
          label="Select Months"
          multiple
          value={selectedMonths}
          onChange={handleMonthChange}
          renderValue={(selected) => {
            const selectedLabels = MONTH_OPTIONS
              .filter((opt) => selected.includes(opt.value))
              .map((opt) => opt.label);
            return selectedLabels.join(', ');
          }}
        >
          {MONTH_OPTIONS.map((month) => (
            <MenuItem key={month.value} value={month.value}>
              {month.label}
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControlStyled>
      <ExportButton onClick={handleExportRevenue}>
        <CloudDownloadOutlined style={{ marginRight: '8px' }} />
        Export Revenue to Excel
      </ExportButton>
    </SectionCard>
  );
};

RevenueRecordsExportSection.propTypes = {
  selectedMonths: PropTypes.array.isRequired,
  selectedYear: PropTypes.number.isRequired,
  handleYearChange: PropTypes.func.isRequired,
  handleMonthChange: PropTypes.func.isRequired,
  handleExportRevenue: PropTypes.func.isRequired,
  exportError: PropTypes.string,
};


export default RevenueRecordsExportSection;
