import React, { useState } from 'react';
import api from '../utils/api';
import styled from 'styled-components';
import { COLORS } from '../BrandColors';
import { CircularProgress } from '@mui/material'; // Add MUI loader

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await api.post('/auth/forgot', { email });
      setMessage(response.data);
      setError('');
    } catch (err) {
      setError(err.response?.data || 'An error occurred');
      setMessage('');
    } finally {
      setLoading(false);
    }
  };

  return (
    <ForgotPasswordContainer>
      <Heading>Forgot Password</Heading>
      <Form onSubmit={handleSubmit}>
        <Label htmlFor="email">Email:</Label>
        <Input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          disabled={loading}
        />

        {/* Show spinner if loading; otherwise show button */}
        {loading ? (
          <LoaderContainer>
            <CircularProgress size={24} />
          </LoaderContainer>
        ) : (
          <Button type="submit" disabled={loading}>
            Reset Password
          </Button>
        )}
      </Form>

      {message && <SuccessMessage>{message}</SuccessMessage>}
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </ForgotPasswordContainer>
  );
};

export default ForgotPassword;

const ForgotPasswordContainer = styled.div`
  background-color: ${COLORS.primaryBackground};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
`;

const Heading = styled.h2`
  color: ${COLORS.accentBlueGray};
  margin-bottom: 1.5rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
`;

const Label = styled.label`
  color: ${COLORS.accentBlueGray};
  margin-bottom: 0.5rem;
`;

const Input = styled.input`
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid ${COLORS.accentBlueGray};
  border-radius: 4px;
  font-size: 1rem;

  &:focus {
    outline: none;
    border-color: ${COLORS.accentTeal};
    box-shadow: 0 0 0 2px rgba(0, 166, 147, 0.2);
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`;

const Button = styled.button`
  background-color: ${COLORS.secondaryButton};
  color: ${COLORS.primaryBackground};
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  margin-bottom: 1rem;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${COLORS.accentOrange};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(255, 111, 60, 0.2);
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const SuccessMessage = styled.p`
  color: ${COLORS.accentTeal};
  font-size: 1rem;
`;

const ErrorMessage = styled.p`
  color: ${COLORS.secondaryButton};
  font-size: 1rem;
`;