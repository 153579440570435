import React, { useEffect, useRef } from 'react';
import Hls from 'hls.js';

// Reusable manifest polling function (you could also import this from a shared utility)
const fetchWithRetry = async (url, retries = 10, delay = 1000) => {
  for (let i = 0; i < retries; i++) {
    try {
      const res = await fetch(url, { method: 'HEAD' });
      if (res.ok) return true;
    } catch (error) {
      console.error('Manifest fetch error:', error);
    }
    await new Promise((resolve) => setTimeout(resolve, delay));
  }
  return false;
};

/**
 * HlsPlayer component
 *
 * @param {string} hlsUrl - The HLS .m3u8 URL
 */
function HlsPlayer({ hlsUrl, autoPlay = false }) {
  const videoRef = useRef(null);
  const hlsInstanceRef = useRef(null);

  useEffect(() => {
    let isMounted = true;

    const initializeHls = async () => {
      // Wait until the manifest is available
      const isReady = await fetchWithRetry(hlsUrl);
      if (!isReady) {
        console.error('HLS manifest not available:', hlsUrl);
        return;
      }

      // Only initialize if the component is still mounted
      if (!isMounted) return;

      if (Hls.isSupported()) {
        // Clean up any existing instance
        if (hlsInstanceRef.current) {
          hlsInstanceRef.current.destroy();
        }
        const hls = new Hls();
        hlsInstanceRef.current = hls;
        hls.loadSource(hlsUrl);
        hls.attachMedia(videoRef.current);
      } else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
        videoRef.current.src = hlsUrl;
      } else {
        console.error('This browser does not support HLS');
      }
    };

    if (hlsUrl) {
      initializeHls();
    } else {
      // If no URL is provided, clean up any existing instance
      if (hlsInstanceRef.current) {
        hlsInstanceRef.current.destroy();
        hlsInstanceRef.current = null;
      }
    }

    return () => {
      isMounted = false;
      if (hlsInstanceRef.current) {
        hlsInstanceRef.current.destroy();
        hlsInstanceRef.current = null;
      }
    };
  }, [hlsUrl]);

  return (
    <video
      ref={videoRef}
      controls
      style={{ width: '100%', maxWidth: '800px' }}
    />
  );
}

export default HlsPlayer;
